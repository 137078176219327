/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import AddNewItemEditor from './AddNewItemEditor';

const currencyFormatGbp = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const currencyFormatEur = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' });

const isEquals = require('lodash.isequal');

const URL = 'https://api.musicmarquee.co.uk';

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class NewItemsTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      data: [],
      selected: undefined,
      selectedOriginal: undefined,
      sellers: [],
      isEditing: false,
      isAdding: false,
    };
    this.handleItemChange = this.handleItemChange.bind(this);
    this.finished = this.finished.bind(this);
  }

  async componentWillMount() {
    // const response = await axios.get(
    //   `${URL}/admin/fulfillment/sellers`,
    //   { headers: { Authorization: localStorage.getItem('mm_admin') } },
    // );
    // this.setState({
    //   sellers: response.data.sellers,
    // });
  }

  getSellerName(id) {
    const retSeller = this.state.sellers.find(seller => seller.id === id).name;
    if (retSeller) {
      return retSeller.name;
    }
    return null;
  }

  handleItemChange(e) {
    const selected = { ...this.state.selected };
    selected[e.target.name] = e.target.value;
    this.setState({ selected });
  }

  finished() {
    this.setState({ isAdding: false });
  }

  async saveItem() {
    await axios.post(`${URL}/admin/inventory/new_stock/update`, {
      item: this.state.selected,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
      // const newData = [...this.state.data];
      // res.data.orders.map((id) => {
      //   const index = newData.findIndex(obj => obj.id === id.id);
      //   if (index !== -1) {
      //     newData.splice(index, 1);
      //   }
      // });
        const dataCopy = this.state.data;
        dataCopy[dataCopy.indexOf(dataCopy.find(obj => obj.sku === res.data.item.sku))] = res.data.item;

        this.setState({
          selectedOriginal: res.data.item,
          isEditing: false,
          data: dataCopy,
        });
      });
    return true;
  }

  render() {
    const itemsColumns = [
      // {
      //   Header: 'Seller',
      //   id: 'seller_id',
      //   accessor: d => this.state.sellers.find(seller => seller.id === d.seller_id).name,
      //   width: getColumnWidth(this.state.data, 'seller_id', 'Order Id'),
      //   Filter: ({ filter, onChange }) =>
      //     (
      //       <select
      //         onChange={event => onChange(event.target.value)}
      //         style={{ width: '100%' }}
      //         value={filter ? filter.value : 'all'}
      //       >
      //         <option value="">All Sellers</option>
      //         {this.state.sellers.map(seller => <option value={seller.id}>{seller.name}</option>)}
      //       </select>),
      // },
      {
        Header: 'SKU',
        id: 'sku',
        accessor: 'sku',
        width: getColumnWidth(this.state.data, 'sku', 'Order Id'),
      }, {
        Header: 'Qty',
        id: 'qty',
        accessor: 'qty',
        width: getColumnWidth(this.state.data, 'qty', 'Qty'),
      }, {
        Header: 'Artist',
        id: 'artist',
        accessor: 'artist',
        width: getColumnWidth(this.state.data, 'artist', 'Artist'),
      }, {
        Header: 'Title',
        id: 'title',
        accessor: 'title',
      }, {
        Header: 'Format',
        id: 'format',
        accessor: 'format',
        width: getColumnWidth(this.state.data, 'format', 'Format'),
        // Filter: ({ filter, onChange }) =>
        //             <IntervalDatePickerField
        //               onChange={event => onChange(event.target.value)}
        //                 style={{ width: "100%" }}
        //                 value={filter ? filter.value : "all"}
        //             />,
      },
    ];

    return (
      <Col>
        <Card
          style={{
            height: '550px',
          }}
        >
          <CardBody>
            <Button
              color={this.state.isAdding ? ('danger') : 'primary'}
              size="sm"
              onClick={() => this.setState(prevState => ({ isAdding: !prevState.isAdding }))}
            >{this.state.isAdding ? ('Cancel') : 'Add New Item'}
            </Button>
            {this.state.isAdding ? (
              <AddNewItemEditor finished={this.finished} />)
                : (
                  <div>
                    <ReactTable
                      style={{
                    height: '400px',
                  }}
                      data={this.state.data} // should default to []
                      columns={itemsColumns}
                      pages={this.state.pages}
                      loading={this.state.loading}
                      manual
                      filterable
                      multiSort={false}
                      defaultPageSize={50}
                      defaultSorted={[
                    {
                      id: 'date_added',
                      desc: true,
                    },
                  ]}
                      className="-striped -highlight -responsive"
                      getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                        onClick: () => {
                          this.setState({
                            selected: rowInfo.original,
                            selectedOriginal: rowInfo.original,
                            isEditing: false,
                          });
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black',
                        },
                      };
                    }
                    return {};
                  }} // informs React Table that you'll be handling sorting and pagination server-side
                      onFetchData={(state) => {
                    // show the loading overlay
                    this.setState({ loading: true });
                    axios.post(`${URL}/admin/inventory/new_stock`, {
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                    .then((res) => {
                      // Update react-table
                      this.setState({
                        data: res.data.items,
                        pages: res.data.pages,
                        loading: false,
                      });
                    });
                  }}
                    />
                  </div>
        )}
          </CardBody>
        </Card>
        {this.state.selected !== undefined && !this.state.isAdding ? (
          <CardDeck>
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  size="sm"
                  disabled={this.state.isEditing}
                  onClick={() => this.setState(prevState => ({ isEditing: !prevState.isEditing }))}
                >Edit
                </Button>
                {this.state.isEditing ? (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() =>
                        this.setState(prevState => ({
                          isEditing: !prevState.isEditing,
                          selected: prevState.selectedOriginal,
                        }))
                      }
                  >Cancel
                  </Button>
                  ) : null }
                <div className="project-summary">
                  <div className="card__title">
                    <h5 className="bold-text">
                      {`${this.state.selected.sku} (${this.state.selected.artist} - ${this.state.selected.title})`}
                    </h5>
                  </div>
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>SKU:</th>
                            <td>{this.state.selected.sku}</td>
                          </tr>
                          <tr>
                            <th>Date Added:</th>
                            <td>{new Date(this.state.selected.date_added).toLocaleDateString()}</td>
                          </tr>
                          <tr>
                            <th>Artist:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="artist"
                                  value={this.state.selected.artist}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.artist}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Title:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="title"
                                  value={this.state.selected.title}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.title}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Qty:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="qty"
                                  value={this.state.selected.qty}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.qty}</b> }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Min Price (GBP):</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="min_price_gbp"
                                  value={this.state.selected.min_price_gbp}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{currencyFormatGbp.format(this.state.selected.min_price_gbp)}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Current Price (GBP):</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="current_price_gbp"
                                  value={this.state.selected.current_price_gbp}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{currencyFormatGbp.format(this.state.selected.current_price_gbp)}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Min Price (EUR):</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="min_price_eur"
                                  value={this.state.selected.min_price_eur}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{currencyFormatEur.format(this.state.selected.min_price_eur)}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Current Price (EUR):</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="current_price_eur"
                                  value={this.state.selected.current_price_eur}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{currencyFormatEur.format(this.state.selected.current_price_eur)}</b> }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          {/* <tr>
                              <th>Shipping Address:</th>
                              <td>{this.getShippingAddress()}</td>
                            </tr> */}
                          <tr>
                            <th>ASIN:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="asin"
                                  value={this.state.selected.asin}
                                  onChange={this.handleItemChange}
                                />
                                ) :
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://www.amazon.co.uk/dp/${this.state.selected.asin}`}
                                >
                                  {this.state.selected.asin}
                                </a>
                                }
                            </td>
                          </tr>
                          <tr>
                            <th>EAN:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="ean"
                                  value={this.state.selected.ean}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.ean}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Release ID:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="release_id"
                                  value={this.state.selected.release_id}
                                  onChange={this.handleItemChange}
                                />
                                ) :
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://www.discogs.com/release/${this.state.selected.release_id}`}
                                >
                                  {this.state.selected.release_id}
                                </a>
                                }
                            </td>
                          </tr>
                          <tr>
                            <th>Location:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="location"
                                  value={this.state.selected.location}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.location}</b> }
                            </td>
                          </tr>
                          {/* <Button
                              color="primary"
                              size="sm"
                              onClick={() => this.copyShippingAddressToClipboard()}
                              >Copy Address
                            </Button> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
                { isEquals(this.state.selectedOriginal, this.state.selected) ? (
                    null
                  ) :
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.saveItem()}
                    >Save Changes
                    </Button> }
              </CardBody>
            </Card>
          </CardDeck>) : null }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(NewItemsTable);
