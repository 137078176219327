export const hsCodes = [
  {
    label: 'Vinyl Record',
    value: 8523809000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Compact Disc',
    value: 8523492000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Cassette Tape',
    value: 8523291900,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Poster',
    value: 4901990000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'T-Shirt',
    value: 6109100010,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Sweatshirt',
    value: 6109100010,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Peaked Cap',
    value: 65050030,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Marker Pens',
    value: 96082000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Book',
    value: 49011010,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Food Storage Container',
    value: 39241000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Slip-mats',
    value: 59111000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Sticker Pack',
    value: 3919909000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Coffee Pack',
    value: '09012100',
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Espresso Mug',
    value: 69149000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Keyring',
    value: 73269080,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Insulated Flask',
    value: 96170000,
    origin: 'GBR',
    qty: 1,
  },
  {
    label: 'Fashion Jewellery',
    value: 7113200000,
    origin: 'GBR',
    qty: 1,
  },
];

export const marketplaces = [
  {
    value: 'amazon',
    label: 'Amazon',
    ioss: 'IM4420001201',
  },
  {
    value: 'ebay',
    label: 'eBay',
    ioss: 'IM2760000742',
  },
  {
    value: 'bandcamp',
    label: 'Bandcamp',
    ioss: 'IM3720016803',
  },
  {
    value: 'discogs',
    label: 'Discogs',
    ioss: 'IM5280008075',
  },
  {
    value: 'none',
    label: 'None (No IOSS)',
    ioss: null,
  },
];
