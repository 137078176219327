/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-array-index-key */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup, ListGroup, ListGroupItem, Table, Input } from 'reactstrap';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
// const URL = 'https://marketplace-api.musicmarquee.co.uk';
const URL = 'https://marketplace-api.musicmarquee.co.uk';


class ShippingToolsFulfillmentPage extends Component {
  constructor() {
    super();
    this.state = {
      readyShipments: [],
      shipmentsBatch: [],
    };
    this.getShipments();
  }

  async getShipments() {
    const response = await axios.get(`${URL}/admin/fulfillment/ship`, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    console.log(this.state.readyShipments);
    const shipments = this.groupBySeller(response.data, 'discogs_username');

    console.log(shipments);
    this.setState({
      readyShipments: shipments,
    });
  }

  groupBySeller(array, key) {
  // Return the end result
    array.sort();
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }

  submitShipments(shipments) {
    axios.post(`${URL}/admin/fulfillment/ship`, {
      shipments,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
        const { readyShipments } = this.state;
        res.data.map((response) => {
          readyShipments[response.from.username].find(order => order.external_order_id === response.order.id).shipped = true;
        });
        this.setState({
          readyShipments,
        });
      })
      .catch((err) => {
        console.log('ERRORRRRRr');
      });
  }

  addToShipmentBatch(shipment) {
    this.setState({ shipmentsBatch: [...this.state.shipmentsBatch, shipment] });
  }

  checkAll() {
    // const ref = `checkbox_${i}`;
    // this.refs[ref].checked = !this.refs[ref].checked;
    const shipments = [...this.state.shipmentsBatch];
    Object.keys(this.refs).forEach((ref) => {
      console.log(ref);
      const refSplit = ref.split('__');
      this.refs[ref].checked = !this.refs[ref].checked;
      if (this.refs[ref].checked) {
        shipments.push(this.state.readyShipments[refSplit[1]][refSplit[2]]);
      } else {
        const index = shipments.indexOf(this.state.readyShipments[refSplit[1]][refSplit[2]]);
        if (index > -1) {
          shipments.splice(index, 1);
        }
      }
    });
    this.setState({
      shipmentsBatch: shipments,
    });
    // console.log(this.refs);
  }

  render() {
    return (
      <Col style={{ height: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardBody>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.checkAll()}
            >Select All
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => console.log(this.state.shipmentsBatch)}
            >Check Batch
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.submitShipments(this.state.shipmentsBatch)}
            >Submit Batch
            </Button>
            {Object.keys(this.state.readyShipments).map(seller => (
              <div>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th><b>{seller}:<p style={{ color: 'green' }}>{this.state.readyShipments[seller].length} Order(s)</p></b></th>
                    </tr>
                    <tr>
                      <th>Select</th>
                      <th>Order Id</th>
                      <th>Shipped Date</th>
                      <th>Carrier</th>
                      <th>Tracking</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.readyShipments[seller].map((shipment, index) => (
                      <tr key={index} style={shipment.shipped ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'clear' }}>
                        <td><input
                          ref={`checkbox__${seller}__${index}`}
                          type="checkbox"
                          onChange={e => (e.target.checked ? this.setState({ shipmentsBatch: [...this.state.shipmentsBatch, this.state.readyShipments[seller][index]] }) : this.setState({
                            shipmentsBatch: this.state.shipmentsBatch.filter(item => item !== this.state.readyShipments[seller][index]),
                          }))}
                        />
                        </td>
                        <td>{shipment.external_order_id}</td>
                        <td>{shipment.shipped_date}</td>
                        <td>{shipment.shipping_carrier}</td>
                        <td>{shipment.tracking_number}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )) }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ShippingToolsFulfillmentPage);
