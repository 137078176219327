export const domesticServices = [
  {
    value: 'CRL24',
    label: 'Royal Mail 1st Class',
    compensation: 20,
    obaPricing: {
      100: 1.00,
      250: 1.42,
      500: 1.54,
      750: 2.05,
    },
    sizes: ['LL'],
    prices: {
      100: 1.29,
      250: 1.83,
      500: 2.39,
      750: 3.30,
    },
  },
  {
    value: 'TPN24',
    label: 'Royal Mail Tracked 24',
    compensation: 20,
    obaPricing: {
      1000: 4.09,
      1250: 4.09,
      1500: 4.09,
      1750: 4.09,
      2000: 4.09,
      20000: 4.09,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.85,
      2000: 6.57,
      20000: 9.00,
    },
  },
  {
    value: 'TRN24',
    label: 'Royal Mail Tracked 24',
    compensation: 100,
    obaPricing: {
      100: 3.22,
      250: 3.22,
      500: 3.22,
      750: 3.22,
    },
    sizes: ['LL'],
    prices: {
      100: 3.23,
      250: 3.23,
      500: 3.79,
      750: 4.70,
    },
  },
  {
    value: 'TPN24SS',
    label: 'Royal Mail Tracked 24 (+Signature)',
    compensation: 20,
    obaPricing: {
      1000: 4.39,
      1250: 4.39,
      1500: 4.39,
      1750: 4.39,
      2000: 4.39,
      20000: 4.39,
    },
    sizes: ['SP'],
    prices: {
      1000: 5.40,
      2000: 7.07,
      20000: 9.50,
    },
  },
  {
    value: 'TRN24SS',
    label: 'Royal Mail Tracked 24 (+Signature)',
    compensation: 100,
    obaPricing: {
      100: 3.52,
      250: 3.52,
      500: 3.52,
      750: 3.52,
    },
    sizes: ['LL'],
    prices: {
      100: 3.72,
      250: 3.72,
      500: 3.94,
      750: 4.75,
    },
  },
  {
    value: 'CRL24',
    label: 'Royal Mail 1st Class',
    compensation: 20,
    obaPricing: {
      1000: 3.02,
      1250: 3.21,
      1500: 3.39,
      1750: 3.55,
      2000: 3.83,
    },
    sizes: ['SP'],
    prices: {
      1000: 3.85,
      2000: 5.57,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      100: 0.79,
      250: 1.09,
      500: 1.25,
      750: 1.65,
    },
    sizes: ['LL'],
    prices: {
      100: 0.96,
      250: 1.53,
      500: 1.99,
      750: 2.70,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      1000: 2.37,
      1250: 2.67,
      1500: 2.67,
      1750: 2.67,
      2000: 2.67,
    },
    sizes: ['SP'],
    prices: {
      1000: 3.20,
      2000: 3.20,
    },
  },
  {
    value: 'TPS48',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      1000: 3.05,
      1250: 3.05,
      1500: 3.05,
      1750: 3.05,
      2000: 3.05,
      20000: 3.05,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.10,
      2000: 4.10,
      20000: 8.00,
    },
  },
  {
    value: 'TRS48',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      100: 2.40,
      250: 2.40,
      500: 2.40,
      750: 2.40,
    },
    sizes: ['LL'],
    prices: {
      100: 2.70,
      250: 2.70,
      500: 3.13,
      750: 3.78,
    },
  },
  {
    value: 'TPS48SS',
    label: 'Royal Mail Tracked 48 (+Signature)',
    compensation: 100,
    obaPricing: {
      1000: 3.35,
      1250: 3.35,
      1500: 3.35,
      1750: 3.35,
      2000: 3.35,
      20000: 3.35,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.60,
      2000: 4.60,
      20000: 8.50,
    },
  },
  {
    value: 'TRS48SS',
    label: 'Royal Mail Tracked 48 (+Signature)',
    compensation: 100,
    obaPricing: {
      100: 2.70,
      250: 2.70,
      500: 2.70,
      750: 2.70,
    },
    sizes: ['LL'],
    prices: {
      100: 3.20,
      250: 3.20,
      500: 3.63,
      750: 4.28,
    },
  },
  {
    value: 'CRL24S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      100: 2.34,
      250: 2.75,
      500: 3.17,
      750: 3.90,
    },
    sizes: ['LL'],
    prices: {
      100: 2.69,
      250: 3.23,
      500: 3.79,
      750: 4.70,
    },
  },
  {
    value: 'CRL24S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      1000: 4.58,
      1250: 6.47,
      1500: 6.47,
      1750: 6.47,
      2000: 6.47,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.85,
      2000: 6.57,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      100: 2.11,
      250: 2.40,
      500: 2.71,
      750: 3.25,
    },
    sizes: ['LL'],
    prices: {
      100: 2.36,
      250: 2.93,
      500: 3.39,
      750: 4.10,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      1000: 3.95,
      1250: 3.95,
      1500: 3.95,
      1750: 3.95,
      2000: 3.95,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.20,
      2000: 4.20,
    },
  },
  {
    value: 'SD1',
    label: 'Royal Mail Special Delivery Guaranteed by 1pm®',
    compensation: 500,
    obaPricing: {
      500: 6.15,
      1000: 7.25,
      2000: 8.90,
    },
    sizes: ['LL', 'SP'],
    prices: {
      500: 7.65,
      1000: 8.95,
      2000: 11.15,
    },
  },
];

export const internationalServices = [
  {
    value: 'MP1',
    label: 'Royal Mail International Tracked',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.125,
        PPK: 3.875,
      },
      ROW: {
        PPI: 6.930,
        PPK: 6.255,
      },
      USA: {
        PPI: 7.450,
        PPK: 5.540,
      },
    },
    prices: {
      250: {
        EU: 10.25,
        1: 12.70,
        2: 14.15,
        3: 13.45,
      },
      500: {
        EU: 11.70,
        1: 16.35,
        2: 18.55,
        3: 18.10,
      },
      750: {
        EU: 12.75,
        1: 18.75,
        2: 21.35,
        3: 20.85,
      },
      1000: {
        EU: 13.70,
        1: 21.40,
        2: 24.50,
        3: 24.50,
      },
      1250: {
        EU: 14.10,
        1: 23.25,
        2: 26.90,
        3: 27.95,
      },
      1500: {
        EU: 14.85,
        1: 24.65,
        2: 29.40,
        3: 30.70,
      },
      // 1750: {
      //   EU: 17.77,
      //   1: 20.98,
      //   2: 22.65,
      //   3: 29.65,
      // },
      2000: {
        EU: 15.25,
        1: 25.65,
        2: 31.00,
        3: 31.95,
      },
    },
  }, {
    value: 'MTI',
    label: 'Royal Mail International Tracked',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.870,
        PPK: 4.140,
      },
      ROW: {
        PPI: 6.310,
        PPK: 6.155,
      },
      USA: {
        PPI: 6.400,
        PPK: 6.440,
      },
    },
    prices: {
      100: {
        EU: 8.65,
        1: 9.60,
        2: 9.65,
        3: 9.65,
      },
      250: {
        EU: 8.85,
        1: 10.35,
        2: 11.45,
        3: 10.55,
      },
      500: {
        EU: 9.85,
        1: 12.20,
        2: 14.00,
        3: 12.50,
      },
      750: {
        EU: 10.25,
        1: 14.05,
        2: 16.80,
        3: 14.50,
      },
    },
  }, {
    value: 'MP5',
    label: 'Royal Mail International Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.595,
        PPK: 3.875,
      },
      ROW: {
        PPI: 7.050,
        PPK: 6.255,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
        // 3: 14.34,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
        // 3: 14.34,
      },
      750: {
        EU: 13.15,
        1: 19.00,
        2: 21.60,
        // 3: 14.34,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
        // 3: 14.34,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
        // 3: 14.34,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
        // 3: 14.34,
      },
      // 1750: {
      //   EU: 14.81,
      //   1: 20.98,
      //   2: 22.65,
      //   // 3: 14.34,
      // },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
        // 3: 14.34,
      },
    },
  }, {
    value: 'MTM',
    label: 'Royal Mail International Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.635,
        PPK: 5.795,
      },
      ROW: {
        PPI: 5.830,
        PPK: 6.205,
      },
    },
    prices: {
      100: {
        EU: 8.75,
        1: 9.70,
        2: 9.80,
        // 3: 14.34,
      },
      250: {
        EU: 9.20,
        1: 10.50,
        2: 11.55,
        // 3: 14.34,
      },
      500: {
        EU: 9.95,
        1: 12.30,
        2: 14.10,
        // 3: 14.34,
      },
      750: {
        EU: 10.35,
        1: 14.15,
        2: 16.90,
        // 3: 14.34,
      },
    },
  },
  {
    value: 'MTA',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 7.245,
        PPK: 3.875,
      },
      ROW: {
        PPI: 7.70,
        PPK: 6.255,
      },
      USA: {
        PPI: 10.370,
        PPK: 5.540,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
        3: 15.45,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
        3: 20.85,
      },
      750: {
        EU: 13.15,
        1: 19.00,
        2: 21.60,
        3: 22.85,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
        3: 26.50,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
        3: 29.95,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
        3: 32.70,
      },
      // 1750: {
      //   EU: 14.81,
      //   1: 20.98,
      //   2: 22.65,
      //   3: 31.65,
      // },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
        3: 33.20,
      },
    },
  }, {
    value: 'MTC',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.885,
        PPK: 5.795,
      },
      ROW: {
        PPI: 6.300,
        PPK: 6.205,
      },
      USA: {
        PPI: 6.120,
        PPK: 6.490,
      },
    },
    prices: {
      100: {
        EU: 8.75,
        1: 9.70,
        2: 9.80,
        3: 9.75,
      },
      250: {
        EU: 9.20,
        1: 10.50,
        2: 11.55,
        3: 10.65,
      },
      500: {
        EU: 9.95,
        1: 12.30,
        2: 14.10,
        3: 12.60,
      },
      750: {
        EU: 10.35,
        1: 14.15,
        2: 16.90,
        3: 14.60,
      },
    },
  },
  {
    value: 'IE1',
    label: 'Royal Mail International Standard',
    sizes: ['SP'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 2.485,
        PPK: 3.150,
      },
      ROW: {
        PPI: 2.115,
        PPK: 6.960,
      },
      USA: {
        PPI: 4.055,
        PPK: 7.100,
      },
    },
    prices: {
      250: {
        EU: 5.95,
        1: 8.30,
        2: 9.90,
        3: 10.95,
      },
      500: {
        EU: 7.80,
        1: 12.10,
        2: 14.50,
        3: 16.60,
      },
      750: {
        EU: 9.05,
        1: 14.85,
        2: 17.60,
        3: 19.35,
      },
      1000: {
        EU: 10.20,
        1: 17.65,
        2: 20.85,
        3: 23.00,
      },
      1250: {
        EU: 11.05,
        1: 19.85,
        2: 23.75,
        3: 26.45,
      },
      1500: {
        EU: 12.10,
        1: 22.10,
        2: 26.85,
        3: 29.20,
      },
      // 1750: {
      //   EU: 13.00,
      //   1: 18.38,
      //   2: 19.95,
      //   3: 28.15,
      // },
      2000: {
        EU: 13.00,
        1: 23.30,
        2: 28.55,
        3: 30.45,
      },
    },
  },
  {
    value: 'IG1',
    label: 'Royal Mail International Standard',
    sizes: ['LL'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 1.060,
        PPK: 4.770,
      },
      ROW: {
        PPI: 1.085,
        PPK: 6.945,
      },
      USA: {
        PPI: 1.085,
        PPK: 7.175,
      },
    },
    prices: {
      100: {
        EU: 3.25,
        1: 4.20,
        2: 4.20,
        3: 4.20,
      },
      250: {
        EU: 4.25,
        1: 5.70,
        2: 6.80,
        3: 5.85,
      },
      500: {
        EU: 5.25,
        1: 8.00,
        2: 9.85,
        3: 8.30,
      },
      750: {
        EU: 6.25,
        1: 10.65,
        2: 13.55,
        3: 11.10,
      },
    },
  },
  {
    value: 'GPT',
    label: 'DHL International Tracked',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.65,
        PPK: 3.00,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 3.00,
        PPK: 6.00,
      },
      3: {
        PPI: 4.00,
        PPK: 3.80,
      },
      4: {
        PPI: 5.00,
        PPK: 3.10,
      },
      5: {
        PPI: 5.00,
        PPK: 8.00,
      },
      6: {
        PPI: 4.65,
        PPK: 11.00,
      },
      7: {
        PPI: 3.95,
        PPK: 7.50,
      },
      8: {
        PPI: 4.60,
        PPK: 10.50,
      },
      9: {
        PPI: 1.80,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 10.20,
        1: 10.20,
        2: 10.20,
        3: 10.20,
        4: 10.20,
        // 5: 4.20, UK
        6: 13.55, // USA
        7: 11.70,
        8: 12.75,
        9: 12.75,
      },
      250: {
        0: 10.35,
        1: 10.35,
        2: 10.35,
        3: 10.35,
        4: 10.35,
        // 5: 4.20, UK
        6: 15.15, // USA
        7: 12.65,
        8: 14.10,
        9: 14.10,
      },
      500: {
        0: 11.80,
        1: 11.80,
        2: 11.80,
        3: 11.80,
        4: 11.80,
        // 5: 4.20, UK
        6: 20.55, // USA
        7: 16.30,
        8: 18.50,
        9: 18.50,
      },
      750: {
        0: 12.85,
        1: 12.85,
        2: 12.85,
        3: 12.85,
        4: 12.85,
        // 5: 4.20, UK
        6: 22.55, // USA
        7: 18.70,
        8: 21.30,
        9: 21.30,
      },
      1000: {
        0: 13.80,
        1: 13.80,
        2: 13.80,
        3: 13.80,
        4: 13.80,
        // 5: 4.20, UK
        6: 26.20, // USA
        7: 21.35,
        8: 24.45,
        9: 24.45,
      },
      1250: {
        0: 14.20,
        1: 14.20,
        2: 14.20,
        3: 14.20,
        4: 14.20,
        // 5: 4.20, UK
        6: 29.65, // USA
        7: 23.20,
        8: 26.85,
        9: 26.85,
      },
      1500: {
        0: 14.75,
        1: 14.75,
        2: 14.75,
        3: 14.75,
        4: 14.75,
        // 5: 4.20, UK
        6: 32.40, // USA
        7: 24.60,
        8: 29.35,
        9: 29.35,
      },
      2000: {
        0: 14.95,
        1: 14.95,
        2: 14.95,
        3: 14.95,
        4: 14.95,
        // 5: 4.20, UK
        6: 32.90, // USA
        7: 25.05,
        8: 30.40,
        9: 30.40,
      },
    },
  }, {
    value: 'GMP',
    label: 'DHL International Standard',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 1.22,
        PPK: 3.00,
      },
      1: {
        PPI: 1.80,
        PPK: 4.00,
      },
      2: {
        PPI: 1.10,
        PPK: 6.00,
      },
      3: {
        PPI: 2.38,
        PPK: 3.80,
      },
      4: {
        PPI: 3.35,
        PPK: 3.10,
      },
      5: {
        PPI: 2.00,
        PPK: 8.00,
      },
      6: {
        PPI: 3.25,
        PPK: 11.00,
      },
      7: {
        PPI: 1.00,
        PPK: 7.50,
      },
      8: {
        PPI: 1.25,
        PPK: 10.50,
      },
      9: {
        PPI: 0.30,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 5.50,
        1: 5.50,
        2: 5.50,
        3: 5.50,
        4: 5.50,
        // 5: 4.20, UK
        6: 9.05, // USA
        7: 6.85,
        8: 8.05,
        9: 8.05,
      },
      250: {
        0: 5.65,
        1: 5.65,
        2: 5.65,
        3: 5.65,
        4: 5.65,
        // 5: 4.20, UK
        6: 10.65, // USA
        7: 8.00,
        8: 9.60,
        9: 9.60,
      },
      500: {
        0: 7.50,
        1: 7.50,
        2: 7.50,
        3: 7.50,
        4: 7.50,
        // 5: 4.20, UK
        6: 16.30, // USA
        7: 11.80,
        8: 14.20,
        9: 14.20,
      },
      750: {
        0: 8.75,
        1: 8.75,
        2: 8.75,
        3: 8.75,
        4: 8.75,
        // 5: 4.20, UK
        6: 19.05, // USA
        7: 14.55,
        8: 17.30,
        9: 17.30,
      },
      1000: {
        0: 9.90,
        1: 9.90,
        2: 9.90,
        3: 9.90,
        4: 9.90,
        // 5: 4.20, UK
        6: 22.70, // USA
        7: 17.35,
        8: 20.55,
        9: 20.55,
      },
      1250: {
        0: 10.75,
        1: 10.75,
        2: 10.75,
        3: 10.75,
        4: 10.75,
        // 5: 4.20, UK
        6: 26.15, // USA
        7: 19.55,
        8: 23.45,
        9: 23.45,
      },
      1500: {
        0: 11.80,
        1: 11.80,
        2: 11.80,
        3: 11.80,
        4: 11.80,
        // 5: 4.20, UK
        6: 28.90, // USA
        7: 21.80,
        8: 26.55,
        9: 26.55,
      },
      2000: {
        0: 12.70,
        1: 12.70,
        2: 12.70,
        3: 12.70,
        4: 12.70,
        // 5: 4.20, UK
        6: 30.15, // USA
        7: 23.00,
        8: 28.25,
        9: 28.25,
      },
    },
  },
];

export const packageSizes = [
  {
    value: 'LL',
    label: 'Large Letter',
    max_weight: 750,
  },
  {
    value: 'SP',
    label: 'Small Parcel',
    max_weight: 2000,
  },
];
