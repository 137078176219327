import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import MerchItemsTable from './components/MerchItemsTable';

const MerchItems = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Bandcamp / Merch Items</h5>
      </Col>
    </Row>
    <Row>
      <MerchItemsTable />
    </Row>
  </Container>
);

export default MerchItems;
