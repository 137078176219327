export const iso2codes = {
  BD: 'BGD',
  BE: 'BEL',
  BF: 'BFA',
  BG: 'BGR',
  BA: 'BIH',
  BB: 'BRB',
  WF: 'WLF',
  BL: 'BLM',
  BM: 'BMU',
  BN: 'BRN',
  BO: 'BOL',
  BH: 'BHR',
  BI: 'BDI',
  BJ: 'BEN',
  BT: 'BTN',
  JM: 'JAM',
  BV: 'BVT',
  BW: 'BWA',
  WS: 'WSM',
  BQ: 'BES',
  BR: 'BRA',
  BS: 'BHS',
  JE: 'JEY',
  BY: 'BLR',
  BZ: 'BLZ',
  RU: 'RUS',
  RW: 'RWA',
  RS: 'SRB',
  TL: 'TLS',
  RE: 'REU',
  TM: 'TKM',
  TJ: 'TJK',
  RO: 'ROU',
  TK: 'TKL',
  GW: 'GNB',
  GU: 'GUM',
  GT: 'GTM',
  GS: 'SGS',
  GR: 'GRC',
  GQ: 'GNQ',
  GP: 'GLP',
  JP: 'JPN',
  GY: 'GUY',
  GG: 'GGY',
  GF: 'GUF',
  GE: 'GEO',
  GD: 'GRD',
  GB: 'GBR',
  GA: 'GAB',
  SV: 'SLV',
  GN: 'GIN',
  GM: 'GMB',
  GL: 'GRL',
  GI: 'GIB',
  GH: 'GHA',
  OM: 'OMN',
  TN: 'TUN',
  JO: 'JOR',
  HR: 'HRV',
  HT: 'HTI',
  HU: 'HUN',
  HK: 'HKG',
  HN: 'HND',
  HM: 'HMD',
  VE: 'VEN',
  PR: 'PRI',
  PS: 'PSE',
  PW: 'PLW',
  PT: 'PRT',
  SJ: 'SJM',
  PY: 'PRY',
  IQ: 'IRQ',
  PA: 'PAN',
  PF: 'PYF',
  PG: 'PNG',
  PE: 'PER',
  PK: 'PAK',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PM: 'SPM',
  ZM: 'ZMB',
  EH: 'ESH',
  EE: 'EST',
  EG: 'EGY',
  ZA: 'ZAF',
  EC: 'ECU',
  IT: 'ITA',
  VN: 'VNM',
  SB: 'SLB',
  ET: 'ETH',
  SO: 'SOM',
  ZW: 'ZWE',
  SA: 'SAU',
  ES: 'ESP',
  ER: 'ERI',
  ME: 'MNE',
  MD: 'MDA',
  MG: 'MDG',
  MF: 'MAF',
  MA: 'MAR',
  MC: 'MCO',
  UZ: 'UZB',
  MM: 'MMR',
  ML: 'MLI',
  MO: 'MAC',
  MN: 'MNG',
  MH: 'MHL',
  MK: 'MKD',
  MU: 'MUS',
  MT: 'MLT',
  MW: 'MWI',
  MV: 'MDV',
  MQ: 'MTQ',
  MP: 'MNP',
  MS: 'MSR',
  MR: 'MRT',
  IM: 'IMN',
  UG: 'UGA',
  TZ: 'TZA',
  MY: 'MYS',
  MX: 'MEX',
  IL: 'ISR',
  FR: 'FRA',
  IO: 'IOT',
  SH: 'SHN',
  FI: 'FIN',
  FJ: 'FJI',
  FK: 'FLK',
  FM: 'FSM',
  FO: 'FRO',
  NI: 'NIC',
  NL: 'NLD',
  NO: 'NOR',
  NA: 'NAM',
  VU: 'VUT',
  NC: 'NCL',
  NE: 'NER',
  NF: 'NFK',
  NG: 'NGA',
  NZ: 'NZL',
  NP: 'NPL',
  NR: 'NRU',
  NU: 'NIU',
  CK: 'COK',
  XK: 'XKX',
  CI: 'CIV',
  CH: 'CHE',
  CO: 'COL',
  CN: 'CHN',
  CM: 'CMR',
  CL: 'CHL',
  CC: 'CCK',
  CA: 'CAN',
  CG: 'COG',
  CF: 'CAF',
  CD: 'COD',
  CZ: 'CZE',
  CY: 'CYP',
  CX: 'CXR',
  CR: 'CRI',
  CW: 'CUW',
  CV: 'CPV',
  CU: 'CUB',
  SZ: 'SWZ',
  SY: 'SYR',
  SX: 'SXM',
  KG: 'KGZ',
  KE: 'KEN',
  SS: 'SSD',
  SR: 'SUR',
  KI: 'KIR',
  KH: 'KHM',
  KN: 'KNA',
  KM: 'COM',
  ST: 'STP',
  SK: 'SVK',
  KR: 'KOR',
  SI: 'SVN',
  KP: 'PRK',
  KW: 'KWT',
  SN: 'SEN',
  SM: 'SMR',
  SL: 'SLE',
  SC: 'SYC',
  KZ: 'KAZ',
  KY: 'CYM',
  SG: 'SGP',
  SE: 'SWE',
  SD: 'SDN',
  DO: 'DOM',
  DM: 'DMA',
  DJ: 'DJI',
  DK: 'DNK',
  VG: 'VGB',
  DE: 'DEU',
  YE: 'YEM',
  DZ: 'DZA',
  US: 'USA',
  UY: 'URY',
  YT: 'MYT',
  UM: 'UMI',
  LB: 'LBN',
  LC: 'LCA',
  LA: 'LAO',
  TV: 'TUV',
  TW: 'TWN',
  TT: 'TTO',
  TR: 'TUR',
  LK: 'LKA',
  LI: 'LIE',
  LV: 'LVA',
  TO: 'TON',
  LT: 'LTU',
  LU: 'LUX',
  LR: 'LBR',
  LS: 'LSO',
  TH: 'THA',
  TF: 'ATF',
  TG: 'TGO',
  TD: 'TCD',
  TC: 'TCA',
  LY: 'LBY',
  VA: 'VAT',
  VC: 'VCT',
  AE: 'ARE',
  AD: 'AND',
  AG: 'ATG',
  AF: 'AFG',
  AI: 'AIA',
  VI: 'VIR',
  IS: 'ISL',
  IR: 'IRN',
  AM: 'ARM',
  AL: 'ALB',
  AO: 'AGO',
  AQ: 'ATA',
  AS: 'ASM',
  AR: 'ARG',
  AU: 'AUS',
  AT: 'AUT',
  AW: 'ABW',
  IN: 'IND',
  AX: 'ALA',
  AZ: 'AZE',
  IE: 'IRL',
  ID: 'IDN',
  UA: 'UKR',
  QA: 'QAT',
  MZ: 'MOZ',
};


export const countryList = [
  {
    label: 'Afghanistan',
    iso2code: 'AF',
    value: 'AFG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP'],
  },
  {
    label: 'Aland Islands',
    iso2code: 'AX',
    value: 'ALA',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Albania',
    iso2code: 'AL',
    value: 'ALB',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Algeria',
    iso2code: 'DZ',
    value: 'DZA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Andorra',
    iso2code: 'AD',
    value: 'AND',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Angola',
    iso2code: 'AO',
    value: 'AGO',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP'],
  },
  {
    label: 'Anguilla',
    iso2code: 'AI',
    value: 'AIA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Antigua / Barbuda',
    iso2code: 'AG',
    value: 'ATG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Argentina',
    iso2code: 'AR',
    value: 'ARG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Armenia',
    iso2code: 'AM',
    value: 'ARM',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Aruba',
    iso2code: 'AW',
    value: 'ABW',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Ascension Island',
    iso2code: 'SH',
    value: 'SHN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Australia',
    iso2code: 'AU',
    value: 'AUS',
    zone: 2,
    dhlZone: 8,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GPT', 'GMP'],
  },
  {
    label: 'Austria',
    iso2code: 'AT',
    value: 'AUT',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Azerbaijan',
    iso2code: 'AZ',
    value: 'AZE',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bahamas',
    iso2code: 'BS',
    value: 'BHS',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bahrain',
    iso2code: 'BH',
    value: 'BHR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bangladesh',
    iso2code: 'BD',
    value: 'BGD',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Barbados',
    iso2code: 'BB',
    value: 'BRB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Belarus',
    iso2code: 'BY',
    value: 'BLR',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Belgium',
    iso2code: 'BE',
    value: 'BEL',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Belize',
    iso2code: 'BZ',
    value: 'BLZ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Benin',
    iso2code: 'BJ',
    value: 'BEN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bermuda',
    iso2code: 'BM',
    value: 'BMU',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bhutan',
    iso2code: 'BT',
    value: 'BTN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bolivia',
    iso2code: 'BO',
    value: 'BOL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bonaire',
    iso2code: 'BQ',
    value: 'BES',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bosnia & Herzegovina',
    iso2code: 'BA',
    value: 'BIH',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Botswana',
    iso2code: 'BW',
    value: 'BWA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Brazil',
    iso2code: 'BR',
    value: 'BRA',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'British Indian Ocean Territory',
    iso2code: 'IO',
    value: 'IOT',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'British Virgin Islands',
    iso2code: 'VG',
    value: 'VGB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Brunei',
    iso2code: 'BN',
    value: 'BRN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Bulgaria',
    iso2code: 'BG',
    value: 'BGR',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Burkina Faso',
    iso2code: 'BF',
    value: 'BFA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Burundi',
    iso2code: 'BI',
    value: 'BDI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Cambodia',
    iso2code: 'KH',
    value: 'KHM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Cameroon',
    iso2code: 'CM',
    value: 'CMR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Canada',
    iso2code: 'CA',
    value: 'CAN',
    zone: 1,
    dhlZone: 8,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Canary Islands',
    iso2code: 'IC',
    value: 'IC',
    zone: 1,
    dhlZone: 7,
    services: ['GMP', 'GPT'],
  },
  {
    label: 'Cape Verde',
    iso2code: 'CV',
    value: 'CPV',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Cayman Islands',
    iso2code: 'KY',
    value: 'CYM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Central African Republic',
    iso2code: 'CF',
    value: 'CAF',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Chad',
    iso2code: 'TD',
    value: 'TCD',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP'],
  },
  {
    label: 'Chile',
    iso2code: 'CL',
    value: 'CHL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'China (Peoples Republic of)',
    iso2code: 'CN',
    value: 'CHN',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG'],
  },
  {
    label: 'China',
    iso2code: 'CN',
    value: 'CHN',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG'],
  },
  {
    label: 'Christmas Island (Indian Ocean)',
    iso2code: 'CX',
    value: 'CXR',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Christmas Island (Pacific Ocean)',
    iso2code: 'CX',
    value: 'CXR',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Colombia',
    iso2code: 'CO',
    value: 'COL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Comoros Islands',
    iso2code: 'KM',
    value: 'COM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Congo (Dem. Rep of)',
    iso2code: 'CD',
    value: 'COD',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Congo (Rep of)',
    iso2code: 'CG',
    value: 'COG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Cook Islands',
    iso2code: 'CK',
    value: 'COK',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Costa Rica',
    iso2code: 'CR',
    value: 'CRI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Croatia',
    iso2code: 'HR',
    value: 'HRV',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Cuba',
    iso2code: 'CU',
    value: 'CUB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Curacao',
    iso2code: 'CW',
    value: 'CUW',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Cyprus',
    iso2code: 'CY',
    value: 'CYP',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Czech Republic',
    iso2code: 'CZ',
    value: 'CZE',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT'],
  },
  {
    label: 'Denmark',
    iso2code: 'DK',
    value: 'DNK',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Djibouti',
    iso2code: 'DJ',
    value: 'DJI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Dominica',
    iso2code: 'DM',
    value: 'DMA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Dominican Republic',
    iso2code: 'DO',
    value: 'DOM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Ecuador',
    iso2code: 'EC',
    value: 'ECU',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Egypt',
    iso2code: 'EG',
    value: 'EGY',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'El Salvador',
    iso2code: 'SV',
    value: 'SLV',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Equatorial Guinea',
    iso2code: 'GQ',
    value: 'GNQ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Eritrea',
    iso2code: 'ER',
    value: 'ERI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Estonia',
    iso2code: 'EE',
    value: 'EST',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Ethiopia',
    iso2code: 'ET',
    value: 'ETH',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Falkland Islands',
    iso2code: 'FK',
    value: 'FLK',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Faroe Islands',
    iso2code: 'FO',
    value: 'FRO',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Fiji',
    iso2code: 'FJ',
    value: 'FJI',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Finland',
    iso2code: 'FI',
    value: 'FIN',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'France',
    iso2code: 'FR',
    value: 'FRA',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'French Guiana',
    iso2code: 'GF',
    value: 'GUF',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'French Polynesia',
    iso2code: 'PF',
    value: 'PYF',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'French South Antarctic Territory',
    iso2code: 'TF',
    value: 'ATF',
    zone: 2,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Gabon',
    iso2code: 'GA',
    value: 'GAB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Gambia',
    iso2code: 'GM',
    value: 'GMB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Georgia',
    iso2code: 'GE',
    value: 'GEO',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Germany',
    iso2code: 'DE',
    value: 'DEU',
    zone: 'EU',
    dhlZone: 0,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Ghana',
    iso2code: 'GH',
    value: 'GHA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Gibraltar',
    iso2code: 'GI',
    value: 'GIB',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Greece',
    iso2code: 'GR',
    value: 'GRC',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Greenland',
    iso2code: 'GL',
    value: 'GRL',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Grenada',
    iso2code: 'GL',
    value: 'GRL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Guadeloupe',
    iso2code: 'GD',
    value: 'GRD',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Guatemala',
    iso2code: 'GT',
    value: 'GTM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Guam',
    iso2code: 'GU',
    value: 'GUM',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'GMP', 'GPP'],
  },
  {
    label: 'Guinea',
    iso2code: 'GN',
    value: 'GIN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Guinea-Bissau',
    iso2code: 'GW',
    value: 'GNB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Guyana',
    iso2code: 'GY',
    value: 'GUY',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Haiti',
    iso2code: 'HT',
    value: 'HTI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Honduras',
    iso2code: 'HN',
    value: 'HND',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Hong Kong',
    iso2code: 'HK',
    value: 'HKG',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Hungary',
    iso2code: 'HU',
    value: 'HUN',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Iceland',
    iso2code: 'IS',
    value: 'ISL',
    zone: 'EU',
    dhlZone: 4,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'India',
    iso2code: 'IN',
    value: 'IND',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Indonesia',
    iso2code: 'ID',
    value: 'IDN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Iran (Islamic Republic of)',
    iso2code: 'IR',
    value: 'IRN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Iraq',
    iso2code: 'IQ',
    value: 'IRQ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Ireland (Republic of)',
    iso2code: 'IE',
    value: 'IRL',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Ireland',
    iso2code: 'IE',
    value: 'IRL',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Israel',
    iso2code: 'IL',
    value: 'ISR',
    zone: 1,
    dhlZone: 8,
    services: ['DE4', 'DG4', 'MP7', 'MTK', 'MP9', 'MTO', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Italy',
    iso2code: 'IT',
    value: 'ITA',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Ivory Coast',
    iso2code: 'CI',
    value: 'CIV',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Jamaica',
    iso2code: 'JM',
    value: 'JAM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Japan',
    iso2code: 'JP',
    value: 'JPN',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Jersey',
    iso2code: 'JE',
    value: 'JEY',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Jordan',
    iso2code: 'JO',
    value: 'JOR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Kazakhstan',
    iso2code: 'KZ',
    value: 'KAZ',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Kenya',
    iso2code: 'KE',
    value: 'KEN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Kiribati',
    iso2code: 'KI',
    value: 'KIR',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Kosovo',
    iso2code: '',
    value: 'KOS',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Kuwait',
    iso2code: 'KW',
    value: 'KWT',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Kyrgyzstan',
    iso2code: 'KG',
    value: 'KGZ',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Laos',
    iso2code: 'LA',
    value: 'LAO',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Latvia',
    iso2code: 'LV',
    value: 'LVA',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Lebanon',
    iso2code: 'LB',
    value: 'LBN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Lesotho',
    iso2code: 'LS',
    value: 'LSO',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Liberia',
    iso2code: 'LR',
    value: 'LBR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Libya',
    iso2code: 'LY',
    value: 'LBY',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Liechtenstein',
    iso2code: 'LI',
    value: 'LIE',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Lithuania',
    iso2code: 'LT',
    value: 'LTU',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Luxembourg',
    iso2code: 'LU',
    value: 'LUX',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Macau',
    iso2code: 'MO',
    value: 'MAC',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Macedonia',
    iso2code: 'MK',
    value: 'MKD',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Madagascar',
    iso2code: 'MG',
    value: 'MDG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mahore (also known as Mayotte)',
    iso2code: 'YT',
    value: 'MYT',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Malawi',
    iso2code: 'MW',
    value: 'MWI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Malaysia',
    iso2code: 'MY',
    value: 'MYS',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Maldives',
    iso2code: 'MV',
    value: 'MDV',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mali',
    iso2code: 'ML',
    value: 'MLI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Malta',
    iso2code: 'MT',
    value: 'MLT',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Martinique',
    iso2code: 'MQ',
    value: 'MTQ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mauritania',
    iso2code: 'MR',
    value: 'MRT',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mauritius',
    iso2code: 'MU',
    value: 'MUS',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mexico',
    iso2code: 'MX',
    value: 'MEX',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Moldova',
    iso2code: 'MD',
    value: 'MDA',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Monaco',
    iso2code: 'MC',
    value: 'MCO',
    zone: 1,
    dhlZone: 1,
    services: ['DE4', 'DG4', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Mongolia',
    iso2code: 'MN',
    value: 'MNG',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Montenegro',
    iso2code: 'ME',
    value: 'MNE',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Montserrat',
    iso2code: 'MS',
    value: 'MSR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Morocco',
    iso2code: 'MA',
    value: 'MAR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Mozambique',
    iso2code: 'MZ',
    value: 'MOZ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Myanmar',
    iso2code: 'MM',
    value: 'MMR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Namibia',
    iso2code: 'NA',
    value: 'NAM',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Nauru Island',
    iso2code: 'NR',
    value: 'NRU',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Nepal',
    iso2code: 'NP',
    value: 'NPL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Netherlands',
    iso2code: 'NL',
    value: 'NLD',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'New Caledonia',
    iso2code: 'NC',
    value: 'NCL',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'New Zealand',
    iso2code: 'NZ',
    value: 'NZL',
    zone: 2,
    dhlZone: 8,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK'],
  },
  {
    label: 'Nicaragua',
    iso2code: 'NI',
    value: 'NIC',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Niger Republic',
    iso2code: 'NE',
    value: 'NER',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Nigeria',
    iso2code: 'NG',
    value: 'NGA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Niue Island',
    iso2code: 'NU',
    value: 'NIU',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'North Korea',
    iso2code: 'KP',
    value: 'PRK',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Norway',
    iso2code: 'NO',
    value: 'NOR',
    zone: 'EU',
    dhlZone: 4,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Oman',
    iso2code: 'OM',
    value: 'OMN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Pakistan',
    iso2code: 'PK',
    value: 'PAK',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Palau (known also as Belau)',
    iso2code: 'PW',
    value: 'PLW',
    zone: 2,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Panama',
    iso2code: 'PA',
    value: 'PAN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Papua New Guinea',
    iso2code: 'PG',
    value: 'PNG',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Paraguay',
    iso2code: 'PY',
    value: 'PRY',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Peru',
    iso2code: 'PE',
    value: 'PER',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Philippines',
    iso2code: 'PH',
    value: 'PHL',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Pitcairn Island',
    iso2code: 'PN',
    value: 'PCN',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Poland',
    iso2code: 'PL',
    value: 'POL',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Portugal',
    iso2code: 'PT',
    value: 'PRT',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Puerto Rico',
    iso2code: 'PR',
    value: 'PRI',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Qatar',
    iso2code: 'QA',
    value: 'QAT',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Reunion Island',
    iso2code: 'RE',
    value: 'REU',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Reunion',
    iso2code: 'RE',
    value: 'REU',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Romania',
    iso2code: 'RO',
    value: 'ROU',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Russian Federation',
    iso2code: 'RU',
    value: 'RUS',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK'],
  },
  {
    label: 'Russia',
    iso2code: 'RU',
    value: 'RUS',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK'],
  },
  {
    label: 'Rwanda',
    iso2code: 'RW',
    value: 'RWA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'San Marino',
    iso2code: 'SM',
    value: 'SMR',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Sao Tome & Principe',
    iso2code: 'ST',
    value: 'STP',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Saudi Arabia',
    iso2code: 'SA',
    value: 'SAU',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Senegal',
    iso2code: 'SN',
    value: 'SEN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Serbia',
    iso2code: 'RS',
    value: 'SRB',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK'],
  },
  {
    label: 'Seychelles',
    iso2code: 'SC',
    value: 'SYC',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Sierra Leone',
    iso2code: 'SL',
    value: 'SLE',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Singapore',
    iso2code: 'SG',
    value: 'SGP',
    zone: 2,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Slovak Republic',
    iso2code: 'SK',
    value: 'SVK',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Slovakia',
    iso2code: 'SK',
    value: 'SVK',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Slovenia',
    iso2code: 'SI',
    value: 'SVN',
    zone: 'EU',
    dhlZone: 2,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Solomon Islands',
    iso2code: 'SB',
    value: 'SLB',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'South Africa ( Republic of)',
    iso2code: 'ZA',
    value: 'ZAF',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'South Africa',
    iso2code: 'ZA',
    value: 'ZAF',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'South Korea (Republic of)',
    iso2code: 'KR',
    value: 'KOR',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'South Korea',
    iso2code: 'KR',
    value: 'KOR',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'South Sudan',
    iso2code: 'SS',
    value: 'SSD',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Spain',
    iso2code: 'ES',
    value: 'ESP',
    zone: 'EU',
    dhlZone: 1,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Sri Lanka',
    iso2code: 'LK',
    value: 'LKA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Eustatius',
    iso2code: 'BQ',
    value: 'BES',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Helena',
    iso2code: 'SH',
    value: 'SHN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Kitts & Nevis',
    iso2code: 'KN',
    value: 'KNA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Lucia',
    iso2code: 'LC',
    value: 'LCA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Maarten',
    iso2code: 'SX',
    value: 'SXM',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'St Vincent & The Grenadines',
    iso2code: 'VC',
    value: 'VCT',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Sudan',
    iso2code: 'SD',
    value: 'SDN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Suriname',
    iso2code: 'SR',
    value: 'SUR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Swaziland',
    iso2code: 'SZ',
    value: 'SWZ',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Sweden',
    iso2code: 'SE',
    value: 'SWE',
    zone: 'EU',
    dhlZone: 3,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Switzerland',
    iso2code: 'CH',
    value: 'CHE',
    zone: 'EU',
    dhlZone: 4,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Syria',
    iso2code: 'SY',
    value: 'SYR',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Taiwan',
    iso2code: 'TW',
    value: 'TWN',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Tajikistan',
    iso2code: 'TJ',
    value: 'TJK',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Tanzania',
    iso2code: 'TZ',
    value: 'TZA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Thailand',
    iso2code: 'TH',
    value: 'THA',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Timor-Leste',
    iso2code: 'TL',
    value: 'TLS',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Togo',
    iso2code: 'TG',
    value: 'TGO',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Tokelau Islands',
    iso2code: 'TK',
    value: 'TKL',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Tonga',
    iso2code: 'TO',
    value: 'TON',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Trinidad & Tobago',
    iso2code: 'TT',
    value: 'TTO',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Tunisia',
    iso2code: 'TN',
    value: 'TUN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Turkey',
    iso2code: 'TR',
    value: 'TUR',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'Turkmenistan',
    iso2code: 'TM',
    value: 'TKM',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Turks & Caicos Islands',
    iso2code: 'TC',
    value: 'TCA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Tuvalu',
    iso2code: 'TV',
    value: 'TUV',
    zone: 2,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Uganda',
    iso2code: 'UG',
    value: 'UGA',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Ukraine',
    iso2code: 'UA',
    value: 'UKR',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'United Arab Emirates',
    iso2code: 'AE',
    value: 'ARE',
    zone: 1,
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Uruguay',
    iso2code: 'UY',
    value: 'URY',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'United Kingdom',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'Guernsey',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'Isle of Man',
    value: 'GBR',
    zone: 'DOMESTIC',
  },
  {
    label: 'United States',
    iso2code: 'US',
    value: 'USA',
    zone: 3,
    dhlZone: 6,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPT', 'GPP'],
  },
  {
    label: 'U.S Virgin Islands',
    iso2code: 'VI',
    value: 'VIR',
    zone: 3,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'GMP'],
  },
  {
    label: 'Uzbekistan',
    iso2code: 'UZ',
    value: 'UZB',
    zone: 'EU',
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Vanuatu',
    iso2code: 'VU',
    value: 'VUT',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'GMP', 'GPP'],
  },
  {
    label: 'Vatican City State',
    iso2code: 'VA',
    value: 'VAT',
    zone: 'EU',
    dhlZone: 7,
    services: ['DE4', 'DG4', 'MTE', 'MTG', 'MP7', 'MTK', 'GMP', 'GPP'],
  },
  {
    label: 'Venezuela',
    iso2code: 'VE',
    value: 'VEN',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Vietnam',
    iso2code: 'VN',
    value: 'VNM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Wallis & Futuna Islands',
    iso2code: 'WF',
    value: 'WLF',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Western Sahara',
    iso2code: 'EH',
    value: 'ESH',
    zone: 1,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Western Samoa',
    iso2code: 'WS',
    value: 'WSM',
    zone: 2,
    dhlZone: 10,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Yemen, Republic of',
    iso2code: 'YE',
    value: 'YEM',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO'],
  },
  {
    label: 'Zambia',
    iso2code: 'ZM',
    value: 'ZMB',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
  {
    label: 'Zimbabwe',
    iso2code: 'ZW',
    value: 'ZWE',
    zone: 1,
    dhlZone: 9,
    services: ['DE4', 'DG4', 'MP9', 'MTO', 'GMP', 'GPP'],
  },
];


export const countriesWithSupplement = [
  {
    label: 'Argentina',
    iso2code: 'AR',
    value: 'ARG',
    standardAmount: 3.75,
    trackedAmount: 3.20,
  },
  {
    label: 'Australia',
    iso2code: 'AU',
    value: 'AUS',
    standardAmount: 3.20,
    trackedAmount: 2.70,
  },
  {
    label: 'Brazil',
    iso2code: 'BR',
    value: 'BRA',
    standardAmount: 4.10,
    trackedAmount: 2.85,
  },
  {
    label: 'Canada',
    iso2code: 'CA',
    value: 'CAN',
    standardAmount: 2.56,
    trackedAmount: 2.56,
  },
  {
    label: 'China (Peoples Republic of)',
    iso2code: 'CN',
    value: 'CHN',
    standardAmount: 0.90,
    trackedAmount: 0.40,
  },
  {
    label: 'China',
    iso2code: 'CN',
    value: 'CHN',
    standardAmount: 0.90,
    trackedAmount: 0.40,
  },
  {
    label: 'Cyprus',
    iso2code: 'CY',
    value: 'CYP',
    standardAmount: 1.29,
    trackedAmount: 1.29,
  },
  {
    label: 'Iceland',
    iso2code: 'IS',
    value: 'ISL',
    standardAmount: 0.85,
    trackedAmount: 0.35,
  },
  {
    label: 'India',
    iso2code: 'IN',
    value: 'IND',
    standardAmount: 1.05,
    trackedAmount: 1.05,
  },
  {
    label: 'Israel',
    iso2code: 'IL',
    value: 'ISR',
    standardAmount: 0.90,
    trackedAmount: 0.40,
  },
  {
    label: 'Japan',
    iso2code: 'JP',
    value: 'JPN',
    standardAmount: 2.78,
    trackedAmount: 2.78,
  },
  {
    label: 'Jordan',
    iso2code: 'JO',
    value: 'JOR',
    standardAmount: 1.59,
    trackedAmount: 1.59,
  },
  {
    label: 'Malaysia',
    iso2code: 'MY',
    value: 'MYS',
    standardAmount: 2.20,
    trackedAmount: 1.30,
  },
  {
    label: 'Mexico',
    iso2code: 'MX',
    value: 'MEX',
    standardAmount: 2.85,
    trackedAmount: 2.85,
  },
  {
    label: 'New Zealand',
    iso2code: 'NZ',
    value: 'NZL',
    standardAmount: 5.23,
    trackedAmount: 5.23,
  },
  {
    label: 'Pakistan',
    iso2code: 'PK',
    value: 'PAK',
    standardAmount: 1.40,
    trackedAmount: 1.40,
  },
  {
    label: 'Russian Federation',
    iso2code: 'RU',
    value: 'RUS',
    standardAmount: 0.85,
    trackedAmount: 0.85,
  },
  {
    label: 'Russia',
    iso2code: 'RU',
    value: 'RUS',
    standardAmount: 0.85,
    trackedAmount: 0.85,
  },
  {
    label: 'Saudi Arabia',
    iso2code: 'SA',
    value: 'SAU',
    standardAmount: 1.31,
    trackedAmount: 1.31,
  },
  {
    label: 'Singapore',
    iso2code: 'SG',
    value: 'SGP',
    standardAmount: 3.45,
    trackedAmount: 2.95,
  },
  {
    label: 'South Africa ( Republic of)',
    iso2code: 'ZA',
    value: 'ZAF',
    standardAmount: 1.81,
    trackedAmount: 1.81,
  },
  {
    label: 'South Korea (Republic of)',
    iso2code: 'KR',
    value: 'KOR',
    standardAmount: 3.20,
    trackedAmount: 2.70,
  },
  {
    label: 'Taiwan',
    iso2code: 'TW',
    value: 'TWN',
    standardAmount: 1.55,
    trackedAmount: 1.55,
  },
  {
    label: 'United Arab Emirates',
    iso2code: 'AE',
    value: 'ARE',
    standardAmount: 1.20,
    trackedAmount: 1.20,
  },
  {
    label: 'United States',
    iso2code: 'US',
    value: 'USA',
    standardAmount: 2.75,
    trackedAmount: 2.25,
  },
];
