/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-array-index-key */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup, ListGroup, ListGroupItem, Table, Input } from 'reactstrap';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import ActionButton from './ActionButton';


const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://marketplace-api.musicmarquee.co.uk';


class ToolsUpdateOrders extends Component {
  constructor() {
    super();
    this.state = {
      updateOrdersResponse: { complete: false, response: undefined },
      newOrderResponse: { complete: false, response: undefined },
      shippedResponse: { complete: false, response: undefined },
    };
  }

  async getNewShopifyOrders() {
    const response = await axios.post(`${URL}/admin/shopify/orders`, { store: 'all' }, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    console.log(response.data);
    this.setState({
      newOrderResponse: { complete: true, response: response.data.reduce((p, c) => [p, <br />, c]) },
    });
    return true;
  }

  async updateShopifyOrders() {
    const response = await axios.post(`${URL}/admin/shopify/orders/update`, { store: 'all' }, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    this.setState({
      updateOrdersResponse: { complete: true, response: response.data.reduce((p, c) => [p, <br />, c]) },
    });
    return true;
  }

  async shipShopifyOrders() {
    const response = await axios.post(`${URL}/admin/shopify/orders/ship`, { store: 'tints-direct' }, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    this.setState({
      shippedResponse: { complete: true, response: `Shipped ${response.data.length} Orders.` },
    });
    return true;
  }

  render() {
    return (
      <Col style={{ height: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardBody>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Complete</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: '20%' }}>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Update Shopify Orders"
                      task={() => this.updateShopifyOrders()}
                    />
                  </td>
                  <td style={{ width: '10%' }}>{this.state.updateOrdersResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td style={{ width: '70%' }}>{this.state.updateOrdersResponse.response || ''}</td>
                </tr>
                <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Get New Shopify Orders"
                      task={() => this.getNewShopifyOrders()}
                    />
                  </td>
                  <td>{this.state.newOrderResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.newOrderResponse.response || ''}</td>
                </tr>
                <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Mark as shipped"
                      task={() => this.shipShopifyOrders()}
                    />
                  </td>
                  <td>{this.state.shippedResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.shippedResponse.response || ''}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ToolsUpdateOrders);
