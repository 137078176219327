export const domesticServices = [
  {
    value: 'CRL24S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      1000: 3.93,
      1250: 4.11,
      1500: 4.28,
      1750: 4.43,
      2000: 4.69,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.48,
      2000: 6.45,
    },
  },
  {
    value: 'TPN',
    label: 'Royal Mail Tracked 24',
    compensation: 20,
    obaPricing: {
      1000: 4.21,
      1250: 4.21,
      1500: 4.21,
      1750: 4.21,
      2000: 4.21,
      20000: 4.21,
    },
    sizes: ['SP'],
    prices: {
      1000: 5.39,
      2000: 5.39,
      20000: 10.00,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      100: 0.73,
      250: 1.03,
      500: 1.15,
      750: 1.53,
    },
    sizes: ['LL'],
    prices: {
      100: 0.73,
      250: 1.03,
      500: 1.15,
      750: 1.53,
    },
  },
  {
    value: 'CRL48',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      1000: 2.32,
      1250: 2.61,
      1500: 2.61,
      1750: 2.61,
      2000: 2.61,
    },
    sizes: ['SP'],
    prices: {
      1000: 2.95,
      2000: 2.95,
    },
  },
  {
    value: 'TPS',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      1000: 3.05,
      1250: 3.05,
      1500: 3.05,
      1750: 3.05,
      2000: 3.05,
      20000: 3.05,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.10,
      2000: 4.10,
      20000: 8.00,
    },
  },
  {
    value: 'TRS',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      100: 2.47,
      250: 2.47,
      500: 2.47,
      750: 2.47,
    },
    sizes: ['LL'],
    prices: {
      100: 2.65,
      250: 3.35,
      500: 3.90,
      750: 4.20,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      100: 1.83,
      250: 2.13,
      500: 2.25,
      750: 2.63,
    },
    sizes: ['LL'],
    prices: {
      100: 1.96,
      250: 2.26,
      500: 2.38,
      750: 2.76,
    },
  },
  {
    value: 'CRL48S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      1000: 3.45,
      1250: 3.74,
      1500: 3.74,
      1750: 3.74,
      2000: 3.74,
    },
    sizes: ['SP'],
    prices: {
      1000: 3.87,
      2000: 3.87,
    },
  },
];

export const internationalServices = [
  {
    value: 'MP1',
    label: 'Royal Mail International Tracked',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.65,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.15,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  }, {
    value: 'MTI',
    label: 'Royal Mail International Tracked',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.45,
        PPK: 3.85,
      },
      ROW: {
        PPI: 5.90,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  }, {
    value: 'MP5',
    label: 'Royal Mail International Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.05,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.05,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  },
  {
    value: 'MP7',
    label: 'Royal Mail International Tracked',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 6.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.200,
        PPK: 3.800,
      },
      HRV: {
        PPI: 5.700,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.300,
        PPK: 4.000,
      },
      CZE: {
        PPI: 6.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.100,
        PPK: 4.200,
      },
      EST: {
        PPI: 6.250,
        PPK: 3.950,
      },
      FIN: {
        PPI: 6.850,
        PPK: 5.250,
      },
      FRA: {
        PPI: 5.300,
        PPK: 3.100,
      },
      DEU: {
        PPI: 5.450,
        PPK: 3.800,
      },
      GRC: {
        PPI: 6.700,
        PPK: 4.700,
      },
      HUN: {
        PPI: 6.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.100,
        PPK: 3.600,
      },
      ITA: {
        PPI: 5.900,
        PPK: 4.400,
      },
      LVA: {
        PPI: 6.950,
        PPK: 4.050,
      },
      LTU: {
        PPI: 6.200,
        PPK: 3.500,
      },
      LUX: {
        PPI: 7.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.550,
        PPK: 5.250,
      },
      NLD: {
        PPI: 5.400,
        PPK: 3.300,
      },
      POL: {
        PPI: 6.300,
        PPK: 3.400,
      },
      PRT: {
        PPI: 5.400,
        PPK: 4.400,
      },
      ROU: {
        PPI: 6.850,
        PPK: 3.950,
      },
      SVK: {
        PPI: 6.300,
        PPK: 3.800,
      },
      SVN: {
        PPI: 6.650,
        PPK: 3.900,
      },
      ESP: {
        PPI: 4.950,
        PPK: 3.450,
      },
      SWE: {
        PPI: 5.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.000,
        PPK: 6.400,
      },
      ISL: {
        PPI: 6.000,
        PPK: 8.750,
      },
      NOR: {
        PPI: 7.000,
        PPK: 5.300,
      },
      RUS: {
        PPI: 6.400,
        PPK: 4.550,
      },
      CHE: {
        PPI: 6.200,
        PPK: 5.000,
      },
      SRB: {
        PPI: 6.450,
        PPK: 8.150,
      },
      TUR: {
        PPI: 6.300,
        PPK: 5.000,
      },
      USA: {
        PPI: 5.750,
        PPK: 5.500,
      },
      ISR: {
        PPI: 6.450,
        PPK: 6.100,
      },
      LBN: {
        PPI: 6.450,
        PPK: 10.100,
      },
      AUS: {
        PPI: 6.250,
        PPK: 6.750,
      },
      HKG: {
        PPI: 6.450,
        PPK: 3.850,
      },
      JPN: {
        PPI: 6.450,
        PPK: 5.500,
      },
      MYS: {
        PPI: 5.700,
        PPK: 6.150,
      },
      NZL: {
        PPI: 5.350,
        PPK: 8.250,
      },
      SGP: {
        PPI: 6.650,
        PPK: 3.900,
      },
      KOR: {
        PPI: 6.000,
        PPK: 4.800,
      },
      TWN: {
        PPI: 6.800,
        PPK: 5.100,
      },
      THA: {
        PPI: 5.400,
        PPK: 9.100,
      },
      BRA: {
        PPI: 6.000,
        PPK: 7.400,
      },
      IND: {
        PPI: 5.650,
        PPK: 6.750,
      },
      // ROEU: {
      //   PPI: 0.000,
      //   PPK: 0.000,
      // },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 6.700,
        PPK: 4.350,
      },
      ROZ2: {
        PPI: 6.700,
        PPK: 4.350,
      },
    },
    prices: {
      250: {
        EU: 10.25,
        1: 12.70,
        2: 14.15,
        3: 13.45,
      },
      500: {
        EU: 11.70,
        1: 16.35,
        2: 18.55,
        3: 18.10,
      },
      750: {
        EU: 12.75,
        1: 18.75,
        2: 20.85,
        3: 20.85,
      },
      1000: {
        EU: 13.70,
        1: 21.40,
        2: 24.50,
        3: 24.50,
      },
      1250: {
        EU: 14.10,
        1: 23.25,
        2: 26.90,
        3: 27.95,
      },
      1500: {
        EU: 14.85,
        1: 24.65,
        2: 29.40,
        3: 30.70,
      },
      2000: {
        EU: 15.25,
        1: 25.65,
        2: 31.00,
        3: 31.95,
      },
    },
  }, {
    value: 'MP9',
    label: 'Royal Mail International Signed',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 7.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.900,
        PPK: 3.800,
      },
      BGR: {
        PPI: 7.800,
        PPK: 3.700,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.600,
        PPK: 4.000,
      },
      CZE: {
        PPI: 7.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.750,
        PPK: 4.650,
      },
      EST: {
        PPI: 6.850,
        PPK: 4.300,
      },
      FIN: {
        PPI: 7.450,
        PPK: 5.550,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 8.400,
        PPK: 0.000,
      },
      GRC: {
        PPI: 7.100,
        PPK: 4.700,
      },
      HUN: {
        PPI: 7.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 7.300,
        PPK: 4.550,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 8.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.800,
        PPK: 5.600,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 7.300,
        PPK: 3.600,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 7.450,
        PPK: 3.950,
      },
      SVK: {
        PPI: 7.300,
        PPK: 4.400,
      },
      SVN: {
        PPI: 7.650,
        PPK: 4.500,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.850,
        PPK: 6.550,
      },
      ISL: {
        PPI: 6.400,
        PPK: 8.950,
      },
      NOR: {
        PPI: 8.100,
        PPK: 5.700,
      },
      RUS: {
        PPI: 7.200,
        PPK: 5.100,
      },
      CHE: {
        PPI: 6.550,
        PPK: 5.350,
      },
      SRB: {
        PPI: 7.050,
        PPK: 8.350,
      },
      TUR: {
        PPI: 6.700,
        PPK: 5.250,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 7.150,
        PPK: 8.110,
      },
      LBN: {
        PPI: 7.050,
        PPK: 10.300,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 7.350,
        PPK: 4.450,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 7.400,
        PPK: 5.300,
      },
      THA: {
        PPI: 7.400,
        PPK: 10.100,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.850,
        PPK: 10.600,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 7.330,
        PPK: 10.040,
      },
      ROZ2: {
        PPI: 7.330,
        PPK: 10.040,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
      },
      750: {
        EU: 13.15,
        1: 19.50,
        2: 21.60,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
      },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
      },
    },
  }, {
    value: 'MTM',
    label: 'Royal Mail International Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 5.80,
        PPK: 3.85,
      },
      ROW: {
        PPI: 5.80,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  },
  {
    value: 'MTA',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['SP'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.65,
        PPK: 3.65,
      },
      ROW: {
        PPI: 6.65,
        PPK: 5.80,
      },
    },
    prices: {
      500: {
        EU: 9.88,
        1: 11.90,
        2: 12.48,
      },
      750: {
        EU: 10.91,
        1: 13.96,
        2: 14.58,
      },
      1000: {
        EU: 11.65,
        1: 15.65,
        2: 16.27,
      },
      1250: {
        EU: 12.23,
        1: 16.39,
        2: 17.18,
      },
      1500: {
        EU: 13.10,
        1: 17.54,
        2: 18.57,
      },
      1750: {
        EU: 13.67,
        1: 18.52,
        2: 19.69,
      },
      2000: {
        EU: 13.92,
        1: 19.03,
        2: 20.47,
      },
    },
  },
  {
    value: 'MTE',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 7.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.900,
        PPK: 3.800,
      },
      BGR: {
        PPI: 7.800,
        PPK: 3.700,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.600,
        PPK: 4.000,
      },
      CZE: {
        PPI: 7.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.750,
        PPK: 4.650,
      },
      EST: {
        PPI: 6.850,
        PPK: 4.300,
      },
      FIN: {
        PPI: 7.450,
        PPK: 5.550,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 8.400,
        PPK: 0.000,
      },
      GRC: {
        PPI: 7.100,
        PPK: 4.700,
      },
      HUN: {
        PPI: 7.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 7.300,
        PPK: 4.550,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 8.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.800,
        PPK: 5.600,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 7.300,
        PPK: 3.600,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 7.450,
        PPK: 3.950,
      },
      SVK: {
        PPI: 7.300,
        PPK: 4.400,
      },
      SVN: {
        PPI: 7.650,
        PPK: 4.500,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.850,
        PPK: 6.550,
      },
      ISL: {
        PPI: 6.400,
        PPK: 8.950,
      },
      NOR: {
        PPI: 8.100,
        PPK: 5.700,
      },
      RUS: {
        PPI: 7.200,
        PPK: 5.100,
      },
      CHE: {
        PPI: 6.550,
        PPK: 5.350,
      },
      SRB: {
        PPI: 7.050,
        PPK: 8.350,
      },
      TUR: {
        PPI: 6.700,
        PPK: 5.250,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 7.150,
        PPK: 8.110,
      },
      LBN: {
        PPI: 7.050,
        PPK: 10.300,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 7.350,
        PPK: 4.450,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 7.400,
        PPK: 5.300,
      },
      THA: {
        PPI: 7.400,
        PPK: 10.100,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.850,
        PPK: 10.600,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 7.330,
        PPK: 10.040,
      },
      ROZ2: {
        PPI: 7.330,
        PPK: 10.040,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
        3: 15.45,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
        3: 20.85,
      },
      750: {
        EU: 13.15,
        1: 19.50,
        2: 21.60,
        3: 22.85,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
        3: 26.50,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
        3: 29.95,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
        3: 32.70,
      },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
        3: 33.20,
      },
    },
  }, {
    value: 'MTC',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['LL'],
    compensation: 100,
    obaPricing: {
      EU: {
        PPI: 6.20,
        PPK: 3.85,
      },
      ROW: {
        PPI: 6.20,
        PPK: 5.90,
      },
    },
    prices: {
      100: {
        EU: 6.77,
        1: 7.33,
        2: 7.45,
      },
      250: {
        EU: 7.69,
        1: 8.56,
        2: 8.81,
      },
      500: {
        EU: 8.13,
        1: 10.01,
        2: 10.33,
      },
      750: {
        EU: 8.81,
        1: 11.53,
        2: 11.98,
      },
    },
  },
  {
    value: 'DE4',
    label: 'Royal Mail International Standard',
    sizes: ['SP'],
    compensation: 20,
    obaPricing: {
      AUT: {
        PPI: 3.300,
        PPK: 0.320,
      },
      BEL: {
        PPI: 2.180,
        PPK: 4.190,
      },
      BGR: {
        PPI: 1.720,
        PPK: 3.950,
      },
      HRV: {
        PPI: 2.430,
        PPK: 5.130,
      },
      CYP: {
        PPI: 1.760,
        PPK: 4.300,
      },
      CZE: {
        PPI: 1.340,
        PPK: 3.740,
      },
      DNK: {
        PPI: 2.700,
        PPK: 2.680,
      },
      EST: {
        PPI: 2.160,
        PPK: 3.270,
      },
      FIN: {
        PPI: 2.420,
        PPK: 3.700,
      },
      FRA: {
        PPI: 2.440,
        PPK: 1.000,
      },
      DEU: {
        PPI: 2.370,
        PPK: 1.340,
      },
      GRC: {
        PPI: 2.160,
        PPK: 4.450,
      },
      HUN: {
        PPI: 2.180,
        PPK: 2.860,
      },
      IRL: {
        PPI: 2.040,
        PPK: 3.810,
      },
      ITA: {
        PPI: 2.050,
        PPK: 2.800,
      },
      LVA: {
        PPI: 0.860,
        PPK: 12.320,
      },
      LTU: {
        PPI: 1.760,
        PPK: 3.060,
      },
      LUX: {
        PPI: 2.310,
        PPK: 3.250,
      },
      MLT: {
        PPI: 1.960,
        PPK: 5.110,
      },
      NLD: {
        PPI: 1.970,
        PPK: 2.740,
      },
      POL: {
        PPI: 2.070,
        PPK: 3.530,
      },
      PRT: {
        PPI: 2.070,
        PPK: 3.530,
      },
      ROU: {
        PPI: 1.760,
        PPK: 3.300,
      },
      SVK: {
        PPI: 1.310,
        PPK: 4.430,
      },
      SVN: {
        PPI: 2.260,
        PPK: 4.210,
      },
      ESP: {
        PPI: 1.980,
        PPK: 3.360,
      },
      SWE: {
        PPI: 1.940,
        PPK: 2.450,
      },
      GIB: {
        PPI: 1.360,
        PPK: 4.390,
      },
      ISL: {
        PPI: 2.170,
        PPK: 5.730,
      },
      NOR: {
        PPI: 3.090,
        PPK: 4.100,
      },
      RUS: {
        PPI: 2.210,
        PPK: 4.700,
      },
      CHE: {
        PPI: 3.850,
        PPK: 1.620,
      },
      SRB: {
        PPI: 1.150,
        PPK: 9.720,
      },
      TUR: {
        PPI: 1.980,
        PPK: 3.530,
      },
      USA: {
        PPI: 4.220,
        PPK: 7.580,
      },
      ISR: {
        PPI: 1.610,
        PPK: 4.230,
      },
      SAU: {
        PPI: 2.070,
        PPK: 4.800,
      },
      ARE: {
        PPI: 1.900,
        PPK: 4.370,
      },
      AUS: {
        PPI: 1.940,
        PPK: 9.100,
      },
      CHN: {
        PPI: 1.910,
        PPK: 5.360,
      },
      HKG: {
        PPI: 1.900,
        PPK: 3.070,
      },
      JPN: {
        PPI: 2.280,
        PPK: 8.400,
      },
      MYS: {
        PPI: 1.760,
        PPK: 5.220,
      },
      NZL: {
        PPI: 1.500,
        PPK: 12.440,
      },
      SGP: {
        PPI: 1.760,
        PPK: 6.390,
      },
      KOR: {
        PPI: 1.900,
        PPK: 6.220,
      },
      THA: {
        PPI: 1.190,
        PPK: 12.540,
      },
      TWN: {
        PPI: 1.720,
        PPK: 4.330,
      },
      VNM: {
        PPI: 1.190,
        PPK: 12.540,
      },
      ARG: {
        PPI: 0.990,
        PPK: 18.32,
      },
      BRA: {
        PPI: 2.590,
        PPK: 7.160,
      },
      MEX: {
        PPI: 0.860,
        PPK: 12.320,
      },
      IND: {
        PPI: 0.900,
        PPK: 9.410,
      },
      KEN: {
        PPI: 0.990,
        PPK: 11.970,
      },
      NGA: {
        PPI: 0.990,
        PPK: 12.680,
      },
      PAK: {
        PPI: 0.990,
        PPK: 12.670,
      },
      ZAF: {
        PPI: 2.010,
        PPK: 5.420,
      },
      ROEU: {
        PPI: 1.150,
        PPK: 9.720,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 1.240,
        PPK: 13.610,
      },
      ROZ2: {
        PPI: 1.240,
        PPK: 13.610,
      },
    },
    prices: {
      250: {
        EU: 5.95,
        1: 8.30,
        2: 9.90,
        3: 10.95,
      },
      500: {
        EU: 7.80,
        1: 12.10,
        2: 14.50,
        3: 16.60,
      },
      750: {
        EU: 9.05,
        1: 14.85,
        2: 17.60,
        3: 19.35,
      },
      1000: {
        EU: 10.20,
        1: 17.65,
        2: 20.85,
        3: 23.00,
      },
      1250: {
        EU: 11.05,
        1: 19.85,
        2: 23.75,
        3: 26.45,
      },
      1500: {
        EU: 12.10,
        1: 22.10,
        2: 26.85,
        3: 29.20,
      },
      2000: {
        EU: 13.00,
        1: 23.30,
        2: 28.55,
        3: 30.45,
      },
    },
  },
  {
    value: 'IE1',
    label: 'Royal Mail International Standard',
    sizes: ['SP'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 1.635,
        PPK: 4.250,
      },
      ROW: {
        PPI: 1.735,
        PPK: 6.755,
      },
      USA: {
        PPI: 3.500,
        PPK: 12.060,
      },
    },
    prices: {
      500: {
        EU: 5.54,
        1: 7.78,
        2: 8.40,
        3: 12.17,
      },
      750: {
        EU: 6.47,
        1: 9.50,
        2: 10.12,
        3: 16.02,
      },
      1000: {
        EU: 7.24,
        1: 11.22,
        2: 11.85,
        3: 19.88,
      },
      1250: {
        EU: 7.84,
        1: 12.60,
        2: 13.44,
        3: 23.74,
      },
      1500: {
        EU: 8.57,
        1: 13.83,
        2: 13.93,
        3: 25.27,
      },
      1750: {
        EU: 9.06,
        1: 14.07,
        2: 15.33,
        3: 27.50,
      },
      2000: {
        EU: 9.31,
        1: 14.66,
        2: 16.13,
        3: 29.71,
      },
    },
  },
  {
    value: 'IG1',
    label: 'Royal Mail International Standard',
    sizes: ['LL'],
    compensation: 20,
    obaPricing: {
      EU: {
        PPI: 0.915,
        PPK: 4.315,
      },
      ROW: {
        PPI: 0.935,
        PPK: 6.510,
      },
      USA: {
        PPI: 0.935,
        PPK: 6.510,
      },
    },
    prices: {
      100: {
        EU: 2.12,
        1: 2.68,
        2: 2.80,
        3: 2.68,
      },
      250: {
        EU: 3.04,
        1: 3.91,
        2: 4.16,
        3: 3.91,
      },
      500: {
        EU: 3.48,
        1: 5.36,
        2: 5.69,
        3: 5.36,
      },
      750: {
        EU: 4.16,
        1: 6.88,
        2: 7.33,
        3: 6.88,
      },
    },
  },
  {
    value: 'GPT',
    label: 'DHL Tracked',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.65,
        PPK: 3.00,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 3.00,
        PPK: 6.00,
      },
      3: {
        PPI: 4.00,
        PPK: 3.80,
      },
      4: {
        PPI: 5.00,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 5.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 4.65,
        PPK: 11.00,
      },
      7: {
        PPI: 3.95,
        PPK: 7.50,
      },
      8: {
        PPI: 4.60,
        PPK: 10.50,
      },
      9: {
        PPI: 1.80,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      250: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      500: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      750: {
        0: 11.84,
        1: 11.84,
        2: 11.84,
        3: 11.84,
        4: 11.84,
        // 5: 4.20, UK
        6: 20.57, // USA
        7: 17.10,
        8: 19.44,
        9: 19.44,
      },
      1000: {
        0: 12.69,
        1: 12.69,
        2: 12.69,
        3: 12.69,
        4: 12.69,
        // 5: 4.20, UK
        6: 23.85, // USA
        7: 19.49,
        8: 22.28,
        9: 22.28,
      },
      1250: {
        0: 13.05,
        1: 13.05,
        2: 13.05,
        3: 13.05,
        4: 13.05,
        // 5: 4.20, UK
        6: 26.96, // USA
        7: 21.15,
        8: 24.44,
        9: 24.44,
      },
      1500: {
        0: 13.55,
        1: 13.55,
        2: 13.55,
        3: 13.55,
        4: 13.55,
        // 5: 4.20, UK
        6: 29.43, // USA
        7: 22.41,
        8: 26.69,
        9: 26.69,
      },
      2000: {
        0: 13.73,
        1: 13.73,
        2: 13.73,
        3: 13.73,
        4: 13.73,
        // 5: 4.20, UK
        6: 29.88, // USA
        7: 22.82,
        8: 27.63,
        9: 27.63,
      },
    },
  },
  {
    value: 'GMP',
    label: 'DHL Signed',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 4.60,
        PPK: 3.00,
      },
      1: {
        PPI: 5.10,
        PPK: 4.00,
      },
      2: {
        PPI: 4.40,
        PPK: 6.00,
      },
      3: {
        PPI: 5.68,
        PPK: 3.80,
      },
      4: {
        PPI: 6.65,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 6.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 6.55,
        PPK: 11.00,
      },
      7: {
        PPI: 4.40,
        PPK: 7.50,
      },
      8: {
        PPI: 4.65,
        PPK: 10.50,
      },
      9: {
        PPI: 3.60,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      250: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      500: {
        0: 10.89,
        1: 10.89,
        2: 10.89,
        3: 10.89,
        4: 10.89,
        // 5: 4.20, UK
        6: 18.77, // USA
        7: 14.94,
        8: 16.92,
        9: 16.92,
      },
      750: {
        0: 11.84,
        1: 11.84,
        2: 11.84,
        3: 11.84,
        4: 11.84,
        // 5: 4.20, UK
        6: 20.57, // USA
        7: 17.10,
        8: 19.44,
        9: 19.44,
      },
      1000: {
        0: 12.69,
        1: 12.69,
        2: 12.69,
        3: 12.69,
        4: 12.69,
        // 5: 4.20, UK
        6: 23.85, // USA
        7: 19.49,
        8: 22.28,
        9: 22.28,
      },
      1250: {
        0: 13.05,
        1: 13.05,
        2: 13.05,
        3: 13.05,
        4: 13.05,
        // 5: 4.20, UK
        6: 26.96, // USA
        7: 21.15,
        8: 24.44,
        9: 24.44,
      },
      1500: {
        0: 13.55,
        1: 13.55,
        2: 13.55,
        3: 13.55,
        4: 13.55,
        // 5: 4.20, UK
        6: 29.43, // USA
        7: 22.41,
        8: 26.69,
        9: 26.69,
      },
      2000: {
        0: 13.73,
        1: 13.73,
        2: 13.73,
        3: 13.73,
        4: 13.73,
        // 5: 4.20, UK
        6: 29.88, // USA
        7: 22.82,
        8: 27.63,
        9: 27.63,
      },
    },
  },
  {
    value: 'GPT',
    label: 'DHL Tracked',
    sizes: ['LL'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.65,
        PPK: 3.00,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 3.00,
        PPK: 6.00,
      },
      3: {
        PPI: 4.00,
        PPK: 3.80,
      },
      4: {
        PPI: 5.00,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 5.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 4.65,
        PPK: 11.00,
      },
      7: {
        PPI: 3.95,
        PPK: 7.50,
      },
      8: {
        PPI: 4.60,
        PPK: 10.50,
      },
      9: {
        PPI: 1.80,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 7.88,
        1: 7.88,
        2: 7.88,
        3: 7.88,
        4: 7.88,
        // 5: 4.20, UK
        6: 8.78, // USA
        7: 8.73,
        8: 8.82,
        9: 8.82,
      },
      250: {
        0: 8.28,
        1: 8.28,
        2: 8.28,
        3: 8.28,
        4: 8.28,
        // 5: 4.20, UK
        6: 9.59, // USA
        7: 9.45,
        8: 10.39,
        9: 10.39,
      },
      500: {
        0: 8.96,
        1: 8.96,
        2: 8.96,
        3: 8.96,
        4: 8.96,
        // 5: 4.20, UK
        6: 11.34, // USA
        7: 11.07,
        8: 12.69,
        9: 12.69,
      },
    },
  },
  {
    value: 'GMP',
    label: 'DHL Signed',
    sizes: ['LL'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 4.60,
        PPK: 3.00,
      },
      1: {
        PPI: 5.10,
        PPK: 4.00,
      },
      2: {
        PPI: 4.40,
        PPK: 6.00,
      },
      3: {
        PPI: 5.68,
        PPK: 3.80,
      },
      4: {
        PPI: 6.65,
        PPK: 3.10,
      },
      // 5: {
      //   PPI: 6.00,
      //   PPK: 8.00,
      // },
      6: {
        PPI: 6.55,
        PPK: 11.00,
      },
      7: {
        PPI: 4.40,
        PPK: 7.50,
      },
      8: {
        PPI: 4.65,
        PPK: 10.50,
      },
      9: {
        PPI: 3.60,
        PPK: 13.50,
      },
    },
    prices: {
      100: {
        0: 7.88,
        1: 7.88,
        2: 7.88,
        3: 7.88,
        4: 7.88,
        // 5: 4.20, UK
        6: 8.78, // USA
        7: 8.73,
        8: 8.82,
        9: 8.82,
      },
      250: {
        0: 8.28,
        1: 8.28,
        2: 8.28,
        3: 8.28,
        4: 8.28,
        // 5: 4.20, UK
        6: 9.59, // USA
        7: 9.45,
        8: 10.39,
        9: 10.39,
      },
      500: {
        0: 8.96,
        1: 8.96,
        2: 8.96,
        3: 8.96,
        4: 8.96,
        // 5: 4.20, UK
        6: 11.34, // USA
        7: 11.07,
        8: 12.69,
        9: 12.69,
      },
    },
  },
];

export const packageSizes = [
  {
    value: 'LL',
    label: 'Large Letter',
    max_weight: 750,
  },
  {
    value: 'SP',
    label: 'Small Parcel',
    max_weight: 20000,
  },
];
