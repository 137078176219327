export const legacyServiceCodes = {
  IE1: 'DE4',
  IG1: 'DG4',
  MTA: 'MTE',
  MTC: 'MTG',
  MP1: 'MP7',
  MP5: 'MP9',
  MTM: 'MTO',
  CRL24: 'CRL1',
  CRL48: 'CRL2',
  TPN24SS: 'TPN',
  TRN24SS: 'TRN',
  TPS48SS: 'TPS',
  TRS48SS: 'TRS',
  TPSN: 'TPS',
  TRSN: 'TRS',
  TPNN: 'TPN',
  TRNN: 'TRN',

};

export const domesticServices = [
  {
    value: 'DPDND',
    label: 'DPD Next Day',
    compensation: 20,
    obaPricing: {
      1000: 0.00,
      1250: 0.00,
      1500: 0.00,
      1750: 0.00,
      2000: 0.00,
      20000: 0.00,
    },
    sizes: ['SP'],
    prices: {
      1000: 0.00,
      2000: 0.00,
      20000: 0.00,
    },
  },
  {
    value: 'CRL1',
    label: 'Royal Mail 1st Class',
    compensation: 20,
    obaPricing: {
      100: 1.20,
      250: 1.70,
      500: 1.85,
      750: 2.40,
    },
    sizes: ['LL'],
    prices: {
      100: 1.60,
      250: 2.25,
      500: 2.95,
      750: 3.30,
    },
  },
  {
    value: 'TPN',
    label: 'Royal Mail Tracked 24',
    compensation: 20,
    obaPricing: {
      1000: 4.21,
      1250: 4.21,
      1500: 4.21,
      1750: 4.21,
      2000: 4.21,
      20000: 4.21,
    },
    sizes: ['SP'],
    prices: {
      1000: 5.39,
      2000: 5.39,
      20000: 10.00,
    },
  },
  {
    value: 'TRN',
    label: 'Royal Mail Tracked 24',
    compensation: 100,
    obaPricing: {
      100: 3.32,
      250: 3.32,
      500: 3.32,
      750: 3.32,
    },
    sizes: ['LL'],
    prices: {
      100: 3.10,
      250: 3.75,
      500: 4.45,
      750: 4.80,
    },
  },
  {
    value: 'CRL1',
    label: 'Royal Mail 1st Class',
    compensation: 20,
    obaPricing: {
      1000: 3.70,
      1250: 3.90,
      1500: 3.90,
      1750: 3.90,
      2000: 3.90,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.19,
      2000: 4.19,
    },
  },
  {
    value: 'CRL2',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      100: 0.95,
      250: 1.30,
      500: 1.50,
      750: 1.95,
    },
    sizes: ['LL'],
    prices: {
      100: 1.15,
      250: 1.85,
      500: 2.40,
      750: 2.70,
    },
  },
  {
    value: 'CRL2',
    label: 'Royal Mail 2nd Class',
    compensation: 20,
    obaPricing: {
      1000: 2.80,
      1250: 3.10,
      1500: 3.10,
      1750: 3.10,
      2000: 3.10,
    },
    sizes: ['SP'],
    prices: {
      1000: 3.49,
      2000: 3.49,
    },
  },
  {
    value: 'TPS',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      1000: 3.14,
      1250: 3.14,
      1500: 3.14,
      1750: 3.14,
      2000: 3.14,
      20000: 3.14,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.69,
      2000: 4.69,
      20000: 9.00,
    },
  },
  {
    value: 'TRS',
    label: 'Royal Mail Tracked 48',
    compensation: 100,
    obaPricing: {
      100: 2.47,
      250: 2.47,
      500: 2.47,
      750: 2.47,
    },
    sizes: ['LL'],
    prices: {
      100: 2.65,
      250: 3.35,
      500: 3.90,
      750: 4.20,
    },
  },
  {
    value: 'CRL1S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      100: 2.34,
      250: 2.75,
      500: 3.17,
      750: 3.90,
    },
    sizes: ['LL'],
    prices: {
      100: 2.69,
      250: 3.23,
      500: 3.79,
      750: 4.70,
    },
  },
  {
    value: 'CRL1S',
    label: 'Royal Mail Signed For® 1st Class',
    compensation: 50,
    obaPricing: {
      1000: 4.58,
      1250: 6.47,
      1500: 6.47,
      1750: 6.47,
      2000: 6.47,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.85,
      2000: 6.57,
    },
  },
  {
    value: 'CRL2S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      100: 2.11,
      250: 2.40,
      500: 2.71,
      750: 3.25,
    },
    sizes: ['LL'],
    prices: {
      100: 2.36,
      250: 2.93,
      500: 3.39,
      750: 4.10,
    },
  },
  {
    value: 'CRL2S',
    label: 'Royal Mail Signed For® 2nd Class',
    compensation: 50,
    obaPricing: {
      1000: 3.95,
      1250: 3.95,
      1500: 3.95,
      1750: 3.95,
      2000: 3.95,
    },
    sizes: ['SP'],
    prices: {
      1000: 4.20,
      2000: 4.20,
    },
  },
  {
    value: 'SD1',
    label: 'Royal Mail Special Delivery Guaranteed by 1pm®',
    compensation: 750,
    obaPricing: {
      500: 6.55,
      1000: 7.65,
      2000: 9.75,
    },
    sizes: ['LL', 'SP'],
    prices: {
      500: 7.65,
      1000: 8.95,
      2000: 11.15,
    },
  },
];

export const internationalServices = [
  {
    value: 'DE4',
    label: 'Royal Mail International Standard',
    sizes: ['SP'],
    compensation: 20,
    obaPricing: {
      AUT: {
        PPI: 3.300,
        PPK: 0.320,
      },
      BEL: {
        PPI: 2.180,
        PPK: 4.190,
      },
      BGR: {
        PPI: 1.720,
        PPK: 3.950,
      },
      HRV: {
        PPI: 2.430,
        PPK: 5.130,
      },
      CYP: {
        PPI: 1.760,
        PPK: 4.300,
      },
      CZE: {
        PPI: 1.340,
        PPK: 3.740,
      },
      DNK: {
        PPI: 2.700,
        PPK: 2.680,
      },
      EST: {
        PPI: 2.160,
        PPK: 3.270,
      },
      FIN: {
        PPI: 2.420,
        PPK: 3.700,
      },
      FRA: {
        PPI: 2.440,
        PPK: 1.000,
      },
      DEU: {
        PPI: 2.370,
        PPK: 1.340,
      },
      GRC: {
        PPI: 2.160,
        PPK: 4.450,
      },
      HUN: {
        PPI: 2.180,
        PPK: 2.860,
      },
      IRL: {
        PPI: 2.040,
        PPK: 3.810,
      },
      ITA: {
        PPI: 2.050,
        PPK: 2.800,
      },
      LVA: {
        PPI: 0.860,
        PPK: 12.320,
      },
      LTU: {
        PPI: 1.760,
        PPK: 3.060,
      },
      LUX: {
        PPI: 2.310,
        PPK: 3.250,
      },
      MLT: {
        PPI: 1.960,
        PPK: 5.110,
      },
      NLD: {
        PPI: 1.970,
        PPK: 2.740,
      },
      POL: {
        PPI: 2.070,
        PPK: 3.530,
      },
      PRT: {
        PPI: 2.070,
        PPK: 3.530,
      },
      ROU: {
        PPI: 1.760,
        PPK: 3.300,
      },
      SVK: {
        PPI: 1.310,
        PPK: 4.430,
      },
      SVN: {
        PPI: 2.260,
        PPK: 4.210,
      },
      ESP: {
        PPI: 1.980,
        PPK: 3.360,
      },
      SWE: {
        PPI: 1.940,
        PPK: 2.450,
      },
      GIB: {
        PPI: 1.360,
        PPK: 4.390,
      },
      ISL: {
        PPI: 2.170,
        PPK: 5.730,
      },
      NOR: {
        PPI: 3.090,
        PPK: 4.100,
      },
      RUS: {
        PPI: 2.210,
        PPK: 4.700,
      },
      CHE: {
        PPI: 3.850,
        PPK: 1.620,
      },
      SRB: {
        PPI: 1.150,
        PPK: 9.720,
      },
      TUR: {
        PPI: 1.980,
        PPK: 3.530,
      },
      USA: {
        PPI: 4.220,
        PPK: 7.580,
      },
      ISR: {
        PPI: 1.610,
        PPK: 4.230,
      },
      SAU: {
        PPI: 2.070,
        PPK: 4.800,
      },
      ARE: {
        PPI: 1.900,
        PPK: 4.370,
      },
      AUS: {
        PPI: 1.940,
        PPK: 9.100,
      },
      CHN: {
        PPI: 1.910,
        PPK: 5.360,
      },
      HKG: {
        PPI: 1.900,
        PPK: 3.070,
      },
      JPN: {
        PPI: 2.280,
        PPK: 8.400,
      },
      MYS: {
        PPI: 1.760,
        PPK: 5.220,
      },
      NZL: {
        PPI: 1.500,
        PPK: 12.440,
      },
      SGP: {
        PPI: 1.760,
        PPK: 6.390,
      },
      KOR: {
        PPI: 1.900,
        PPK: 6.220,
      },
      THA: {
        PPI: 1.190,
        PPK: 12.540,
      },
      TWN: {
        PPI: 1.720,
        PPK: 4.330,
      },
      VNM: {
        PPI: 1.190,
        PPK: 12.540,
      },
      ARG: {
        PPI: 0.990,
        PPK: 18.32,
      },
      BRA: {
        PPI: 2.590,
        PPK: 7.160,
      },
      MEX: {
        PPI: 0.860,
        PPK: 12.320,
      },
      IND: {
        PPI: 0.900,
        PPK: 9.410,
      },
      KEN: {
        PPI: 0.990,
        PPK: 11.970,
      },
      NGA: {
        PPI: 0.990,
        PPK: 12.680,
      },
      PAK: {
        PPI: 0.990,
        PPK: 12.670,
      },
      ZAF: {
        PPI: 2.010,
        PPK: 5.420,
      },
      ROEU: {
        PPI: 1.150,
        PPK: 9.720,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 1.240,
        PPK: 13.610,
      },
      ROZ2: {
        PPI: 1.240,
        PPK: 13.610,
      },
    },
    prices: {
      250: {
        EU: 5.95,
        1: 8.30,
        2: 9.90,
        3: 10.95,
      },
      500: {
        EU: 7.80,
        1: 12.10,
        2: 14.50,
        3: 16.60,
      },
      750: {
        EU: 9.05,
        1: 14.85,
        2: 17.60,
        3: 19.35,
      },
      1000: {
        EU: 10.20,
        1: 17.65,
        2: 20.85,
        3: 23.00,
      },
      1250: {
        EU: 11.05,
        1: 19.85,
        2: 23.75,
        3: 26.45,
      },
      1500: {
        EU: 12.10,
        1: 22.10,
        2: 26.85,
        3: 29.20,
      },
      2000: {
        EU: 13.00,
        1: 23.30,
        2: 28.55,
        3: 30.45,
      },
    },
  },
  {
    value: 'DG4',
    label: 'Royal Mail International Standard',
    sizes: ['LL'],
    compensation: 20,
    obaPricing: {
      AUT: {
        PPI: 1.280,
        PPK: 0.790,
      },
      BEL: {
        PPI: 0.630,
        PPK: 4.250,
      },
      BGR: {
        PPI: 0.470,
        PPK: 4.020,
      },
      HRV: {
        PPI: 0.560,
        PPK: 5.170,
      },
      CYP: {
        PPI: 0.480,
        PPK: 4.20,
      },
      CZE: {
        PPI: 0.560,
        PPK: 4.220,
      },
      DNK: {
        PPI: 1.520,
        PPK: 5.430,
      },
      EST: {
        PPI: 0.610,
        PPK: 5.060,
      },
      FIN: {
        PPI: 0.660,
        PPK: 5.800,
      },
      FRA: {
        PPI: 0.980,
        PPK: 3.630,
      },
      DEU: {
        PPI: 0.600,
        PPK: 4.270,
      },
      GRC: {
        PPI: 0.680,
        PPK: 5.830,
      },
      HUN: {
        PPI: 0.570,
        PPK: 4.370,
      },
      IRL: {
        PPI: 1.010,
        PPK: 3.530,
      },
      ITA: {
        PPI: 0.660,
        PPK: 4.650,
      },
      LVA: {
        PPI: 0.570,
        PPK: 5.900,
      },
      LTU: {
        PPI: 0.520,
        PPK: 3.680,
      },
      LUX: {
        PPI: 0.650,
        PPK: 4.620,
      },
      MLT: {
        PPI: 0.550,
        PPK: 5.980,
      },
      NLD: {
        PPI: 0.570,
        PPK: 3.750,
      },
      POL: {
        PPI: 0.570,
        PPK: 4.230,
      },
      PRT: {
        PPI: 0.650,
        PPK: 4.980,
      },
      ROU: {
        PPI: 0.570,
        PPK: 4.250,
      },
      SVK: {
        PPI: 0.610,
        PPK: 5.050,
      },
      SVN: {
        PPI: 0.600,
        PPK: 4.830,
      },
      ESP: {
        PPI: 0.610,
        PPK: 4.390,
      },
      SWE: {
        PPI: 0.830,
        PPK: 4.280,
      },
      GIB: {
        PPI: 0.670,
        PPK: 5.140,
      },
      ISL: {
        PPI: 0.660,
        PPK: 5.330,
      },
      NOR: {
        PPI: 1.360,
        PPK: 5.810,
      },
      RUS: {
        PPI: 0.490,
        PPK: 4.370,
      },
      CHE: {
        PPI: 0.870,
        PPK: 4.530,
      },
      TUR: {
        PPI: 0.520,
        PPK: 3.990,
      },
      USA: {
        PPI: 0.590,
        PPK: 5.170,
      },
      ISR: {
        PPI: 0.660,
        PPK: 5.320,
      },
      SAU: {
        PPI: 0.610,
        PPK: 4.560,
      },
      ARE: {
        PPI: 0.510,
        PPK: 4.510,
      },
      AUS: {
        PPI: 0.640,
        PPK: 6.940,
      },
      CHN: {
        PPI: 0.490,
        PPK: 5.030,
      },
      HKG: {
        PPI: 0.450,
        PPK: 4.000,
      },
      JPN: {
        PPI: 0.630,
        PPK: 4.920,
      },
      MYS: {
        PPI: 0.520,
        PPK: 3.910,
      },
      NZL: {
        PPI: 0.600,
        PPK: 8.050,
      },
      SGP: {
        PPI: 0.500,
        PPK: 3.960,
      },
      KOR: {
        PPI: 0.500,
        PPK: 3.750,
      },
      TWN: {
        PPI: 0.530,
        PPK: 4.390,
      },
      ARG: {
        PPI: 0.150,
        PPK: 11.170,
      },
      BRA: {
        PPI: 0.460,
        PPK: 5.490,
      },
      MEX: {
        PPI: 0.510,
        PPK: 4.450,
      },
      IND: {
        PPI: 0.150,
        PPK: 8.340,
      },
      KEN: {
        PPI: 0.150,
        PPK: 9.180,
      },
      NGA: {
        PPI: 0.150,
        PPK: 10.470,
      },
      PAK: {
        PPI: 0.150,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 0.510,
        PPK: 5.210,
      },
      ROEU: {
        PPI: 0.370,
        PPK: 6.880,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 0.328,
        PPK: 8.306,
      },
      ROZ2: {
        PPI: 0.328,
        PPK: 8.306,
      },
    },
    prices: {
      100: {
        EU: 3.25,
        1: 4.20,
        2: 4.20,
        3: 4.20,
      },
      250: {
        EU: 4.95,
        1: 6.40,
        2: 7.50,
        3: 6.55,
      },
      500: {
        EU: 5.95,
        1: 8.70,
        2: 10.55,
        3: 9.00,
      },
      750: {
        EU: 6.95,
        1: 11.35,
        2: 14.25,
        3: 11.80,
      },
    },
  },
  {
    value: 'MTE',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 7.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.900,
        PPK: 3.800,
      },
      BGR: {
        PPI: 7.800,
        PPK: 3.700,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.600,
        PPK: 4.000,
      },
      CZE: {
        PPI: 7.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.750,
        PPK: 4.650,
      },
      EST: {
        PPI: 6.850,
        PPK: 4.300,
      },
      FIN: {
        PPI: 7.450,
        PPK: 5.550,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 8.400,
        PPK: 0.000,
      },
      GRC: {
        PPI: 7.100,
        PPK: 4.700,
      },
      HUN: {
        PPI: 7.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 7.300,
        PPK: 4.550,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 8.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.800,
        PPK: 5.600,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 7.300,
        PPK: 3.600,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 7.450,
        PPK: 3.950,
      },
      SVK: {
        PPI: 7.300,
        PPK: 4.400,
      },
      SVN: {
        PPI: 7.650,
        PPK: 4.500,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.850,
        PPK: 6.550,
      },
      ISL: {
        PPI: 6.400,
        PPK: 8.950,
      },
      NOR: {
        PPI: 8.100,
        PPK: 5.700,
      },
      RUS: {
        PPI: 7.200,
        PPK: 5.100,
      },
      CHE: {
        PPI: 6.550,
        PPK: 5.350,
      },
      SRB: {
        PPI: 7.050,
        PPK: 8.350,
      },
      TUR: {
        PPI: 6.700,
        PPK: 5.250,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 7.150,
        PPK: 8.110,
      },
      LBN: {
        PPI: 7.050,
        PPK: 10.300,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 7.350,
        PPK: 4.450,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 7.400,
        PPK: 5.300,
      },
      THA: {
        PPI: 7.400,
        PPK: 10.100,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.850,
        PPK: 10.600,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 7.330,
        PPK: 10.040,
      },
      ROZ2: {
        PPI: 7.330,
        PPK: 10.040,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
        3: 15.45,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
        3: 20.85,
      },
      750: {
        EU: 13.15,
        1: 19.00,
        2: 21.60,
        3: 22.85,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
        3: 26.50,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
        3: 29.95,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
        3: 32.70,
      },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
        3: 33.20,
      },
    },
  },
  {
    value: 'MTG',
    label: 'Royal Mail International Tracked & Signed',
    sizes: ['LL'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 6.690,
        PPK: 4.140,
      },
      BEL: {
        PPI: 6.690,
        PPK: 4.140,
      },
      BGR: {
        PPI: 6.690,
        PPK: 4.140,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.690,
        PPK: 2.390,
      },
      CZE: {
        PPI: 6.690,
        PPK: 4.140,
      },
      DNK: {
        PPI: 6.690,
        PPK: 4.140,
      },
      EST: {
        PPI: 6.290,
        PPK: 4.140,
      },
      FIN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      GRC: {
        PPI: 6.690,
        PPK: 4.140,
      },
      HUN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 6.690,
        PPK: 4.140,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 6.690,
        PPK: 4.140,
      },
      MLT: {
        PPI: 6.690,
        PPK: 4.140,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 6.690,
        PPK: 4.140,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SVK: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SVN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ISL: {
        PPI: 6.690,
        PPK: 3.290,
      },
      NOR: {
        PPI: 6.290,
        PPK: 4.140,
      },
      RUS: {
        PPI: 6.690,
        PPK: 3.290,
      },
      CHE: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SRB: {
        PPI: 6.690,
        PPK: 4.140,
      },
      TUR: {
        PPI: 6.690,
        PPK: 4.140,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 6.830,
        PPK: 8.265,
      },
      LBN: {
        PPI: 6.830,
        PPK: 9.855,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 6.830,
        PPK: 6.405,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 6.830,
        PPK: 7.555,
      },
      THA: {
        PPI: 6.830,
        PPK: 9.855,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 6.590,
        PPK: 9.245,
      },
      ROZ2: {
        PPI: 6.590,
        PPK: 9.245,
      },
    },
    prices: {
      100: {
        EU: 8.75,
        1: 9.70,
        2: 9.80,
        3: 9.75,
      },
      250: {
        EU: 9.20,
        1: 10.50,
        2: 11.55,
        3: 10.65,
      },
      500: {
        EU: 9.95,
        1: 12.30,
        2: 14.10,
        3: 12.60,
      },
      750: {
        EU: 10.35,
        1: 14.15,
        2: 16.90,
        3: 14.60,
      },
    },
  },
  {
    value: 'MP7',
    label: 'Royal Mail International Tracked',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 6.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.200,
        PPK: 3.800,
      },
      HRV: {
        PPI: 5.700,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.300,
        PPK: 4.000,
      },
      CZE: {
        PPI: 6.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.100,
        PPK: 4.200,
      },
      EST: {
        PPI: 6.250,
        PPK: 3.950,
      },
      FIN: {
        PPI: 6.850,
        PPK: 5.250,
      },
      FRA: {
        PPI: 5.300,
        PPK: 3.100,
      },
      DEU: {
        PPI: 5.450,
        PPK: 3.800,
      },
      GRC: {
        PPI: 6.700,
        PPK: 4.700,
      },
      HUN: {
        PPI: 6.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.100,
        PPK: 3.600,
      },
      ITA: {
        PPI: 5.900,
        PPK: 4.400,
      },
      LVA: {
        PPI: 6.950,
        PPK: 4.050,
      },
      LTU: {
        PPI: 6.200,
        PPK: 3.500,
      },
      LUX: {
        PPI: 7.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.550,
        PPK: 5.250,
      },
      NLD: {
        PPI: 5.400,
        PPK: 3.300,
      },
      POL: {
        PPI: 6.300,
        PPK: 3.400,
      },
      PRT: {
        PPI: 5.400,
        PPK: 4.400,
      },
      ROU: {
        PPI: 6.850,
        PPK: 3.950,
      },
      SVK: {
        PPI: 6.300,
        PPK: 3.800,
      },
      SVN: {
        PPI: 6.650,
        PPK: 3.900,
      },
      ESP: {
        PPI: 4.950,
        PPK: 3.450,
      },
      SWE: {
        PPI: 5.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.000,
        PPK: 6.400,
      },
      ISL: {
        PPI: 6.000,
        PPK: 8.750,
      },
      NOR: {
        PPI: 7.000,
        PPK: 5.300,
      },
      RUS: {
        PPI: 6.400,
        PPK: 4.550,
      },
      CHE: {
        PPI: 6.200,
        PPK: 5.000,
      },
      SRB: {
        PPI: 6.450,
        PPK: 8.150,
      },
      TUR: {
        PPI: 6.300,
        PPK: 5.000,
      },
      USA: {
        PPI: 5.750,
        PPK: 5.500,
      },
      ISR: {
        PPI: 6.450,
        PPK: 6.100,
      },
      LBN: {
        PPI: 6.450,
        PPK: 10.100,
      },
      AUS: {
        PPI: 6.250,
        PPK: 6.750,
      },
      HKG: {
        PPI: 6.450,
        PPK: 3.850,
      },
      JPN: {
        PPI: 6.450,
        PPK: 5.500,
      },
      MYS: {
        PPI: 5.700,
        PPK: 6.150,
      },
      NZL: {
        PPI: 5.350,
        PPK: 8.250,
      },
      SGP: {
        PPI: 6.650,
        PPK: 3.900,
      },
      KOR: {
        PPI: 6.000,
        PPK: 4.800,
      },
      TWN: {
        PPI: 6.800,
        PPK: 5.100,
      },
      THA: {
        PPI: 5.400,
        PPK: 9.100,
      },
      BRA: {
        PPI: 6.000,
        PPK: 7.400,
      },
      IND: {
        PPI: 5.650,
        PPK: 6.750,
      },
      // ROEU: {
      //   PPI: 0.000,
      //   PPK: 0.000,
      // },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 6.700,
        PPK: 4.350,
      },
      ROZ2: {
        PPI: 6.700,
        PPK: 4.350,
      },
    },
    prices: {
      250: {
        EU: 10.25,
        1: 12.70,
        2: 14.15,
        3: 13.45,
      },
      500: {
        EU: 11.70,
        1: 16.35,
        2: 18.55,
        3: 18.10,
      },
      750: {
        EU: 12.75,
        1: 18.75,
        2: 21.35,
        3: 20.85,
      },
      1000: {
        EU: 13.70,
        1: 21.40,
        2: 24.50,
        3: 24.50,
      },
      1250: {
        EU: 14.10,
        1: 23.25,
        2: 26.90,
        3: 27.95,
      },
      1500: {
        EU: 14.85,
        1: 24.65,
        2: 29.40,
        3: 30.70,
      },
      2000: {
        EU: 15.25,
        1: 25.65,
        2: 31.00,
        3: 31.95,
      },
    },
  },
  {
    value: 'MTK',
    label: 'Royal Mail International Tracked',
    sizes: ['LL'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 5.870,
        PPK: 4.140,
      },
      BEL: {
        PPI: 5.870,
        PPK: 4.140,
      },
      HRV: {
        PPI: 5.870,
        PPK: 4.140,
      },
      CYP: {
        PPI: 5.870,
        PPK: 2.390,
      },
      CZE: {
        PPI: 5.870,
        PPK: 4.140,
      },
      DNK: {
        PPI: 5.870,
        PPK: 4.140,
      },
      EST: {
        PPI: 5.870,
        PPK: 4.140,
      },
      FIN: {
        PPI: 5.870,
        PPK: 4.140,
      },
      FRA: {
        PPI: 5.300,
        PPK: 3.100,
      },
      DEU: {
        PPI: 5.450,
        PPK: 3.800,
      },
      GRC: {
        PPI: 5.870,
        PPK: 4.140,
      },
      HUN: {
        PPI: 5.870,
        PPK: 4.140,
      },
      IRL: {
        PPI: 5.870,
        PPK: 4.140,
      },
      ITA: {
        PPI: 5.870,
        PPK: 4.140,
      },
      LVA: {
        PPI: 5.870,
        PPK: 4.140,
      },
      LTU: {
        PPI: 5.870,
        PPK: 3.590,
      },
      LUX: {
        PPI: 5.870,
        PPK: 4.140,
      },
      MLT: {
        PPI: 5.870,
        PPK: 4.140,
      },
      NLD: {
        PPI: 5.400,
        PPK: 3.300,
      },
      POL: {
        PPI: 5.870,
        PPK: 4.140,
      },
      PRT: {
        PPI: 5.400,
        PPK: 4.400,
      },
      ROU: {
        PPI: 5.870,
        PPK: 4.140,
      },
      SVK: {
        PPI: 5.870,
        PPK: 4.140,
      },
      SVN: {
        PPI: 5.870,
        PPK: 4.140,
      },
      ESP: {
        PPI: 4.950,
        PPK: 3.450,
      },
      SWE: {
        PPI: 5.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 5.870,
        PPK: 4.140,
      },
      ISL: {
        PPI: 5.870,
        PPK: 3.290,
      },
      NOR: {
        PPI: 5.870,
        PPK: 4.140,
      },
      RUS: {
        PPI: 5.870,
        PPK: 3.290,
      },
      CHE: {
        PPI: 5.870,
        PPK: 4.140,
      },
      SRB: {
        PPI: 5.870,
        PPK: 4.140,
      },
      TUR: {
        PPI: 5.870,
        PPK: 4.140,
      },
      USA: {
        PPI: 5.750,
        PPK: 5.500,
      },
      ISR: {
        PPI: 6.450,
        PPK: 6.100,
      },
      LBN: {
        PPI: 6.310,
        PPK: 9.855,
      },
      AUS: {
        PPI: 6.250,
        PPK: 6.750,
      },
      HKG: {
        PPI: 6.450,
        PPK: 3.850,
      },
      JPN: {
        PPI: 6.450,
        PPK: 5.500,
      },
      MYS: {
        PPI: 5.700,
        PPK: 6.150,
      },
      NZL: {
        PPI: 5.350,
        PPK: 8.250,
      },
      SGP: {
        PPI: 6.650,
        PPK: 3.900,
      },
      KOR: {
        PPI: 6.000,
        PPK: 4.800,
      },
      TWN: {
        PPI: 6.310,
        PPK: 7.555,
      },
      THA: {
        PPI: 5.400,
        PPK: 9.100,
      },
      BRA: {
        PPI: 6.000,
        PPK: 7.400,
      },
      IND: {
        PPI: 5.650,
        PPK: 6.750,
      },
      // ROEU: {
      //   PPI: 0.000,
      //   PPK: 0.000,
      // },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 6.310,
        PPK: 8.330,
      },
      ROZ2: {
        PPI: 6.310,
        PPK: 8.330,
      },
    },
    prices: {
      100: {
        EU: 8.65,
        1: 9.60,
        2: 9.65,
        3: 9.65,
      },
      250: {
        EU: 8.85,
        1: 10.35,
        2: 11.45,
        3: 10.55,
      },
      500: {
        EU: 9.85,
        1: 12.20,
        2: 14.00,
        3: 12.50,
      },
      750: {
        EU: 10.25,
        1: 14.05,
        2: 16.80,
        3: 14.50,
      },
    },
  },
  {
    value: 'MP9',
    label: 'Royal Mail International Signed',
    sizes: ['SP'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 7.500,
        PPK: 3.500,
      },
      BEL: {
        PPI: 6.900,
        PPK: 3.800,
      },
      BGR: {
        PPI: 7.800,
        PPK: 3.700,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.600,
        PPK: 4.000,
      },
      CZE: {
        PPI: 7.900,
        PPK: 3.500,
      },
      DNK: {
        PPI: 6.750,
        PPK: 4.650,
      },
      EST: {
        PPI: 6.850,
        PPK: 4.300,
      },
      FIN: {
        PPI: 7.450,
        PPK: 5.550,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 8.400,
        PPK: 0.000,
      },
      GRC: {
        PPI: 7.100,
        PPK: 4.700,
      },
      HUN: {
        PPI: 7.800,
        PPK: 3.500,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 7.300,
        PPK: 4.550,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 8.050,
        PPK: 4.850,
      },
      MLT: {
        PPI: 6.800,
        PPK: 5.600,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 7.300,
        PPK: 3.600,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 7.450,
        PPK: 3.950,
      },
      SVK: {
        PPI: 7.300,
        PPK: 4.400,
      },
      SVN: {
        PPI: 7.650,
        PPK: 4.500,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.850,
        PPK: 6.550,
      },
      ISL: {
        PPI: 6.400,
        PPK: 8.950,
      },
      NOR: {
        PPI: 8.100,
        PPK: 5.700,
      },
      RUS: {
        PPI: 7.200,
        PPK: 5.100,
      },
      CHE: {
        PPI: 6.550,
        PPK: 5.350,
      },
      SRB: {
        PPI: 7.050,
        PPK: 8.350,
      },
      TUR: {
        PPI: 6.700,
        PPK: 5.250,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 7.150,
        PPK: 8.110,
      },
      LBN: {
        PPI: 7.050,
        PPK: 10.300,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 7.350,
        PPK: 4.450,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 7.400,
        PPK: 5.300,
      },
      THA: {
        PPI: 7.400,
        PPK: 10.100,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.850,
        PPK: 10.600,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 7.330,
        PPK: 10.040,
      },
      ROZ2: {
        PPI: 7.330,
        PPK: 10.040,
      },
    },
    prices: {
      250: {
        EU: 10.65,
        1: 12.95,
        2: 14.40,
      },
      500: {
        EU: 12.10,
        1: 16.60,
        2: 18.80,
      },
      750: {
        EU: 13.15,
        1: 19.00,
        2: 21.60,
      },
      1000: {
        EU: 14.10,
        1: 21.65,
        2: 24.75,
      },
      1250: {
        EU: 14.50,
        1: 23.50,
        2: 27.15,
      },
      1500: {
        EU: 15.05,
        1: 24.90,
        2: 29.65,
      },
      2000: {
        EU: 15.25,
        1: 25.35,
        2: 30.70,
      },
    },
  },
  {
    value: 'MTO',
    label: 'Royal Mail International Signed',
    sizes: ['LL'],
    compensation: 50,
    obaPricing: {
      AUT: {
        PPI: 6.690,
        PPK: 4.140,
      },
      BEL: {
        PPI: 6.690,
        PPK: 4.140,
      },
      BGR: {
        PPI: 6.690,
        PPK: 4.140,
      },
      HRV: {
        PPI: 5.800,
        PPK: 5.900,
      },
      CYP: {
        PPI: 6.690,
        PPK: 2.390,
      },
      CZE: {
        PPI: 6.690,
        PPK: 4.140,
      },
      DNK: {
        PPI: 6.690,
        PPK: 4.140,
      },
      EST: {
        PPI: 6.290,
        PPK: 4.140,
      },
      FIN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      FRA: {
        PPI: 6.050,
        PPK: 3.450,
      },
      DEU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      GRC: {
        PPI: 6.690,
        PPK: 4.140,
      },
      HUN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      IRL: {
        PPI: 6.700,
        PPK: 3.600,
      },
      ITA: {
        PPI: 6.400,
        PPK: 4.650,
      },
      LVA: {
        PPI: 6.690,
        PPK: 4.140,
      },
      LTU: {
        PPI: 6.300,
        PPK: 3.650,
      },
      LUX: {
        PPI: 6.690,
        PPK: 4.140,
      },
      MLT: {
        PPI: 6.690,
        PPK: 4.140,
      },
      NLD: {
        PPI: 5.700,
        PPK: 3.800,
      },
      POL: {
        PPI: 6.690,
        PPK: 4.140,
      },
      PRT: {
        PPI: 6.600,
        PPK: 4.600,
      },
      ROU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SVK: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SVN: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ESP: {
        PPI: 5.350,
        PPK: 3.900,
      },
      SWE: {
        PPI: 6.200,
        PPK: 3.550,
      },
      GIB: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ISL: {
        PPI: 6.690,
        PPK: 3.290,
      },
      NOR: {
        PPI: 6.290,
        PPK: 4.140,
      },
      RUS: {
        PPI: 6.690,
        PPK: 3.290,
      },
      CHE: {
        PPI: 6.690,
        PPK: 4.140,
      },
      SRB: {
        PPI: 6.690,
        PPK: 4.140,
      },
      TUR: {
        PPI: 6.690,
        PPK: 4.140,
      },
      USA: {
        PPI: 8.900,
        PPK: 5.600,
      },
      ISR: {
        PPI: 6.550,
        PPK: 6.100,
      },
      JOR: {
        PPI: 6.830,
        PPK: 8.265,
      },
      LBN: {
        PPI: 6.830,
        PPK: 9.855,
      },
      SAU: {
        PPI: 5.150,
        PPK: 8.050,
      },
      ARE: {
        PPI: 5.550,
        PPK: 7.650,
      },
      AUS: {
        PPI: 6.350,
        PPK: 6.750,
      },
      CHN: {
        PPI: 6.600,
        PPK: 5.100,
      },
      HKG: {
        PPI: 6.850,
        PPK: 4.100,
      },
      JPN: {
        PPI: 6.850,
        PPK: 5.500,
      },
      MYS: {
        PPI: 6.350,
        PPK: 6.650,
      },
      NZL: {
        PPI: 5.950,
        PPK: 8.400,
      },
      SGP: {
        PPI: 6.830,
        PPK: 6.405,
      },
      KOR: {
        PPI: 6.650,
        PPK: 5.400,
      },
      TWN: {
        PPI: 6.830,
        PPK: 7.555,
      },
      THA: {
        PPI: 6.830,
        PPK: 9.855,
      },
      ARG: {
        PPI: 5.500,
        PPK: 11.050,
      },
      BRA: {
        PPI: 6.200,
        PPK: 7.400,
      },
      MEX: {
        PPI: 5.350,
        PPK: 9.350,
      },
      IND: {
        PPI: 5.850,
        PPK: 6.750,
      },
      KEN: {
        PPI: 5.400,
        PPK: 9.150,
      },
      NGA: {
        PPI: 5.400,
        PPK: 10.050,
      },
      PAK: {
        PPI: 5.400,
        PPK: 8.900,
      },
      ZAF: {
        PPI: 5.200,
        PPK: 4.650,
      },
      ROEU: {
        PPI: 6.690,
        PPK: 4.140,
      },
      ROZ1: { // AVERAGE OF (REST OF....)
        PPI: 6.590,
        PPK: 9.245,
      },
      ROZ2: {
        PPI: 6.590,
        PPK: 9.245,
      },
    },
    prices: {
      100: {
        EU: 8.75,
        1: 9.70,
        2: 9.80,
      },
      250: {
        EU: 9.20,
        1: 10.50,
        2: 11.55,
      },
      500: {
        EU: 9.95,
        1: 12.30,
        2: 14.10,
      },
      750: {
        EU: 10.35,
        1: 14.15,
        2: 16.90,
      },
    },
  },
  {
    value: 'GPT',
    label: 'DHL International Tracked',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.50,
        PPK: 3.75,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 2.65,
        PPK: 7.30,
      },
      3: {
        PPI: 3.95,
        PPK: 3.30,
      },
      4: {
        PPI: 4.90,
        PPK: 3.00,
      },
      5: {
        PPI: 5.50,
        PPK: 8.00,
      },
      6: {
        PPI: 4.80,
        PPK: 11.50,
      },
      7: {
        PPI: 3.25,
        PPK: 12.00,
      },
      8: {
        PPI: 4.10,
        PPK: 11.25,
      },
      9: {
        PPI: 1.70,
        PPK: 16.25,
      },
    },
    prices: {
      100: {
        0: 10.20,
        1: 10.20,
        2: 10.20,
        3: 10.20,
        4: 10.20,
        6: 13.55, // USA
        7: 11.70,
        8: 12.75,
        9: 12.75,
      },
      250: {
        0: 10.35,
        1: 10.35,
        2: 10.35,
        3: 10.35,
        4: 10.35,
        6: 15.15, // USA
        7: 12.65,
        8: 14.10,
        9: 14.10,
      },
      500: {
        0: 11.80,
        1: 11.80,
        2: 11.80,
        3: 11.80,
        4: 11.80,
        6: 20.55, // USA
        7: 16.30,
        8: 18.50,
        9: 18.50,
      },
      750: {
        0: 12.85,
        1: 12.85,
        2: 12.85,
        3: 12.85,
        4: 12.85,
        6: 22.55, // USA
        7: 18.70,
        8: 21.30,
        9: 21.30,
      },
      1000: {
        0: 13.80,
        1: 13.80,
        2: 13.80,
        3: 13.80,
        4: 13.80,
        6: 26.20, // USA
        7: 21.35,
        8: 24.45,
        9: 24.45,
      },
      1250: {
        0: 14.20,
        1: 14.20,
        2: 14.20,
        3: 14.20,
        4: 14.20,
        6: 29.65, // USA
        7: 23.20,
        8: 26.85,
        9: 26.85,
      },
      1500: {
        0: 14.75,
        1: 14.75,
        2: 14.75,
        3: 14.75,
        4: 14.75,
        6: 32.40, // USA
        7: 24.60,
        8: 29.35,
        9: 29.35,
      },
      2000: {
        0: 14.95,
        1: 14.95,
        2: 14.95,
        3: 14.95,
        4: 14.95,
        6: 32.90, // USA
        7: 25.05,
        8: 30.40,
        9: 30.40,
      },
    },
  }, {
    value: 'GPT',
    label: 'DHL International Tracked',
    sizes: ['LL'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 2.50,
        PPK: 3.75,
      },
      1: {
        PPI: 3.35,
        PPK: 4.00,
      },
      2: {
        PPI: 2.65,
        PPK: 7.30,
      },
      3: {
        PPI: 3.95,
        PPK: 3.30,
      },
      4: {
        PPI: 4.90,
        PPK: 3.00,
      },
      5: {
        PPI: 5.50,
        PPK: 8.00,
      },
      6: {
        PPI: 4.80,
        PPK: 11.50,
      },
      7: {
        PPI: 3.25,
        PPK: 12.00,
      },
      8: {
        PPI: 4.10,
        PPK: 11.25,
      },
      9: {
        PPI: 1.70,
        PPK: 16.25,
      },
    },
    prices: {
      100: {
        0: 8.75,
        1: 8.75,
        2: 8.75,
        3: 8.75,
        4: 8.75,
        6: 9.75, // USA
        7: 9.70,
        8: 9.80,
        9: 9.80,
      },
      250: {
        0: 9.20,
        1: 9.20,
        2: 9.20,
        3: 9.20,
        4: 9.20,
        6: 10.65, // USA
        7: 10.50,
        8: 10.50,
        9: 11.55,
      },
      500: {
        0: 9.95,
        1: 9.95,
        2: 9.95,
        3: 9.95,
        4: 9.95,
        6: 12.60, // USA
        7: 12.30,
        8: 12.30,
        9: 14.10,
      },
    },
  }, {
    value: 'GMP',
    label: 'DHL International Standard',
    sizes: ['SP'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 1.63,
        PPK: 3.75,
      },
      1: {
        PPI: 2.00,
        PPK: 4.00,
      },
      2: {
        PPI: 1.20,
        PPK: 7.30,
      },
      3: {
        PPI: 2.55,
        PPK: 3.30,
      },
      4: {
        PPI: 3.65,
        PPK: 3.00,
      },
      5: {
        PPI: 2.50,
        PPK: 8.00,
      },
      6: {
        PPI: 3.85,
        PPK: 11.50,
      },
      7: {
        PPI: 0.90,
        PPK: 12.00,
      },
      8: {
        PPI: 1.65,
        PPK: 11.25,
      },
      9: {
        PPI: 0.15,
        PPK: 16.25,
      },
    },
    prices: {
      100: {
        0: 5.50,
        1: 5.50,
        2: 5.50,
        3: 5.50,
        4: 5.50,
        6: 9.05, // USA
        7: 6.85,
        8: 8.05,
        9: 8.05,
      },
      250: {
        0: 5.65,
        1: 5.65,
        2: 5.65,
        3: 5.65,
        4: 5.65,
        6: 10.65, // USA
        7: 8.00,
        8: 9.60,
        9: 9.60,
      },
      500: {
        0: 7.50,
        1: 7.50,
        2: 7.50,
        3: 7.50,
        4: 7.50,
        6: 16.30, // USA
        7: 11.80,
        8: 14.20,
        9: 14.20,
      },
      750: {
        0: 8.75,
        1: 8.75,
        2: 8.75,
        3: 8.75,
        4: 8.75,
        6: 19.05, // USA
        7: 14.55,
        8: 17.30,
        9: 17.30,
      },
      1000: {
        0: 9.90,
        1: 9.90,
        2: 9.90,
        3: 9.90,
        4: 9.90,
        6: 22.70, // USA
        7: 17.35,
        8: 20.55,
        9: 20.55,
      },
      1250: {
        0: 10.75,
        1: 10.75,
        2: 10.75,
        3: 10.75,
        4: 10.75,
        6: 26.15, // USA
        7: 19.55,
        8: 23.45,
        9: 23.45,
      },
      1500: {
        0: 11.80,
        1: 11.80,
        2: 11.80,
        3: 11.80,
        4: 11.80,
        6: 28.90, // USA
        7: 21.80,
        8: 26.55,
        9: 26.55,
      },
      2000: {
        0: 12.70,
        1: 12.70,
        2: 12.70,
        3: 12.70,
        4: 12.70,
        6: 30.15, // USA
        7: 23.00,
        8: 28.25,
        9: 28.25,
      },
    },
  },
  {
    value: 'GMP',
    label: 'DHL International Standard',
    sizes: ['LL'],
    compensation: 30,
    obaPricing: {
      0: {
        PPI: 0.58,
        PPK: 5.00,
      },
      1: {
        PPI: 1.20,
        PPK: 5.50,
      },
      2: {
        PPI: 0.55,
        PPK: 7.25,
      },
      3: {
        PPI: 1.35,
        PPK: 4.25,
      },
      4: {
        PPI: 1.55,
        PPK: 4.20,
      },
      5: {
        PPI: 1.60,
        PPK: 8.00,
      },
      6: {
        PPI: 3.85,
        PPK: 11.50,
      },
      7: {
        PPI: 0.90,
        PPK: 12.00,
      },
      8: {
        PPI: 1.65,
        PPK: 11.25,
      },
      9: {
        PPI: 0.15,
        PPK: 16.25,
      },
    },
    prices: {
      100: {
        0: 3.25,
        1: 3.25,
        2: 3.25,
        3: 3.25,
        4: 3.25,
        6: 4.20, // USA
        7: 4.20,
        8: 4.20,
        9: 4.20,
      },
      250: {
        0: 4.25,
        1: 4.25,
        2: 4.25,
        3: 4.25,
        4: 4.25,
        6: 5.85, // USA
        7: 6.80,
        8: 6.80,
        9: 6.80,
      },
      500: {
        0: 5.25,
        1: 5.25,
        2: 5.25,
        3: 5.25,
        4: 5.25,
        6: 8.30, // USA
        7: 9.85,
        8: 9.85,
        9: 9.85,
      },
    },
  },
];

export const packageSizes = [
  {
    value: 'LL',
    label: 'Large Letter',
    max_weight: 750,
  },
  {
    value: 'SP',
    label: 'Small Parcel',
    max_weight: 2000,
  },
];
