import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import OrdersReportTable from './components/OrdersReportTable';


const OrdersReporting = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Tools / Reporting / Orders</h5>
      </Col>
    </Row>
    <Row>
      <OrdersReportTable />
    </Row>
  </Container>
);

export default OrdersReporting;
