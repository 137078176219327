/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */


import React, { Component } from 'react';
import axios from 'axios';
import { Col, Row, Card, CardBody, CardHeader, Button, UncontrolledCollapse, Media } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { connect } from 'react-redux';
import { countryList } from '../../../../helpers/shipping/Countries';
import iso3codes from '../../../../helpers/shipping/iso2toiso3';

const URL = 'https://api.musicmarquee.co.uk'; // http://-localhost:8000

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
// const currencyFormatEur = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' });


const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

class AmazonOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      selected: undefined,
      data: [],
    };
  }

  getCountryLabel(countryCode) {
    const iso3code = iso3codes[countryCode];
    const countryObj = countryList.find(obj => obj.value === iso3code);
    if (countryObj !== undefined) {
      return countryObj.label;
    }
    return null;
  }

  getDiscogsImageURL(releaseId) {
    const split = releaseId.toString().split('');
    const join = split.join('/');
    const url = `https://s3-eu-west-1.amazonaws.com/releaseimages/${join}/img01.jpg`;
    return url;
  }

  getConditionAbr(condition) {
    switch (condition) {
      case 'Mint (M)':
        return 'M';
      case 'Near Mint (NM)':
        return 'NM';
      case 'Very Good Plus (VG+)':
        return 'VG+';
      case 'Very Good (VG)':
        return 'VG';
      case 'Good Plus (G+)':
        return 'G+';
      case 'Good (G)':
        return 'G';
      case 'Fair (F)':
        return 'G';
      case 'Poor (P)':
        return 'Poor';
      default:
        return condition;
    }
  }

  getStatusColour(items) {
    const colours = [];
    const greenStatuses = ['Self Fulfilled', 'Payment Pending', 'Payment Received', 'Invoice Sent', 'New Order', 'Merged', 'Shipped'];
    const redStatuses = ['Not Ordered', 'Cancelled (Item Unavailable)', 'Cancelled (Per Buyer\'s Request)', 'Cancelled (Non-Paying Buyer)', 'Cancelled (Refund Received)', 'Cancelled'];

    let colour = 'red';

    if (Array.isArray(items)) {
      items.map((item) => {
        if (item.order_item.discogs_purchase_status === 'Messaged') {
          colours.push('orange');
        } else if (item.order_item.discogs_purchase_status === 'Cancelled') {
          colours.push('grey');
        } else if (greenStatuses.includes(item.order_item.discogs_purchase_status)) {
          colours.push('green');
        } else if (redStatuses.includes(item.order_item.discogs_purchase_status)) {
          colours.push('red');
        }
        return null;
      });

      if (colours.includes('red')) {
        colour = 'red';
      } else if (colours.includes('orange')) {
        colour = 'orange';
      } else if (colours.includes('grey')) {
        colour = 'grey';
      } else {
        colour = 'green';
      }
    } else if (items.order_item.discogs_purchase_status === 'Messaged') {
      colour = 'orange';
    } else if (items.order_item.discogs_purchase_status === 'Cancelled') {
      colour = 'grey';
    } else if (greenStatuses.includes(items.order_item.discogs_purchase_status)) {
      colour = 'green';
    } else if (redStatuses.includes(items.order_item.discogs_purchase_status)) {
      colour = 'red';
    }
    return colour;
  }

  purchaseIdDidChange(event, item) {
    if (event.target.value === 's' || event.target.value === 'S') {
      item.discogs_purchase_id = 'Self';
      event.target.value = 'Self';
    } else if (event.target.value === 'Me' || event.target.value === 'me' || event.target.value === 'ME') {
      item.discogs_purchase_id = 'Messaged';
      event.target.value = 'Messaged';
    } else if (event.target.value === 'C' || event.target.value === 'c') {
      item.discogs_purchase_id = 'Cancel';
      event.target.value = 'Cancel';
    } else {
      item.discogs_purchase_id = event.target.value;
    }
    this.forceUpdate();
  }

  copyShippingAddressToClipboard() {
    let returnAddress =
    `${this.state.selected.buyer_name}\r\n`;
    returnAddress += `${this.state.selected.shipping_address1}\r\n`;
    returnAddress += this.state.selected.shipping_address2 ? `${this.state.selected.shipping_address2}\r\n` : '';
    returnAddress += this.state.selected.shipping_address3 ? `${this.state.selected.shipping_address3}\r\n` : '';
    returnAddress += `${this.state.selected.shipping_address_city}\r\n`;
    returnAddress += this.state.selected.shipping_address_region ? `${this.state.selected.shipping_address_region}\r\n`
      : '';
    returnAddress += `${this.state.selected.shipping_address_postcode}\r\n`;
    const iso3code = iso3codes[this.state.selected.shipping_address_countrycode];
    const countryObj = countryList.find(obj => obj.value === iso3code);
    if (countryObj !== undefined) {
      returnAddress += countryObj.label;
    }
    const inp = document.createElement('textarea');
    document.body.appendChild(inp);
    inp.value = returnAddress;
    inp.select();
    document.execCommand('copy');
    inp.remove();
    // return returnAddress;
  }

  updatePurchaseOrder(orderItem) {
    axios.post(`${URL}/admin/orders/amazon/update`, {
      item: orderItem,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        const currentData = [...this.state.data];
        const itemToUpdate = currentData.find(order => order.amazon_order_id === res.data.amazon_order_id).items.find(item => item.order_item.order_item_id === res.data.order_item_id);
        itemToUpdate.order_item = res.data;
        this.setState({
          data: currentData,
        });
      });
  }

  blacklistRelease(item) {
    item.blacklist_amazon = true;
    axios.post(`${URL}/admin/inventory/blacklist`, {
      item,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
        console.log(res);
        alert(`Successfully Blacklisted: ${res.data.item.description}`);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          alert('ERROR');
        }
      });
  }

  render() {
    const ordersColumns = [
      {
        Header: 'MM Status',
        width: getColumnWidth(this.state.data, 'MM Status', 'MM Status'),
        getProps: (state, row) => {
          if (row) {
            return {
              style: {
                background: this.getStatusColour(row.original.items),
              },
            };
          }
          return {
            style: { className: '-striped -highlight' },
          };
        },
      },
      // {
      //   Header: 'Order Id',
      //   id: 'amazon_order_id',
      //   width: getColumnWidth(this.state.data, 'amazon_order_id', 'Order Id'),
      //   accessor: 'amazon_order_id',
      // },
      {
        Header: 'Customer',
        id: 'buyer_name',
        width: getColumnWidth(this.state.data, 'buyer_name', 'Customer'),
        Cell: row => (
          <div>
            <tr>{row.original.buyer_name}</tr>
            <tr><b>{row.original.status}</b></tr>
          </div>
        ),
      }, {
        Header: 'Summary',
        id: 'summary',
        sortable: false,
        // accessor: d => {
        //   if (d.items.length > 1) {
        //     return d.items.length+' Items';
        //   } else {
        //     return d.items.length+' Item';
        //   }
        // }
        Cell: row => (
          <div>
            <span>
              <b>{(row.original.items.length > 1) ? `${row.original.items.length} Items:`
              : `${row.original.items.length} Item:`}
              </b>
            </span>
            {row.original.items.map(i =>
              <tr>{i ? i.order_item.title : '' }</tr>)}
          </div>
        ),
      },
    ];

    const stockColumns = [
      {
        Header: 'Seller',
        id: 'seller',
        accessor: 'seller',
      },
      {
        Header: 'Buy Price',
        id: 'buy_price',
        accessor: 'discogs_price',
      },
      {
        Header: 'Media',
        id: 'media_cond',
        accessor: 'media_cond',
      },
      {
        Header: 'Sleeve',
        id: 'sleeve_cond',
        accessor: 'sleeve_cond',
      },
      {
        Header: 'Location',
        id: 'location',
        accessor: 'location',
      },
      // {
      //   Header: 'View',
      //   id: 'view',
      //   Cell: row => (
      //     <div>
      //       <a
      //         target="_blank"
      //         rel="noopener noreferrer"
      //         href={`https://www.discogs.com/sell/item/${row.original.listing_id}`}
      //       >View on Discogs
      //       </a>
      //     </div>
      //   ),
      // },
    ];

    return (
      <Col md={12}>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <ReactTable
                  style={{
                    height: '400px',
                  }}
                  data={this.state.data}
                  columns={ordersColumns}
                  pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
                  loading={this.state.loading}
                  onFetchData={(state) => {
        // show the loading overlay
                    this.setState({ loading: true });
                    axios.post(`${URL}/admin/orders/amazon`, {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                  }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                  .then((res) => {
                    // Update react-table
                    console.log(res.data.orders);
                    this.setState({
                      data: res.data.orders,
                      pages: res.data.pages,
                      loading: false,
                    });
                  });
      }}
                  manual
                  filterable
                  multiSort={false}
                  defaultPageSize={50}
                  className="-striped -highlight -responsive"
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                        onClick: () => {
                          rowInfo.original.items.map((item, index) => {
                            if (document.getElementById(`#purchaseId${index}`)) {
                              document.getElementById(`#purchaseId${index}`).value = '';
                            }
                            return null;
                          });
                          this.setState({
                            selected: rowInfo.original,
                          });
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black',
                        },
                      };
                    }
              return {};
          }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardHeader>
                <h5 className="bold-text">{this.state.selected ? this.state.selected.amazon_order_id : 'Select Order'}</h5>
              </CardHeader>
              {this.state.selected ? (
                <CardBody>
                  <div className="project-summary">
                    <div className="project-summary__stats">
                      <div className="project-summary__stat">
                        <table className="project-summary__info">
                          <tbody>
                            <tr>
                              <th>Order Number:</th>
                              <td><a href={`https://sellercentral.amazon.co.uk/orders-v3/order/${this.state.selected.amazon_order_id}`} target="_blank" rel="noopener noreferrer">{this.state.selected.amazon_order_id}</a></td>
                            </tr>
                            <tr>
                              <th>Order date:</th>
                              <td>{new Date(this.state.selected.order_date).toLocaleDateString()} {new Date(this.state.selected.order_date).toLocaleTimeString()}</td>
                            </tr>
                            <tr>
                              <th>Ship by:</th>
                              <td>{new Date(this.state.selected.latest_ship_date).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                              <th>Deliver by:</th>
                              <td>{new Date(this.state.selected.latest_del_date).toLocaleDateString()}</td>
                            </tr>
                            <tr>
                              <th>Order Status:</th>
                              <td>{this.state.selected.status}</td>
                            </tr>
                            <tr>
                              <th>Order Channel:</th>
                              <td><b>{this.state.selected.order_channel}</b></td>
                            </tr>
                            <tr>
                              <th>Shipping Service:</th>
                              <td><b>{this.state.selected.shipping_service_cat}</b></td>
                            </tr>
                            <tr>
                              <th>Order Total:</th>
                              <td><b>£{this.state.selected.order_total}</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="project-summary__stat">
                        <table className="project-summary__info">
                          <tbody>
                            {/* <tr>
                              <th>Shipping Address:</th>
                              <td>{this.getShippingAddress()}</td>
                            </tr> */}
                            <tr>
                              <th>Name:</th>
                              <td>{this.state.selected.buyer_name}</td>
                            </tr>
                            <tr>
                              <th>Address 1:</th>
                              <td>{this.state.selected.shipping_address1}</td>
                            </tr>
                            {this.state.selected.shipping_address2 ? (
                              <tr>
                                <th>Address 2:</th>
                                <td>{this.state.selected.shipping_address2}</td>
                              </tr>
                            ) : null }
                            {this.state.selected.shipping_address3 ? (
                              <tr>
                                <th>Address 3:</th>
                                <td>{this.state.selected.shipping_address3}</td>
                              </tr>
                            ) : null }
                            <tr>
                              <th>City:</th>
                              <td>{this.state.selected.shipping_address_city}</td>
                            </tr>
                            {this.state.selected.shipping_address_region ? (
                              <tr>
                                <th>Region:</th>
                                <td>{this.state.selected.shipping_address_region}</td>
                              </tr>
                            ) : null }
                            {this.state.selected.shipping_address_county ? (
                              <tr>
                                <th>County:</th>
                                <td>{this.state.selected.shipping_address_county}</td>
                              </tr>
                            ) : null }
                            {this.state.selected.shipping_address_district ? (
                              <tr>
                                <th>District:</th>
                                <td>{this.state.selected.shipping_address_district}</td>
                              </tr>
                            ) : null }
                            <tr>
                              <th>Postcode:</th>
                              <td>{this.state.selected.shipping_address_postcode}</td>
                            </tr>
                            <tr>
                              <th>Country:</th>
                              <td>{this.getCountryLabel(this.state.selected.shipping_address_countrycode)}</td>
                            </tr>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => this.copyShippingAddressToClipboard()}
                            >Copy Address
                            </Button>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                  </div>
                </CardBody>
            ) : null }
            </Card>
          </Col>
        </Row>
        {this.state.selected ? (
            this.state.selected.items.map((item, index) => (
              <Row>
                <Card>
                  <CardHeader
                    id={`toggler${index}`}
                    style={{
                     backgroundColor: this.getStatusColour(item),
                    }}
                  >
                    <h5 className="bold-text" style={{ color: 'white' }}>{item.order_item.title}</h5>
                  </CardHeader>
                  <UncontrolledCollapse toggler={`#toggler${index}`}>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Card>
                            <CardHeader>
                              <h5 className="bold-text">Amazon Details</h5>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Media top href={`https://www.${this.state.selected.order_channel}/gp/product/${item.order_item.asin}`} target="_blank">
                                  <Media
                                    object
                                    src={`https://ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${item.order_item.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL250`}
                                    alt="Generic placeholder image"
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      maxHeight: '150px',
                                      maxWidth: '150px',
                                    }}
                                  />
                                </Media>
                                <div
                                  className="project-summary"
                                  style={{
                                    margin: '10px',
                                }}
                                >
                                  <table className="project-summary__info">
                                    <tbody>
                                      <tr>
                                        <th>Amazon Title:</th>
                                        <td><b>{item.order_item.title}</b>
                                          <Button
                                            outline
                                            size="sm"
                                            onClick={() => {
                                            const inp = document.createElement('textarea');
                                            document.body.appendChild(inp);
                                            inp.value = `Order Id: ${item.order_item.amazon_order_id}\r\nAMA ${item.order_item.title}\r\n${item.inventory.seller_username} - ${item.inventory.location}`;
                                            inp.select();
                                            document.execCommand('copy');
                                            inp.remove();
                                          }}
                                          ><span className="lnr lnr-select" />
                                          </Button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>SKU:</th>
                                        <td><b>{item.order_item.sku}</b></td>
                                      </tr>
                                      <tr>
                                        <th>Sold Price:</th>
                                        <td>{currencyFormat.format(item.order_item.item_price)}</td>
                                      </tr>
                                      <tr>
                                        <th>Shipping Paid:</th>
                                        <td>{currencyFormat.format(item.order_item.shipping_price)}</td>
                                      </tr>
                                      <tr>
                                        <th>QTY:</th>
                                        <td><b>{item.order_item.qty_ordered}</b></td>
                                      </tr>
                                      <tr>
                                        <th>Condition Notes:</th>
                                        <td><b>{item.order_item.cond_note}</b></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <CardHeader>
                              <h5 className="bold-text">Discogs Details</h5>
                            </CardHeader>
                            {item.inventory ? (
                              <CardBody>
                                <Row>
                                  <Media top href={`https://www.discogs.com/sell/item/${item.inventory.listing_id}`} target="_blank">
                                    <Media
                                      object
                                      src={this.getDiscogsImageURL(item.inventory.release_id)}
                                      alt="Generic placeholder image"
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        maxHeight: '150px',
                                        maxWidth: '150px',
                                      }}
                                    />
                                  </Media>
                                  <div
                                    className="project-summary"
                                    style={{
                                      'margin-left': '10px',
                                    }}
                                  >
                                    <table className="project-summary__info">
                                      <tbody>
                                        <tr>
                                          <th>Discogs Title:</th>
                                          <td><b>{item.inventory.description}</b></td>
                                        </tr>
                                        <tr>
                                          <th>Listing ID:</th>
                                          <td><a href={`https://www.discogs.com/sell/item/${item.inventory.listing_id}`} target="_blank" rel="noopener noreferrer">{item.inventory.listing_id}</a></td>
                                        </tr>
                                        <tr>
                                          <th>Seller:</th>
                                          <td><b>{item.inventory.seller_username}</b></td>
                                        </tr>
                                        <tr>
                                          <th>Buy Price:</th>
                                          <td>{currencyFormat.format(item.inventory.discogs_price)}</td>
                                        </tr>
                                        <tr>
                                          <th>Condition:</th>
                                          <td>{this.getConditionAbr(item.inventory.media_cond)} / {this.getConditionAbr(item.inventory.sleeve_cond)}</td>
                                        </tr>
                                        <tr>
                                          <th>Location:</th>
                                          <td>{item.inventory.location}</td>
                                        </tr>
                                        <tr>
                                          <th>Comments:</th>
                                          <td>{item.inventory.comments}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Row>
                              </CardBody>
                            ) : null }
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Card>
                            <CardHeader
                              id={`togglerB${index}`}
                            >
                              <h5 className="bold-text">Amazon Actions</h5>
                            </CardHeader>
                            <UncontrolledCollapse toggler={`#togglerB${index}`}>
                              <CardBody>
                                <Row>
                                  <div
                                    className="project-summary"
                                    style={{
                                      margin: '10px',
                                  }}
                                  >
                                    <table className="project-summary__info">
                                      <tbody>
                                        <tr>
                                          <Button
                                            color="primary"
                                            size="sm"
                                            onClick={() => this.blacklistRelease(item)}
                                          >Blacklist Release
                                          </Button>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </Row>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card>
                            <CardHeader
                              id={`togglerC${index}`}
                            >
                              <h5 className="bold-text">Dropship Details</h5>
                            </CardHeader>
                            <UncontrolledCollapse toggler={`#togglerC${index}`}>
                              <CardBody>
                                <Row>
                                  <div className="project-summary">
                                    <div className="project-summary__stats">
                                      <div className="project-summary__stat">
                                        <table className="project-summary__info">
                                          <tbody>
                                            <tr>
                                              <th>Purchase Id:</th>
                                              <td>
                                                <input
                                                  type="text"
                                                  id={`#purchaseId${index}`}
                                                  name="purchase_id"
                                                  onChange={event => this.purchaseIdDidChange(event, item.order_item)}
                                                  value={item.order_item.discogs_purchase_id}
                                                  onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                      this.updatePurchaseOrder(item.order_item);
                                                    }
                                                  }}
                                                />
                                                <Button
                                                  color="primary"
                                                  size="sm"
                                                  onClick={() => this.updatePurchaseOrder(item.order_item)}
                                                >Update
                                                </Button>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th>Order Status:</th>
                                              <td><a href={`https://www.discogs.com/sell/order/${item.order_item.discogs_purchase_id}`} target="_blank" rel="noopener noreferrer">{item.order_item.discogs_purchase_status}</a></td>
                                            </tr>
                                            <tr>
                                              <th>Seller:</th>
                                              <td>{item.order_item.discogs_purchase_seller}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </CardBody>
                            </UncontrolledCollapse>
                          </Card>
                        </Col>
                      </Row>
                      {false ? (
                        <div>
                          <ReactTable
                            pages={this.state.pages}
                            showPagination={false}
                            loading={this.state.loading}
                            style={{
                            height: `${(item.inventory.stock.length) * 51}px`,
                          }}
                            defaultPageSize={item.inventory.stock.length}
                            data={item.inventory.stock}
                            columns={stockColumns}
                            className="-striped -highlight -responsive"
                            getTrProps={(state, rowInfo) => {
                        if (rowInfo !== undefined) {
                          return {
                            onClick: () => {
                              // console.log(rowInfo.original);
                              window.open(`https://www.discogs.com/sell/item/${rowInfo.original.listing_id}`, '_blank');
                            },
                          };
                        }
                          return {};
                      }}
                          />
                        </div>
                      ) : null }
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </Row>

             ))
           ) : null
       }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(AmazonOrdersTable);
