import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';


class SidebarContent extends Component {
  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    user: PropTypes.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        {/* <ul className="sidebar__block">
          <SidebarCategory title="Layout" icon="layers">
            <button className="sidebar__link" onClick={this.props.changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button className="sidebar__link" onClick={this.props.changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul> */}
        <ul className="sidebar__block">
          <SidebarCategory title="Fulfillment" icon="diamond">
            <SidebarCategory title="Discogs">
              <SidebarLink title="Open Orders" route="/fulfillment/orders" onClick={this.hideSidebar} />
              <SidebarLink title="Complete Orders" route="/fulfillment/allorders" onClick={this.hideSidebar} />
            </SidebarCategory>
            {/* <SidebarLink title="Page two" route="/pages/two" onClick={this.hideSidebar} /> */}
            <SidebarCategory title="Bandcamp">
              <SidebarLink title="Merch Items" route="/fulfillment/bandcamp/merch" onClick={this.hideSidebar} />
              <SidebarLink
                title="BandCamp Orders (UK)"
                route="/fulfillment/bandcamp/orders"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="BandCamp Orders (NON-UK)"
                route="/fulfillment/bandcampdhl/orders"
                onClick={this.hideSidebar}
              />
              {/* <SidebarLink title="Returned Orders"
              route="/aboveboard/orders/returns" onClick={this.hideSidebar} /> */}
            </SidebarCategory>
            <SidebarCategory title="Shopify">
              <SidebarLink title="Open Orders" route="/fulfillment/shopify/orders" onClick={this.hideSidebar} />
              <SidebarLink title="Actions" route="/fulfillment/shopify/actions" onClick={this.hideSidebar} />
              <SidebarLink title="Stock" route="/fulfillment/shopify/stock" onClick={this.hideSidebar} />
            </SidebarCategory>
            {/* <SidebarCategory title="WooCommerce">
              <SidebarLink title="Open Orders" route="/fulfillment/orders" onClick={this.hideSidebar} />
              <SidebarLink title="Complete Orders" route="/fulfillment/allorders" onClick={this.hideSidebar} />
            </SidebarCategory> */}
            {this.props.user.fulfillment_location_id === 1 ? (
              <SidebarCategory title="Tints Direct" icon="diamond">
                <SidebarLink title="Actions" route="/tintsdirect/actions/index" onClick={this.hideSidebar} />
                {/* <SidebarLink title="Stock" route="/aboveboard/merch" onClick={this.hideSidebar} /> */}
                <SidebarLink
                  title="Shopify Orders"
                  route="/tintsdirect/orders/index"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>
              ) : null }
          </SidebarCategory>
          <SidebarCategory title="Inventory" icon="diamond">
            {/* <SidebarLink title="Used Items" route="/fulfillment/orders" onClick={this.hideSidebar} /> */}
            <SidebarLink title="New Items" route="/inventory/new_items" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="MusicMarquee" icon="diamond">
            <SidebarCategory title="Orders">
              <SidebarLink title="Amazon Orders" route="/orders/amazon" onClick={this.hideSidebar} />
              <SidebarLink title="eBay Orders" route="/orders/ebay" onClick={this.hideSidebar} />
              {/* <SidebarLink title="Discogs Orders" route="/orders/discogs" onClick={this.hideSidebar} /> */}
            </SidebarCategory>
            <SidebarCategory title="Purchases">
              <SidebarLink title="Create Purchase" route="/orders/purchases/new" onClick={this.hideSidebar} />
              <SidebarLink title="Purchases" route="/orders/purchases" onClick={this.hideSidebar} />
            </SidebarCategory>
          </SidebarCategory>
          {/* {this.props.user.fulfillment_location_id === 1 || 2 ? (
            <SidebarCategory title="AboveBoard" icon="diamond">
              <SidebarLink title="Merch Items" route="/aboveboard/merch" onClick={this.hideSidebar} />
              <SidebarLink
                title="BandCamp Orders (UK)"
                route="/aboveboard/orders/bandcamp"
                onClick={this.hideSidebar}
              />
              <SidebarLink
                title="BandCamp Orders (NON-UK)"
                route="/aboveboard/orders/bandcampdhl"
                onClick={this.hideSidebar}
              />
               <SidebarLink title="Returned Orders"
              route="/aboveboard/orders/returns" onClick={this.hideSidebar} />
            </SidebarCategory>
            ) : null } */}
          <SidebarCategory title="Shipping" icon="diamond">
            <SidebarLink title="DHL Shipments" route="/shipping/dhl" onClick={this.hideSidebar} />
            <SidebarLink title="Manage Shipments" route="/shipping/manageshipments" onClick={this.hideSidebar} />
            <SidebarLink title="Create Shipments" route="/shipping/shipments" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Tools" icon="menu" onClick={this.hideSidebar}>
            <SidebarLink title="Manage Sellers" route="/tools/sellers/manage" onClick={this.hideSidebar} />
            <SidebarLink title="Update Orders" route="/tools/orders/update" onClick={this.hideSidebar} />
            <SidebarCategory title="Mark as Shipped" onClick={this.hideSidebar}>
              <SidebarLink title="eBay" route="/tools/shipping/ebay" onClick={this.hideSidebar} />
              <SidebarLink title="Amazon" route="/tools/shipping/amazon" onClick={this.hideSidebar} />
              <SidebarLink title="Fulfilment" route="/tools/shipping/fulfilment" onClick={this.hideSidebar} />
              <SidebarLink title="AboveBoard" route="/tools/shipping/aboveboard" onClick={this.hideSidebar} />
            </SidebarCategory>
            <SidebarCategory title="Reporting" onClick={this.hideSidebar}>
              <SidebarLink title="Orders" route="/tools/reporting/orders" onClick={this.hideSidebar} />
            </SidebarCategory>
          </SidebarCategory>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(SidebarContent);
