/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Link } from '@react-pdf/renderer';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { countryList } from '../../../../../../helpers/shipping/CountriesNew';
import SubmitBatchButton from './SubmitBatchButton';
import { domesticServices, internationalServices } from '../../../../../../helpers/shipping/AbdServices';
import { getServicePrice, getMMServicePrice, isTaxable, getShippingZone, getDhlShippingZone } from '../../../../../../helpers/shipping/AbdPricer';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://api.musicmarquee.co.uk';

let MyDoc;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  multipleSplitSection: {
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 4,
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    // color: 'red',
  },
  subHeader: {
    fontSize: 14,
    textAlign: 'center',
    // color: 'red',

  },
  subSubHeader: {
    fontSize: 12,
    textAlign: 'center',
    // color: 'red',

  },
  text: {
    fontSize: 14,
    // color: 'red',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 5,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    // color: 'red',
  },
  multipleHeaderCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    // color: 'red',
  },
});

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'pull_sheet':
        magicSpacing += 5;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class OpenOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      groupedData: [],
      data: [],
      selectedGroup: undefined,
      selected: undefined,
      selectedActWeight: null,
      selectedActualPrice: null,
      selectedMMPrice: null,
      shipmentBatch: [],
      isEditing: false,
      // selectedSize: null,
    };
    this.getGroupedOrders();
  }

  async getGroupedOrders() {
    const response = await axios.post(`${URL}/admin/bandcamp/orders/open`, {
      page: 1,
      pageSize: 1000,
      sorted: null,
      filtered: null,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });

    // const grouped = response.data.orders.filter(orderDest => orderDest.ship_to_country_code !== 'GB' && orderDest.ship_to_country_code === 'AU').reduce((groups, order) => {
    const grouped = response.data.orders.filter(orderDest =>
      orderDest.ship_to_country_code !== 'GB'
      // && orderDest.quantity === 1
      // && (orderDest.ship_to_country_code === 'AU' || orderDest.ship_to_country_code === 'NZ')
      // && orderDest.ship_to_country_code === 'US'
      // && orderDest.ship_to_country_code !== 'BR'
      // && orderDest.ship_to_country_code !== 'VN'
      // && orderDest.ship_to_country_code !== 'NZ'
      // && orderDest.ship_to_country_code !== 'RO'
      // && orderDest.ship_to_country_code !== 'MT'
      // && orderDest.ship_to_country_code !== 'MY'
      // && orderDest.ship_to_country_code !== 'HK'
      // && orderDest.ship_to_country_code !== 'JP'
      // && orderDest.ship_to_country_code !== 'EE'
      // && orderDest.ship_to_country_code !== 'SG'
      && orderDest.selling_band_url !== 'https://parallaxrecordings.bandcamp.com').reduce((groups, order) => {
    // const grouped = response.data.orders.filter(orderDest => orderDest.ship_to_country_code !== 'GB' && (orderDest.ship_notes != null && orderDest.ship_notes.includes('MARQU'))).reduce((groups, order) => {
    // const grouped = response.data.orders.filter(orderDest => orderDest.ship_to_country_code !== 'GB' && (orderDest.ship_to_country_code !== 'JP' && orderDest.ship_to_country_code !== 'TW' && orderDest.ship_to_country_code !== 'CH' && orderDest.ship_to_country_code !== 'TH' && orderDest.ship_to_country_code !== 'KR' && orderDest.ship_to_country_code !== 'MQ' && orderDest.ship_to_country_code !== 'RS' && orderDest.ship_to_country_code !== 'ZA' && orderDest.ship_to_country_code !== 'AU' && orderDest.ship_to_country_code !== 'NZ')).reduce((groups, order) => {
    // const grouped = response.data.orders.reduce((groups, order) => {
      if (response.data.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id).length > 1) {
        const hasPreOrder = response.data.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id).filter(filterOrder2 => filterOrder2.is_preorder === true).length > 0;
        const outOfStock = response.data.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id).filter(filterOrder2 => filterOrder2.mm_quantity < 1).length > 0;

        const itemsInOrder = response.data.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id);
        let toSend = true;
        // let toSend = itemsInOrder.filter(item =>
        // item.sku === 'D-DSP-2' &&
        // item.sku === 'WGW(SPLATTER)' ||
        // item.sku === 'WGW(BLACK180)(L)' ||
        // item.sku === 'DAUPETEATOWEL' ||
        // item.sku === 'OBISCARF' ||
        // item.subdomain.includes('daupe')).length < 5;
        // const toSend = itemsInOrder.filter(item => item.sku === 'SLIPCASE').length > 0;
        // const toSend = itemsInOrder.filter(item => item.sku === 'HWH8(B)(BEER)').length > 0;

        const totalItems = itemsInOrder.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0);
        if (totalItems > 300) {
          toSend = false;
        }


        order.is_preorder = hasPreOrder;
        if (hasPreOrder || !toSend) {
          if (!groups['Multiple Items (Pre-Order)']) groups['Multiple Items (Pre-Order)'] = [];
          groups['Multiple Items (Pre-Order)'].push(order);
        } else if (outOfStock) {
          if (!groups['Multiple Items (Out of Stock)']) groups['Multiple Items (Out of Stock)'] = [];
          groups['Multiple Items (Out of Stock)'].push(order);
        } else {
          if (!groups['Multiple Items']) groups['Multiple Items'] = [];
          groups['Multiple Items'].push(order);
        }
        // groups['Multiple Items'].push(response.data.orders.find(filterOrder =>
        //   filterOrder.payment_id === order.payment_id));
      } else {
        const title = `${order.album_title ? `${order.album_title}:` : ''} ${order.title} by ${order.artist}`;
        if (!groups[title]) groups[title] = [];
        groups[title].push(order);
      }
      return groups;
    }, {});

    const group2 = [];
    console.log('GROUPEDDDD');
    console.log(grouped);
    Object.keys(grouped).forEach((key) => {
      let orderType = '';
      if (grouped[key].filter(filterOrder => filterOrder.is_preorder === true).length > 0) {
        orderType = 'Pre-Order';
      } else if (grouped[key].filter(filterOrder => filterOrder.mm_quantity < 1).length > 0) {
        orderType = 'Out of Stock';
      } else {
        orderType = 'Ready';
      }
      const obj = {
        item: key,
        orders: grouped[key],
        orderType,
      };
      group2.push(obj);
    });
    this.setState({
      groupedData: group2,
    });
  }

  getAvailableTrackedService = (countryLabel, size) => {
    const country = countryList.find(ctry => ctry.label === countryLabel);
    if (size === 'SP') {
      if (country.services.includes('GMP')) {
        return 'GMP';
      } else if (country.services.includes('MP7')) {
        return 'MP7';
      } else if (country.services.includes('MTE')) {
        return 'MTE';
      } else if (country.services.includes('MP9')) {
        return 'MP9';
      }
    } else if (size === 'LL') {
      if (country.services.includes('GMP')) {
        return 'GMP';
      } else if (country.services.includes('MTK')) {
        return 'MTK';
      } else if (country.services.includes('MTG')) {
        return 'MTG';
      } else if (country.services.includes('MTO')) {
        return 'MTO';
      }
    }
    return null;
  };

  async submitPrepareBatch(group) {
    // const dhlSignedOnly = ['NZ', 'AR', 'ZA', 'CO', 'BG', 'UA', 'GR', 'AE', 'RO'];
    if (group.item === 'Multiple Items' || group.item === 'Multiple Items (Pre-Order)') {
      const orders = [];
      const grouped = group.orders.reduce((groups, order) => {
        if (group.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id).length > 1) {
          if (!groups[order.payment_id]) groups[order.payment_id] = [];
          groups[order.payment_id].push(order);
        }
        return groups;
      }, {});

      Object.keys(grouped).forEach((key) => {
        orders.push(grouped[key]);
      });

      const ordersToPush = [];

      orders.map((groupOrders) => {
        let service = '';
        let mmSize = 'LL';
        let toTrack = false;
        groupOrders.map((groupOrder) => {
          if (groupOrder.sku === 'VRADE19') {
            toTrack = true;
          }
          if (groupOrders[0].package_size === 'LL' && groupOrder.package_size === 'SP') {
            groupOrders[0].package_size = 'SP';
          }
        });
        if (groupOrders[0].ship_to_country_code === 'GB') {
          service = 'CRL48';
          if (toTrack) {
            service = 'CRL24S';
          }
        } else if (groupOrders[0].ship_to_country_code !== 'GB') {
          if (countryList.find(country => country.iso2code === groupOrders[0].ship_to_country_code).services.includes('GPT')) {
            service = 'GPT';
          } else {
            service = this.getAvailableTrackedService(groupOrders[0].ship_to_country, groupOrders[0].package_size);
          }
          if (groupOrders[0].package_size === 'SP') {
            mmSize = 'SP';
          } else if (groupOrders[0].package_size === 'LL') {
            mmSize = 'LL';
          }
        }
        // console.log(service);
        // console.log(groupOrders[0].ship_to_country);
        // console.log(toTrack);
        // console.log();
        groupOrders[0].shipping_service = service;
        groupOrders[0].mm_package_size = mmSize;
        let totalValue = 0.00;
        groupOrders.map((order) => {
          totalValue += parseFloat(order.sub_total);
        });
        ordersToPush.push({
          payment_id: groupOrders[0].payment_id,
          sub_total: totalValue,
          shipping_service: service,
          mm_package_size: mmSize,
          package_size: groupOrders[0].package_size,
        });
      });
      const group2 = {};
      group2.orders = ordersToPush;
      await axios.post(`${URL}/admin/bandcamp/orders/prepare`, {
        group2,
      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
        .then((res) => {
        // Update react-table
          const groupCopy = this.state.selectedGroup;
          res.data.orders.map((resOrder) => {
            const groupOrders = groupCopy.orders.filter(findOrder =>
              findOrder.payment_id.toString() === resOrder.payment_id.toString());
            groupOrders.map((groupOrder) => {
              groupOrder.fulfilment_status = resOrder.status;
              groupOrder.fulfilment_id = resOrder.id;
              groupOrder.shipping_service = resOrder.shipping_service;
              groupOrder.package_size = resOrder.package_size;
            });
          });
          this.setState({ selectedGroup: groupCopy });
        });
    } else {
      const orders = [];

      group.orders.map((order) => {
        let service = '';
        let mmSize = 'SP';
        if (order.ship_to_country_code === 'GB') {
          service = 'CRL48';
          if (order.sku === 'VRADE19') {
            service = 'CRL24S';
          }
        } else if (order.ship_to_country_code !== 'GB') {
          if (countryList.find(country => country.iso2code === order.ship_to_country_code).services.includes('GPT')) {
            service = 'GPT';
          } else {
            service = this.getAvailableTrackedService(order.ship_to_country, order.package_size);
          }
          if (order.package_size === 'SP') {
            mmSize = 'SP';
          } else if (order.package_size === 'LL') {
            mmSize = 'LL';
          }
        }
        order.shipping_service = service;
        order.mm_package_size = mmSize;

        orders.push({
          payment_id: order.payment_id,
          package_id: order.package_id,
          sub_total: order.sub_total,
          shipping_service: service,
          mm_package_size: mmSize,
          package_size: order.package_size,
        });
      });
      const group2 = {};
      group2.orders = orders;
      await axios.post(`${URL}/admin/bandcamp/orders/prepare`, {
        group2,
      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
        .then((res) => {
        // Update react-table
          const groupCopy = this.state.selectedGroup;
          res.data.orders.map((resOrder) => {
            const groupOrder = groupCopy.orders.find(findOrder =>
              findOrder.payment_id.toString() === resOrder.payment_id.toString());
            groupOrder.fulfilment_status = resOrder.status;
            groupOrder.fulfilment_id = resOrder.id;
            groupOrder.shipping_service = resOrder.shipping_service;
            groupOrder.package_size = resOrder.package_size;
          });
          this.setState({ selectedGroup: groupCopy });
        });
    }
    return true;
  }

  async submitShipmentBatch(row) {
    const smallPackage = 50;
    const mediumPackage = 200;
    const largePackage = 200;

    if (row.item === 'Multiple Items' || row.item === 'Multiple Items (Pre-Order)') {
      const orders = [];
      const grouped = row.orders.reduce((groups, order) => {
        if (row.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id && filterOrder.fulfilment_status === 'Preparing').length > 1) {
          if (!groups[order.payment_id]) groups[order.payment_id] = [];
          groups[order.payment_id].push(order);
        }
        return groups;
      }, {});

      Object.keys(grouped).forEach((key) => {
        orders.push(grouped[key]);
      });

      const filteredOrders = [];
      const itemsForUpdate = [];
      orders.map((groupOrders) => {
        if (groupOrders[0].fulfilment_status === 'Preparing' && filteredOrders.length <= 35) {
          groupOrders[0].packaging_fee = 0.36;
          groupOrders[0].order_fee = 1.00;

          let totalWeight = groupOrders.map(order => order.mm_weight * order.quantity).reduce((prev, curr) => prev + curr, 0);
          groupOrders[0].customsItems = [];

          groupOrders.map((order, index) => {
            const customsItem = {};
            const nameCheck = order.item_name.toString().toLowerCase();

            if (nameCheck.includes('cd')) {
              customsItem.itemDesc = 'Compact Disc';
              customsItem.itemHs = 8523492000;
            } else if (nameCheck.includes('poster')) {
              customsItem.itemDesc = 'Poster';
              customsItem.itemHs = 4901990000;
            } else if (nameCheck.includes('cassette')) {
              customsItem.itemDesc = 'Cassette Tape';
              customsItem.itemHs = 8523291900;
            } else if (nameCheck.includes('sweatshirt')) {
              customsItem.itemDesc = 'Sweatshirt';
              customsItem.itemHs = 6109100010;
            } else if (nameCheck.includes('scarf')) {
              customsItem.itemDesc = 'Scarf';
              customsItem.itemHs = 6214900000;
            } else if (nameCheck.includes('towel')) {
              customsItem.itemDesc = 'Tea Towel';
              customsItem.itemHs = 6304926000;
            } else if (nameCheck.includes('sticker')) {
              customsItem.itemDesc = 'Sticker Pack';
              customsItem.itemHs = 3919909000;
            } else if (nameCheck.includes('vhs')) {
              customsItem.itemDesc = 'VHS';
              customsItem.itemHs = 8523291900;
            } else if (nameCheck.includes('snapback')) {
              customsItem.itemDesc = 'Peaked Cap';
              customsItem.itemHs = 65050030;
            } else if (nameCheck.includes('slip-mat')) {
              customsItem.itemDesc = 'Felt Slip-Mats';
              customsItem.itemHs = 59111000;
            } else if (nameCheck.includes('coffee')) {
              customsItem.itemDesc = 'Coffee Pack';
              customsItem.itemHs = '09012100';
            } else {
              customsItem.itemDesc = 'Vinyl Record';
              customsItem.itemHs = 8523809000;
            }
            customsItem.itemName = order.item_name;
            customsItem.itemOrigin = 'GBR';
            customsItem.itemQty = order.quantity;
            customsItem.itemPrice = order.price;
            customsItem.itemWeight = order.mm_weight;
            if (index < 5) {
              groupOrders[0].customsItems.push(customsItem);
            }
          });
          if (groupOrders[0].package_size === 'SP') {
            if (groupOrders.map(orderCount => orderCount.quantity).reduce((prev, curr) => prev + curr, 0) > 0) {
              totalWeight += largePackage;
              groupOrders[0].packaging_fee = 0.72;
            } else {
              totalWeight += mediumPackage;
            }
          } else {
            totalWeight += smallPackage;
          }
          if (totalWeight > 1999 && groupOrders[0].ship_to_country_code === 'GB') {
            groupOrders[0].shipping_service = 'TPS48';
          }
          // if (totalWeight > 1999 && groupOrders[0].ship_to_country_code !== 'GB') {
          //   totalWeight = 1999;
          // }
          groupOrders[0].mm_weight = totalWeight;
          if (totalWeight > 749) {
            groupOrders[0].packaging_fee = 0.72;
            groupOrders[0].mm_package_size = 'SP';
          }
          const servicePrice = getServicePrice(groupOrders[0].mm_weight, groupOrders[0].shipping_service, groupOrders[0].ship_to_country, groupOrders[0].package_size);
          const mmPrice = getMMServicePrice(groupOrders[0].mm_weight, groupOrders[0].shipping_service, groupOrders[0].ship_to_country, groupOrders[0].mm_package_size);
          const shippingZone = (groupOrders[0].shipping_service === 'GPT' || groupOrders[0].shipping_service === 'GMP' ? getDhlShippingZone(groupOrders[0].ship_to_country) : getShippingZone(groupOrders[0].ship_to_country));
          groupOrders[0].shipping_zone = shippingZone;
          groupOrders[0].mm_shipping_cost = mmPrice;
          groupOrders[0].client_shipping_price = servicePrice;
          groupOrders[0].fee_vat = (groupOrders[0].packaging_fee + groupOrders[0].order_fee) * 0.2;
          if (isTaxable(groupOrders[0].ship_to_country)) {
            groupOrders[0].shipping_vat = groupOrders[0].client_shipping_price * 0.2;
          } else {
            groupOrders[0].shipping_vat = 0;
          }
          const country = countryList.find(ctry => ctry.label === groupOrders[0].ship_to_country);
          groupOrders[0].ship_to_countrycode = country.value;
          if (groupOrders[0].mm_weight > 1999) {
            if (groupOrders[0].ship_to_country_code === 'GB') {
              filteredOrders.push(groupOrders[0]);
              groupOrders.map((groupOrder) => {
                if (!itemsForUpdate.some(item => item.package_id === groupOrder.package_id)) itemsForUpdate.push({ package_id: groupOrder.package_id, sku: groupOrder.sku, qty: 0 });
                itemsForUpdate.find(item => item.package_id === groupOrder.package_id).qty += groupOrder.quantity;
              });
            }
          } else {
            filteredOrders.push(groupOrders[0]);
            groupOrders.map((groupOrder) => {
              if (!itemsForUpdate.some(item => item.package_id === groupOrder.package_id)) itemsForUpdate.push({ package_id: groupOrder.package_id, sku: groupOrder.sku, qty: 0 });
              itemsForUpdate.find(item => item.package_id === groupOrder.package_id).qty += groupOrder.quantity;
            });
          }
        }
      });
      filteredOrders.map((item, index) => {
        item.batchPosition = index;
      });
      await axios.post(`${URL}/admin/bandcamp/orders/shipdhl`, {
        orders: filteredOrders,
        items_to_update: itemsForUpdate,
      }, {
        headers: {
          Authorization: localStorage.getItem('mm_admin'),
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      })
        .then((res) => {
        // Update react-table
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]); // or any other extension
          document.body.appendChild(link);
          link.click();
          this.getGroupedOrders();
          // window.open(`data:application/pdf;base64,${Buffer.from(res.data).toString('base64')}`);
        });
    } else {
      const filteredOrders = row.orders.filter((order, index) => order.fulfilment_status === 'Preparing' && index <= 35);
      const itemsForUpdate = [];
      // console.log(itemsForUpdate);
      filteredOrders.map((order) => {
        console.log('ORDERRRR');
        console.log(order);
        order.packaging_fee = 0.36;
        order.order_fee = 1.00;

        let totalWeight = order.mm_weight * order.quantity;
        if (order.package_size === 'SP') {
          if (order.quantity > 2) {
            totalWeight += largePackage;
            order.packaging_fee = 0.72;
          } else {
            totalWeight += mediumPackage;
          }
        } else {
          totalWeight += smallPackage;
        }
        if (totalWeight > 1999 && order.ship_to_country_code === 'GB') {
          order.shipping_service = 'TPS48';
        }
        order.mm_weight = totalWeight;
        if (totalWeight > 500) {
          order.packaging_fee = 0.72;
          order.mm_package_size = 'SP';
        }
        const servicePrice = getServicePrice(order.mm_weight, order.shipping_service, order.ship_to_country, order.package_size);
        const mmPrice = getMMServicePrice(order.mm_weight, order.shipping_service, order.ship_to_country, order.mm_package_size);
        const shippingZone = (order.shipping_service === 'GPT' || order.shipping_service === 'GMP' ? getDhlShippingZone(order.ship_to_country) : getShippingZone(order.ship_to_country));

        order.shipping_zone = shippingZone;
        order.mm_shipping_cost = mmPrice;
        order.client_shipping_price = servicePrice;
        order.fee_vat = (order.packaging_fee + order.order_fee) * 0.2;
        if (isTaxable(order.ship_to_country)) {
          order.shipping_vat = order.client_shipping_price * 0.2;
        } else {
          order.shipping_vat = 0;
        }
        console.log(order.ship_to_country);
        const country = countryList.find(ctry => ctry.label === order.ship_to_country);
        order.ship_to_countrycode = country.value;
        order.customsItems = [];

        const customsItem = {};
        customsItem.itemName = order.item_name;

        const nameCheck = order.item_name.toString().toLowerCase();
        if (nameCheck.includes('cd')) {
          customsItem.itemDesc = 'Compact Disc';
          customsItem.itemHs = 8523492000;
        } else if (nameCheck.includes('poster')) {
          customsItem.itemDesc = 'Poster';
          customsItem.itemHs = 4901990000;
        } else if (nameCheck.includes('cassette')) {
          customsItem.itemDesc = 'Cassette Tape';
          customsItem.itemHs = 8523291900;
        } else if (nameCheck.includes('shirt ')) {
          customsItem.itemDesc = 'T-Shirt';
          customsItem.itemHs = 6109100010;
        } else if (nameCheck.includes('sweatshirt')) {
          customsItem.itemDesc = 'Sweatshirt';
          customsItem.itemHs = 6109100010;
        } else if (nameCheck.includes('slipcase')) {
          customsItem.itemDesc = 'Vinyl Record Slipcase';
          customsItem.itemHs = 48195030;
        } else if (nameCheck.includes('snapback')) {
          customsItem.itemDesc = 'Peaked Cap';
          customsItem.itemHs = 65050030;
        } else if (nameCheck.includes('slip-mat')) {
          customsItem.itemDesc = 'Felt Slip-Mats';
          customsItem.itemHs = 59111000;
        } else if (nameCheck.includes('coffee')) {
          customsItem.itemDesc = 'Coffee Pack';
          customsItem.itemHs = '09012100';
        } else {
          customsItem.itemDesc = 'Vinyl Record';
          customsItem.itemHs = 8523809000;
        }
        customsItem.itemOrigin = 'GBR';
        customsItem.itemQty = order.quantity;
        customsItem.itemPrice = order.price;
        customsItem.itemWeight = order.mm_weight;
        order.customsItems.push(customsItem);
      });
      const finalFilteredOrders = filteredOrders.filter((order, index) => !(order.ship_to_country_code !== 'GB' && order.mm_weight > 1999));
      finalFilteredOrders.map((groupOrder) => {
        if (!itemsForUpdate.some(item => item.package_id === groupOrder.package_id)) itemsForUpdate.push({ package_id: groupOrder.package_id, sku: groupOrder.sku, qty: 0 });
        itemsForUpdate.find(item => item.package_id === groupOrder.package_id).qty += groupOrder.quantity;
      });
      filteredOrders.map((item, index) => {
        item.batchPosition = index;
      });
      await axios.post(`${URL}/admin/bandcamp/orders/shipdhl`, {
        orders: filteredOrders,
        items_to_update: itemsForUpdate,
      }, {
        headers: {
          Authorization: localStorage.getItem('mm_admin'),
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      })
        .then((res) => {
        // Update react-table
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.pdf'); // or any other extension
          document.body.appendChild(link);
          link.click();
          this.getGroupedOrders();
          // window.open(`data:application/pdf;base64,${Buffer.from(res.data).toString('base64')}`);
        });
    }
    return true;
  }

  cancelFulfilment(order) {
    if (order.fulfilment_id !== undefined) {
      const postData = {
        orderId: order.fulfilment_id,
      };
      axios.post(`${URL}/admin/bandcamp/orders/fulfilment/cancel`, postData, { headers: { Authorization: localStorage.getItem('mm_admin') } })
        .then((response) => {
          const groupCopy = this.state.selectedGroup;
          const groupOrders = groupCopy.orders.filter(findOrder =>
            findOrder.fulfilment_id === response.data.id);
          groupOrders.map((groupOrder) => {
            groupOrder.fulfilment_status = undefined;
            groupOrder.fulfilment_id = undefined;
          });
          this.setState({ selectedGroup: groupCopy });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }


  generateAllOrderPDFs = () => {
    const pullSheet = this.state.groupedData.map(group => (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>
            AboveBoard PullSheet
          </Text>
          <Text style={styles.subHeader}>
            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>
            {group.item}
          </Text>
          <Text style={styles.subHeader}>
            Number of copies to pull: {group.orders.map(order => order.quantity).reduce((prev, curr) => prev + curr, 0)}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Customer</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Quantity</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Country</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Initials</Text>
            </View>
          </View>
          {group.orders.map(order => (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.ship_to_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.ship_to_country}</Text>
              </View>
              <View style={styles.tableCol} />
            </View>
          ))}
        </View>
      </Page>
    ));

    MyDoc = null;

    this.state.groupedData.map((group) => {
      this.setState({ selectedGroup: group });
      this.submitPrepareBatch(group);
    });

    MyDoc = (
      <Document title="Order">
        {pullSheet}
      </Document>
    );

    // const ordersToPrepare = [];
    // orders.map((order) => {
    //   ordersToPrepare.push(order.id);
    // });

    // this.submitPrepareBatch(orders);
    // this.forceUpdate();
  }

  generateOrderPDF = (group) => {
    // console.log('GROUP SIZE:');
    // console.log(group);
    if (group.item === 'Multiple Items' || group.item === 'Multiple Items (Pre-Order)') {
      let orders = [];
      const grouped = group.orders.reduce((groups, order) => {
        if (group.orders.filter(filterOrder => filterOrder.payment_id === order.payment_id).length > 1) {
          if (!groups[order.payment_id]) groups[order.payment_id] = [];
          groups[order.payment_id].push(order);
        }
        return groups;
      }, {});


      Object.keys(grouped).forEach((key) => {
        orders.push(grouped[key]);
      });

      // console.log(orders.length);

      // LIMIT MULTIPLES TO 20 ORDERS
      orders = orders.slice(0, 20);

      const paymentIdsToKeep = [];
      orders.map(order => order.map(order2 => paymentIdsToKeep.push(order2.payment_id)));
      group.orders = group.orders.filter(x => paymentIdsToKeep.includes(x.payment_id));
      // console.log(group.orders);
      let skusToPull = new Set(group.orders.map(item => item.sku));
      skusToPull = Array.from(skusToPull);
      const skusToPullObjects = [];
      skusToPull.map(sku => skusToPullObjects.push({ sku, quantity: group.orders.filter(orderCount => orderCount.sku === sku).map(order2 => order2.quantity).reduce((prev, curr) => Number(prev) + Number(curr), 0) }));
      // const hasOptions = (!!group.orders[0].options);
      // console.log(group.orders[0].options);
      const pullSheet = (
        <View style={{ width: '100%' }}>
          <View style={styles.section}>
            <Text style={styles.header}>
              Bandcamp PullSheet
            </Text>
            <Text style={styles.subHeader}>
              {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subHeader}>
              Multiple Item Orders
            </Text>
            {
              skusToPullObjects.map(skuToPull => (
                <Text style={styles.subHeader}>
                  {skuToPull.sku}: {skuToPull.quantity}
                </Text>
              ))
            }
          </View>
          {orders.map(order => (
            <View>
              <View style={styles.multipleSplitSection} />
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Customer</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Total Quantity</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell} />
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Country</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{order[0].ship_to_name}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{order.map(orderCount => orderCount.quantity).reduce((prev, curr) => prev + curr, 0)}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{order[0].option_name}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{order[0].ship_to_country}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Item</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Quantity</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Option/Notes</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>SKU</Text>
                  </View>
                </View>
                {order.map(order2 => (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{order2.item_name}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{order2.quantity}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{order2.option_name}</Text>
                      <Text style={styles.tableCell}>{order2.ship_notes}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{order2.sku}{'\n'}{order2.mm_location}</Text>
                    </View>
                  </View>
              ))}
              </View>
            </View>
        ))}
        </View>
      );

      MyDoc = null;

      MyDoc = (
        <Document title="Order">
          <Page size="A4" style={styles.page}>
            {pullSheet}
          </Page>
        </Document>
      );
      this.submitPrepareBatch(group);
      this.forceUpdate();
    } else {
      // LIMIT SINGLES TO 36 ORDERS
      group.orders = group.orders.slice(0, 36);
      const hasOptions = (!!group.orders[0].options);
      const pullSheet = (
        <View style={{ width: '100%' }}>
          <View style={styles.section}>
            <Text style={styles.header}>
              Bandcamp PullSheet
            </Text>
            <Text style={styles.subHeader}>
              {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
            </Text>
          </View>
          <View style={[styles.section, { width: '100%' }]}>
            <Text style={styles.subHeader}>
              {group.item}
            </Text>
            {group.orders[0].options ?
              group.orders[0].options.map(option => (
                <Text style={styles.tableCell}>{option.title}: {group.orders.filter(order => order.option_name === option.title).map(order => order.quantity).reduce((prev, curr) => prev + curr, 0)} (sku: {option.sku})</Text>
              )) : <Text style={styles.tableCell}>SKU: {group.orders[0].sku}{'\n'}{group.orders[0].mm_location}</Text> }
            <Text style={styles.subHeader}>
              Number of items to pull: {group.orders.map(order => order.quantity).reduce((prev, curr) => prev + curr, 0)}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Customer</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Option/Note</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Country</Text>
              </View>
            </View>
            {group.orders.map(order => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{order.ship_to_name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{order.quantity}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{order.option_name}</Text>
                  <Text style={styles.tableCell}>{order.ship_notes}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{order.ship_to_country}</Text>
                </View>
              </View>
          ))}
          </View>
        </View>
      );

      MyDoc = null;

      MyDoc = (
        <Document title="Order">
          <Page size="A4" style={styles.page}>
            {pullSheet}
          </Page>
        </Document>
      );

      this.submitPrepareBatch(group);
      this.forceUpdate();
    }
  }

  render() {
    const ordersColumns = [
      {
        Header: 'Note',
        width: getColumnWidth(this.state.data, '', 'In Batch?'),
        accessor: 'ship_notes',
      }, {
        Header: 'FF Id',
        id: 'id',
        sortable: false,
        accessor: 'id',
        width: getColumnWidth(this.state.data, 'id', 'FF Id'),
      }, {
        Header: 'Order Date',
        id: 'order_date',
        filterable: false,
        width: getColumnWidth(this.state.data, 'order_date', 'Order Date'),
        Cell: row => (
          <div>
            <span>{new Date(row.original.order_date).toLocaleDateString()}</span>
          </div>
        ),
      }, {
        Header: 'Customer',
        id: 'ship_to_name',
        sortable: false,
        accessor: 'ship_to_name',
        width: getColumnWidth(this.state.data, 'ship_to_name', 'Customer'),
      }, {
        Header: 'Destination',
        id: 'ship_to_country',
        accessor: 'ship_to_country',
        width: getColumnWidth(this.state.data, 'ship_to_country', 'Destination'),

      }, {
        Header: 'Quantity',
        id: 'quantity',
        accessor: 'quantity',
        width: getColumnWidth(this.state.data, 'quantity', 'Quantity'),

      }, {
        Header: 'Order Value',
        id: 'sub_total',
        accessor: 'sub_total',
        width: getColumnWidth(this.state.data, 'sub_total', 'Order Value'),

      }, {
        Header: 'Fulfilment Status',
        id: 'fulfilment_status',
        width: getColumnWidth(this.state.data, 'fulfilment_status', 'Fulfilment Status'),
        Cell: row => (
          <div>
            {row.original.fulfilment_status === 'Preparing' ? (
              <tr>{row.original.fulfilment_status} (<a href="#" onClick={() => { this.cancelFulfilment(row.original); }}>Remove</a>)</tr>
                          ) : null }
          </div>
        ),
      },
    ];

    const groupedColumns = [
      {
        Header: 'Order Type',
        id: 'order_type',
        width: getColumnWidth(this.state.data, 'order_type', 'Order Type'),
        accessor: d => d.orderType,
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'Ready'}
            >
              <option value="Ready">Ready</option>
              <option value="Pre-Order">Pre-Order</option>
              <option value="Out of Stock">Out of Stock</option>
              <option value="">All</option>
            </select>),
      }, {
        Header: 'Item',
        id: 'item',
        sortable: false,
        Cell: row => (
          <div>
            <span>{row.original.item}</span>
            <br />
            <span>{row.original.item === 'Multiple Items' ? '-' : row.original.orders[0].sku}</span>
            <br />
            <span>
              <b>{(row.original.orders.length > 1) ? `${row.original.orders.length} Orders `
              : `${row.original.orders.length} Order `}
               ({row.original.orders.map(order => order.quantity).reduce((prev, curr) => prev + curr, 0)} Items)
              </b>
              {row.original.orderType === 'Pre-Order' ? (
                <div>
                  <span>Release Date: {new Date(row.original.orders[0].release_date).toLocaleDateString()}</span>
                </div>
              ) : null }
            </span>
          </div>
        ),
      }, {
        Header: 'Pull Sheet',
        id: 'pull_sheet',
        sortable: false,
        width: getColumnWidth(this.state.data, 'pull_sheet', 'Pull Sheet'),
        Cell: row => (
          <div>
            {(row.original.orders.filter(filterOrder => filterOrder.fulfilment_status === 'Preparing').length > 0) ? (
              // <div>
              <SubmitBatchButton
                color="danger"
                title="Submit Batch"
                task={() => this.submitShipmentBatch(row.original)}
              />
              //   <br />
              //   <SubmitBatchButton
              //     color="danger"
              //     title="Submit DHL"
              //     task={() => this.submitShipmentBatch(row.original)}
              //   />
              // </div>
          ) :
              // <div>
              <Button
                color="primary"
                size="sm"
                onClick={() => this.generateOrderPDF(row.original)}
              >Generate Batch
              </Button>
              //   <br />
              //   // <Button
              //   //   color="primary"
              //   //   size="sm"
              //   //   onClick={() => this.generateOrderPDF(row.original)}
              //   // >Generate DHL
              //   // </Button>
              // </div>
               }
          </div>
        ),
      },
    ];

    return (
      <Col>
        <Card>
          <CardBody>
            <PDFDownloadLink
              className="btn btn-primary"
              document={MyDoc}
              fileName="ABDPullSheet.pdf"
            >
              {({
              loading,
            }) => (loading ? 'Loading document...' : 'Download')}
            </PDFDownloadLink>
            <ReactTable
              data={this.state.groupedData}
              columns={groupedColumns}
              showPagination={false}
              className="-striped -highlight -responsive"
              filterable
              defaultPageSize={200}
              defaultFiltered={[
                {
                  id: 'order_type',
                  value: 'Ready',
                },
              ]}
              getTrProps={(state, rowInfo) => {
                if (rowInfo !== undefined) {
                  return {
                  onClick: (e) => {
                  this.setState({
                  selectedGroup: rowInfo.original,
                  data: rowInfo.original.orders,
                });
                    },
                    style: {
                    background: rowInfo.original === this.state.selectedGroup ? '#00afec' : 'white',
                    color: rowInfo.original === this.state.selectedGroup ? 'white' : 'black',
                  },
                };
              }
              return {};
              }}
              style={{
                height: '400px',
              }}
            />
            <ReactTable
              style={{
                height: '400px',
              }}
              data={this.state.data} // should default to []
              columns={ordersColumns}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual
              filterable
              multiSort={false}
              defaultPageSize={250}
              defaultSorted={[
                {
                  id: 'order_date',
                  desc: true,
                },
              ]}
              className="-striped -highlight -responsive"
              getTrProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      onClick: () => {
                        const currentShipment = this.state.shipmentBatch.find(shipment =>
                          shipment.fulfillmentId === rowInfo.original.id);

                        this.setState({
                          selected: rowInfo.original,
                          selectedActWeight: currentShipment ? currentShipment.weight : '',
                          // selectedSize: currentShipment ? currentShipment.actual_size : rowInfo.original.package_size,
                          selectedMMPrice: currentShipment ? currentShipment.mm_shipping_cost : 0,
                          selectedActualPrice: currentShipment ? currentShipment.actual_shipping_price : 0,
                        });
                        console.log(rowInfo.original);
                      },
                      style: {
                        background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                        color: rowInfo.original === this.state.selected ? 'white' : 'black',
                      },
                    };
                  }
                    return {};
                }} // informs React Table that you'll be handling sorting and pagination server-side
            />
          </CardBody>
        </Card>
        {this.state.selected !== undefined ? (
          <CardDeck>
            <Card>
              <CardBody>
                <div className="project-summary">
                  <div className="card__title">
                    <h5 className="bold-text">{`${this.state.selected.external_order_id}`}</h5>
                  </div>
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Order date:</th>
                            <td>{new Date(this.state.selected.created_date).toLocaleDateString()}</td>
                          </tr>
                          <tr>
                            <th>Order Status:</th>
                            <td>{this.state.selected.status}</td>
                          </tr>
                          <tr>
                            <th>Buyer Notes:</th>
                            <td><b>{this.state.selected.buyer_notes}</b></td>
                          </tr>
                          <tr>
                            <th>Ship Notes:</th>
                            <td><b>{this.state.selected.ship_notes}</b></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Service:</th>
                            {/* <td>{this.getServiceLabel(
                              this.state.selected.shipping_service,
                              this.state.selected.shipping_address_country_code,
                            )}
                            </td> */}
                          </tr>
                          <tr>
                            <th>Weight (est.):</th>
                            <td>{this.state.selected.estimated_weight}g</td>
                          </tr>
                          <tr>
                            <th>Price (est.):</th>
                            <td>{currencyFormat.format(this.state.selected.estimated_shipping_price)}</td>
                          </tr>
                          <tr>
                            <th>Weight (act.):</th>
                            <td>
                              <input
                                type="number"
                                name="actual_weight"
                                value={this.state.selectedActWeight}
                                onChange={this.handleWeightChange}
                                min={1}
                                max={1999}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>Size</th>
                            {/* <td>
                              <select
                                onChange={event => this.handleSizeChange(event.target.value)}
                                value={this.state.selectedSize}
                                disabled={!this.isDomestic(this.state.selected.shipping_address_country_code)}
                              >
                                <option value="LL">Large Letter</option>
                                <option value="SP">Small Parcel</option>
                              // </select>
                            </td> */}
                          </tr>
                          <tr>
                            <th>Price (act.):</th>
                            <td>{currencyFormat.format(this.state.selectedActualPrice)}</td>
                          </tr>
                          <tr>
                            <th>MM Cost:</th>
                            <td>{currencyFormat.format(this.state.selectedMMPrice)}</td>
                          </tr>
                          <tr>
                            <th />
                            <td>
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled={!this.state.selectedActWeight}
                                onClick={this.state.shipmentBatch.find(shipment =>
                                  shipment.fulfillmentId === this.state.selected.id)
                                  ? this.removeFromShipmentBatch : this.addToShipmentBatch}
                              >{this.state.shipmentBatch.find(shipment =>
                                shipment.fulfillmentId === this.state.selected.id)
                                ? 'Remove From Batch' : 'Add To Batch'}
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          {/* <tr>
                            <th>Shipping Address:</th>
                            <td>{this.getShippingAddress()}</td>
                          </tr> */}
                          <tr>
                            <th>First Name:</th>
                            {this.state.isEditing ? (
                              <td>
                                <input
                                  type="text"
                                  name="shipping_address_firstname"
                                  value={this.state.selected.shipping_address_firstname}
                                  // onChange={this.handleWeightChange}
                                  // min={1}
                                  // max={1999}
                                />
                              </td>
                            ) : <td>{this.state.selected.shipping_address_firstname}</td> }
                          </tr>
                          <tr>
                            <th>Last Name:</th>
                            <td>{this.state.selected.shipping_address_lastname}</td>
                          </tr>
                          <tr>
                            <th>Address 1:</th>
                            <td>{this.state.selected.shipping_address_1}</td>
                          </tr>
                          {this.state.selected.shipping_address_2 ? (
                            <tr>
                              <th>Address 2:</th>
                              <td>{this.state.selected.shipping_address_2}</td>
                            </tr>
                          ) : null }
                          {this.state.selected.shipping_address_3 ? (
                            <tr>
                              <th>Address 3:</th>
                              <td>{this.state.selected.shipping_address_3}</td>
                            </tr>
                          ) : null }
                          <tr>
                            <th>City:</th>
                            <td>{this.state.selected.shipping_address_city}</td>
                          </tr>
                          {this.state.selected.shipping_address_region ? (
                            <tr>
                              <th>County:</th>
                              <td>{this.state.selected.shipping_address_region}</td>
                            </tr>
                          ) : null }
                          <tr>
                            <th>Postcode:</th>
                            <td>{this.state.selected.shipping_address_postcode}</td>
                          </tr>
                          {/* <tr>
                            <th>Country:</th>
                            <td>{this.getCountryLabel(this.state.selected.shipping_address_country_code)}</td>
                          </tr> */}
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => this.copyShippingAddressToClipboard()}
                          >Copy Address
                          </Button>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
                <SubmitBatchButton
                  color="primary"
                  title={`Submit Batch (${this.state.shipmentBatch.length})`}
                  task={() => this.submitShipmentBatch()}
                />
              </CardBody>
            </Card>
          </CardDeck>) : null }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(OpenOrdersTable);
