/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/no-string-refs */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Link, Font } from '@react-pdf/renderer';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { countryList } from '../../../../helpers/shipping/CountriesNew';
import SubmitBatchButton from './SubmitBatchButton';
import { domesticServices, internationalServices } from '../../../../helpers/shipping/AbdServices';
import { getServicePrice, getMMServicePrice, isTaxable, getShippingZone, getDhlShippingZone, getAvailableTrackedService } from '../../../../helpers/shipping/AbdPricer';
import { styles, invoiceStyles } from './Styles';
import HelveticaNeue from '../../../../resources/fonts/HelveticaNeue.ttf';
import HelveticaNeueBold from '../../../../resources/fonts/HelveticaNeueBd.ttf';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://api.musicmarquee.co.uk';
const MARKETPLACE_URL = 'https://marketplace-api.musicmarquee.co.uk';

Font.register({
  family: 'HelveticaNeue',
  fonts: [{ src: HelveticaNeue }],
});

Font.register({
  family: 'HelveticaNeueBold',
  fonts: [{ src: HelveticaNeueBold }],
});

let MyDoc;

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'actions':
        magicSpacing += 10;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'pull_sheet':
        magicSpacing += 5;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class OpenOrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      groupedData: [],
      selectedGroup: undefined,
      selected: undefined,
      selectedActWeight: null,
      selectedTrackingNumber: null,
      selectedParcelNumber: null,
      shipmentBatch: [],
      isEditing: false,
      // selectedSize: null,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);

    this.getGroupedOrders();
  }

  async getGroupedOrders() {
    const response = await axios.post(`${URL}/admin/tintsdirect/orders/shopify/open`, {
      page: 1,
      pageSize: 1000,
      sorted: null,
      filtered: null,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    const groupedOrders = response.data.orders;
    groupedOrders.map((order) => {
      if (order.note) {
        const note = order.note.toLowerCase();
        if (note.includes('wait')) {
          order.orderType = 'Wait';
        } else {
          order.orderType = 'Ready';
        }
      } else {
        order.orderType = 'Ready';
      }
    });

    this.setState({
      groupedData: response.data.orders,
    });
  }

  handleFieldChange(e) {
    switch (e.target.name) {
      case 'actual_weight':
        this.setState({ selectedActWeight: e.target.value });
        break;
      case 'tracking_number':
        this.setState({ selectedTrackingNumber: e.target.value });
        break;
      case 'parcel_number':
        this.setState({ selectedParcelNumber: e.target.value });
        break;
      default:
    }
  }

  async submitPrepareOrder(order) {
    await axios.post(`${URL}/admin/tintsdirect/orders/shopify/prepare`, {
      order,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        const selectedGroup = { ...this.state.selectedGroup };
        selectedGroup.mm_fulfilment_status = res.data.order.status;

        const groupedData = [...this.state.groupedData];
        const index = groupedData.findIndex(obj => obj.id === selectedGroup.id);
        if (index !== -1) {
          groupedData[index] = selectedGroup;
        }
        this.setState({
          groupedData,
          selectedGroup,
        });
        return true;
      });
    return true;
  }

  async submitShipment() {
    console.log('SUBMITTING SHIPMENT');
    await axios.post(`${URL}/admin/tintsdirect/orders/shopify/ship`, {
      order: this.state.selectedGroup,
      shipment: { parcel_number: this.state.selectedParcelNumber, tracking_number: this.state.selectedTrackingNumber, actual_weight: this.state.selectedActWeight },
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
        const selectedGroup = { ...this.state.selectedGroup };
        selectedGroup.mm_fulfilment_status = res.data.order.status;

        const groupedData = [...this.state.groupedData];
        const index = groupedData.findIndex(obj => obj.id === selectedGroup.id);
        if (index !== -1) {
          groupedData[index] = selectedGroup;
        }
        this.setState({
          groupedData,
          selectedGroup,
        });
        return true;
      });
    return true;
  }

  copyShippingAddressToClipboard() {
    let returnAddress = `${this.state.selectedGroup.shipping_address.company}\r\n`;
    returnAddress += this.state.selectedGroup.shipping_address.first_name ? `${this.state.selectedGroup.shipping_address.first_name} ${this.state.selectedGroup.shipping_address.last_name}\r\n` : '';
    returnAddress += `${this.state.selectedGroup.shipping_address.address1}\r\n`;
    returnAddress += this.state.selectedGroup.shipping_address.address2 ? `${this.state.selectedGroup.shipping_address.address2}\r\n` : '';
    returnAddress += this.state.selectedGroup.shipping_address.address3 ? `${this.state.selectedGroup.shipping_address.address3}\r\n` : '';
    returnAddress += `${this.state.selectedGroup.shipping_address.city}\r\n`;
    returnAddress += `${this.state.selectedGroup.shipping_address.zip}\r\n`;
    returnAddress += `${this.state.selectedGroup.shipping_address.country}`;
    const inp = document.createElement('textarea');
    document.body.appendChild(inp);
    inp.value = returnAddress;
    inp.select();
    document.execCommand('copy');
    inp.remove();
    // return returnAddress;
  }

  generateOrderPDF = (group) => {
    const pullSheet = (
      <View style={{ width: '100%' }}>
        <View style={styles.section}>
          <Text style={styles.header}>
              TintsDirect Pick Sheet
          </Text>
          <Text style={styles.subHeader}>
            {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
          </Text>
        </View>
        <View style={[styles.section, { width: '100%' }]}>
          <Text style={styles.subHeader}>
            Order: {group.order_name}
          </Text>
          <Text style={[styles.address, { fontWeight: 'bold' }]}>
            Delivery Address:
          </Text>
          <Text style={styles.address}>
            {group.shipping_address.company}
          </Text>
          <Text style={styles.address}>
            {group.shipping_address.name}
          </Text>
          <Text style={styles.address}>
            {group.shipping_address.address1}
          </Text>
          {group.shipping_address.address2 ? (
            <Text style={styles.address}>
              {group.shipping_address.address2}
            </Text>
          ) : null }
          {group.shipping_address.address3 ? (
            <Text style={styles.address}>
              {group.shipping_address.address3}
            </Text>
          ) : null }
          <Text style={styles.address}>
            {group.shipping_address.city}
          </Text>
          <Text style={styles.address}>
            {group.shipping_address.zip}
          </Text>
          <Text style={styles.subHeader}>
              Number of items to pick: {group.line_items.map(order => order.quantity).reduce((prev, curr) => prev + curr, 0)}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColTitle}>
              <Text style={styles.tableCell}>Title</Text>
            </View>
            <View style={styles.tableColSku}>
              <Text style={styles.tableCell}>SKU</Text>
            </View>
            <View style={styles.tableColQty}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
            <View style={styles.tableColCheck}>
              <Text style={styles.tableCell}>Check</Text>
            </View>
          </View>
          {group.line_items.map(item => (
            <View style={styles.tableRow}>
              <View style={styles.tableColTitle}>
                <Text style={[styles.title, { textAlign: 'left' }]}>{item.title}{item.variant_title ? ` - ${item.variant_title}` : ''}</Text>
              </View>
              <View style={styles.tableColSku}>
                <Text style={styles.tableCell}>{item.sku}</Text>
              </View>
              <View style={styles.tableColQty}>
                <Text style={styles.tableCell}>{item.quantity}</Text>
              </View>
              <View style={styles.tableColCheck}>
                <Text style={styles.tableCell} />
              </View>
            </View>
          ))}
        </View>
      </View>
    );

    MyDoc = null;

    MyDoc = (
      <Document title="Order">
        <Page size="A4" style={styles.page}>
          {pullSheet}
        </Page>
      </Document>
    );

    if (!group.mm_fulfilment_status) {
      this.submitPrepareOrder(group);
      this.forceUpdate();
    }
  }

  generateOrderInvoice = async (order) => {
    // console.log(order);
    const shopifyOrder = await axios.post(`${MARKETPLACE_URL}/admin/shopify/order`, {
      store: 'tints-direct',
      orderId: order.id,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } });

    const orderData = shopifyOrder.data;

    const invoice = (
      <View style={{ width: '100%' }}>
        <View style={[invoiceStyles.section, { marginBottom: '0' }]}>
          <Text style={[invoiceStyles.subSubHeader, { float: 'right' }]}>
            Invoice Date: {new Date(orderData.created_at).toLocaleDateString()}
          </Text>
          <Text style={[invoiceStyles.subSubHeader, { float: 'right' }]}>
            Invoice for {orderData.name}
          </Text>
          <Text style={[invoiceStyles.header, { marginBottom: '20' }]}>
            Tints Direct Ltd.
          </Text>
          <Text style={[invoiceStyles.subHeader, { marginBottom: '15' }]}>
            {`Office 006, Big Yellow Storage
            1 Eastman Road
            Harrow
            HA1 4WL`}
          </Text>
          <Text style={[invoiceStyles.subHeader, { marginBottom: '15' }]}>
            {`02089024046
            info@tintsdirect.co.uk
            VAT Reg No. 743 6967 91`}
          </Text>
        </View>
        <View style={[invoiceStyles.section, { marginTop: '10' }]}>
          <Text style={[invoiceStyles.header, { marginBottom: '20' }]}>
            Customer Details
          </Text>
          <Text style={invoiceStyles.subHeader}>
            {orderData.customer.default_address.company}
          </Text>
          <Text style={invoiceStyles.subHeader}>
            {orderData.customer.default_address.address1}
          </Text>
          <Text style={invoiceStyles.subHeader}>
            {orderData.customer.default_address.address2}
          </Text>
          <Text style={invoiceStyles.subHeader}>
            {orderData.customer.default_address.city}
          </Text>
          <Text style={invoiceStyles.subHeader}>
            {orderData.customer.default_address.zip}
          </Text>
          <Text style={[invoiceStyles.subHeader, { marginBottom: '15' }]}>
            {orderData.customer.default_address.country}
          </Text>
        </View>
        <View style={[invoiceStyles.section, { marginTop: '0' }]}>
          <Text style={[invoiceStyles.header, { marginBottom: '20' }]}>
            Item Details
          </Text>
          <View style={invoiceStyles.table}>
            <View style={invoiceStyles.tableRow}>
              <View style={[invoiceStyles.tableColTitle, { textAlign: 'left' }]}>
                <Text style={[invoiceStyles.tableCell, { textAlign: 'left' }]}>Item</Text>
              </View>
              <View style={invoiceStyles.tableColPrice}>
                <Text style={invoiceStyles.tableCell}>Price</Text>
              </View>
              <View style={invoiceStyles.tableColQty}>
                <Text style={invoiceStyles.tableCell}>Quantity</Text>
              </View>
              <View style={invoiceStyles.tableColTax}>
                <Text style={invoiceStyles.tableCell}>Taxes</Text>
              </View>
              <View style={invoiceStyles.tableColTotal}>
                <Text style={invoiceStyles.tableCell}>Total</Text>
              </View>
            </View>
            {orderData.line_items.map(item => (
              <View style={invoiceStyles.tableRow}>
                <View style={invoiceStyles.tableColTitle}>
                  <Text style={[invoiceStyles.tableCellBold, { textAlign: 'left' }]}>{item.title} - {item.variant_title}</Text>
                </View>
                <View style={invoiceStyles.tableColPrice}>
                  <Text style={invoiceStyles.tableCell}>£{item.total_discount !== '0.00' ? (((item.price * item.quantity) - item.total_discount) / item.quantity).toFixed(2) : item.price}</Text>
                </View>
                <View style={invoiceStyles.tableColQty}>
                  <Text style={invoiceStyles.tableCell}>{item.quantity} x</Text>
                </View>
                <View style={invoiceStyles.tableColTax}>
                  <Text style={invoiceStyles.tableCell}>
                    {`£${item.tax_lines[0].price}
                    ${item.tax_lines[0].title}`}
                  </Text>
                </View>
                <View style={invoiceStyles.tableColTotal}>
                  <Text style={invoiceStyles.tableCell}>£{item.total_discount !== '0.00' ? ((item.price * item.quantity) - item.total_discount).toFixed(2) : (item.price * item.quantity).toFixed(2)}</Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={[invoiceStyles.header, { marginTop: '20', marginBottom: '20' }]}>
            Payment Details
          </Text>
          <View style={invoiceStyles.table}>
            <View style={invoiceStyles.tableRow}>
              <View style={[invoiceStyles.tableColTitle, { textAlign: 'left', width: '50%' }]}>
                <Text style={[invoiceStyles.tableCell, { textAlign: 'left' }]}>Subtotal price:</Text>
              </View>
              <View style={[invoiceStyles.tableColPrice, { width: '50%' }]}>
                <Text style={invoiceStyles.tableCell}>£{orderData.current_subtotal_price}</Text>
              </View>
            </View>
            {orderData.discount_codes.length > 0 ? (
              <View style={invoiceStyles.tableRow}>
                <View style={[invoiceStyles.tableColTitle, { textAlign: 'left', width: '50%' }]}>
                  <Text style={[invoiceStyles.tableCell, { textAlign: 'left' }]}>{`Includes discount "${orderData.discount_codes[0].code}"`}</Text>
                </View>
                <View style={[invoiceStyles.tableColPrice, { width: '50%' }]}>
                  <Text style={invoiceStyles.tableCell}>£-{orderData.discount_codes[0].amount}</Text>
                </View>
              </View>
              ) : null }
            <View style={invoiceStyles.tableRow}>
              <View style={[invoiceStyles.tableColTitle, { textAlign: 'left', width: '50%' }]}>
                <Text style={[invoiceStyles.tableCell, { textAlign: 'left' }]}>Total Tax:</Text>
              </View>
              <View style={[invoiceStyles.tableColPrice, { width: '50%' }]}>
                <Text style={invoiceStyles.tableCell}>£{orderData.total_tax}</Text>
              </View>
            </View>
            <View style={invoiceStyles.tableRow}>
              <View style={[invoiceStyles.tableColTitle, { textAlign: 'left', width: '50%' }]}>
                <Text style={[invoiceStyles.tableCell, { textAlign: 'left' }]}>Shipping:</Text>
              </View>
              <View style={[invoiceStyles.tableColPrice, { width: '50%' }]}>
                <Text style={invoiceStyles.tableCell}>£{orderData.total_shipping_price_set.shop_money.amount}</Text>
              </View>
            </View>
            <View style={invoiceStyles.tableRow}>
              <View style={[invoiceStyles.tableColTitle, { textAlign: 'left', width: '50%' }]}>
                <Text style={[invoiceStyles.tableCellBold, { textAlign: 'left' }]}>Total Price:</Text>
              </View>
              <View style={[invoiceStyles.tableColPrice, { width: '50%' }]}>
                <Text style={invoiceStyles.tableCellBold}>£{orderData.total_price}</Text>
              </View>
            </View>
          </View>
          {orderData.note ? (
            <View>
              <Text style={[invoiceStyles.header, { marginTop: '20', marginBottom: '20' }]}>
                Note
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginBottom: '20' }]}>
                {orderData.note}
              </Text>
            </View>
            ) : null }
          <View wrap={false}>
            <Text style={[invoiceStyles.header, { marginTop: '20', marginBottom: '20' }]}>
              Shipping Details
            </Text>
            <View wrap={false} style={[invoiceStyles.shippingAddressBox, { textAlign: 'left' }]}>
              <Text style={[invoiceStyles.subHeader, { margin: '10', marginBottom: '0', fontFamily: 'HelveticaNeueBold' }]}>
                {`${orderData.shipping_address.first_name} ${orderData.shipping_address.last_name}`}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10' }]}>
                {orderData.shipping_address.company}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10' }]}>
                {orderData.shipping_address.address1}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10' }]}>
                {orderData.shipping_address.address2}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10' }]}>
                {orderData.shipping_address.city}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10' }]}>
                {orderData.shipping_address.zip}
              </Text>
              <Text style={[invoiceStyles.subHeader, { marginLeft: '10', marginBottom: '15' }]}>
                {orderData.shipping_address.country}
              </Text>
            </View>
          </View>
          <Text style={[invoiceStyles.subHeader, { marginTop: '15', marginBottom: '20' }]}>
            If you have any questions, please send an email to sales@tintsdirect.co.uk
          </Text>
        </View>
      </View>
    );

    MyDoc = null;

    MyDoc = (
      <Document title="Order">
        <Page size="A4" style={styles.page}>
          {invoice}
        </Page>
      </Document>
    );
    this.forceUpdate();
  }

  render() {
    const ordersColumns = [
      {
        Header: 'Order Type',
        id: 'order_type',
        width: getColumnWidth(this.state.groupedData, 'order_type', 'Order Type'),
        accessor: d => d.orderType,
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'Ready'}
            >
              <option value="Ready">Ready</option>
              <option value="Wait">Wait</option>
              <option value="Out of Stock">Out of Stock</option>
              <option value="">All</option>
            </select>),
      }, {
        Header: 'Order Id',
        id: 'id',
        sortable: false,
        filterable: false,
        accessor: 'order_number',
        width: getColumnWidth(this.state.groupedData, 'order_number', 'Order Id'),
      }, {
        Header: 'Order Date',
        id: 'order_date',
        filterable: false,
        width: getColumnWidth(this.state.groupedData, '01/01/1977', 'Order Date'),
        accessor: 'created_at',
        Cell: row => (
          <div>
            <span>{new Date(row.original.created_at).toLocaleDateString()}</span>
          </div>
        ),
      }, {
        Header: 'Customer',
        id: 'ship_to_name',
        sortable: false,
        // width: getColumnWidth(this.state.groupedData, 'customer', 'Customer'),
        Cell: row => (
          <div>
            <span>{row.original.shipping_address.company ? row.original.shipping_address.company : `${row.original.shipping_address.first_name} ${row.original.shipping_address.last_name}`}</span>
            <br />
            <b>{row.original.line_items.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0)} Items</b>
          </div>
        ),
      },
      // {
      //   Header: 'Destination',
      //   id: 'ship_to_country',
      //   filterable: false,
      //   Cell: row => (
      //     <div>
      //       <span>{row.original.shipping_address.country_code}</span>
      //     </div>
      //   ),
      // },
      // {
      //   Header: 'Total Quantity',
      //   id: 'quantity',
      //   filterable: false,
      //   width: getColumnWidth(this.state.groupedData, '0000', 'Total Quantity'),
      //   Cell: row => (
      //     <div>
      //       <span>{row.original.line_items.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0)}</span>
      //     </div>
      //   ),
      // },
      // {
      //   Header: 'Payment Status',
      //   id: 'payment_state',
      //   accessor: 'financial_status',
      //   filterable: false,
      //   width: getColumnWidth(this.state.data, 'financial_status', 'Payment Status'),
      // },
      {
        Header: 'Fulfilment Status',
        id: 'mm_fulfilment_status',
        accessor: 'mm_fulfilment_status',
        filterable: false,
        width: getColumnWidth(this.state.groupedData, 'fulfilment_status', 'Fulfilment Status'),
      }, {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        filterable: false,
        width: getColumnWidth(this.state.groupedData, 'actions', 'Actions'),
        Cell: row => (
          <div>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.generateOrderPDF(row.original)}
            >{row.original.mm_fulfilment_status ? (
              'Reprint Sheet') : 'Generate Batch' }
            </Button>
            <br />
            <Button
              color="primary"
              size="sm"
              onClick={() => this.generateOrderInvoice(row.original)}
            >Generate Invoice
            </Button>
          </div>
        ),
      },
    ];

    const groupedColumns = [
      {
        Header: 'SKU',
        id: 'sku',
        accessor: 'sku',
        sortable: true,
        filterable: false,
        width: getColumnWidth(this.state.selectedGroup ? this.state.selectedGroup.line_items : [], 'sku', 'SKU'),

      }, {
        Header: 'Quantity',
        id: 'quantity',
        accessor: 'quantity',
        sortable: true,
        filterable: false,
        width: getColumnWidth(this.state.selectedGroup ? this.state.selectedGroup.line_items : [], 'quantity', 'Quantity'),
      }, {
        Header: 'Vendor',
        id: 'vendor',
        accessor: 'vendor',
        sortable: true,
        filterable: false,
        width: getColumnWidth(this.state.selectedGroup ? this.state.selectedGroup.line_items : [], 'vendor', 'Vendor'),

      }, {
        Header: 'Description',
        id: 'description',
        accessor: 'title',
        sortable: true,
        filterable: false,
      },
    ];

    return (
      <Col>
        <Card>
          <CardBody>
            <PDFDownloadLink
              className="btn btn-primary"
              document={MyDoc}
              fileName="ABDPullSheet.pdf"
            >
              {({
              loading,
            }) => (loading ? 'Loading document...' : 'Download')}
            </PDFDownloadLink>
            <ReactTable
              data={this.state.groupedData}
              columns={ordersColumns}
              showPagination={false}
              className="-striped -highlight -responsive"
              filterable
              defaultPageSize={200}
              defaultFiltered={[
                {
                  id: 'order_type',
                  value: 'Ready',
                },
              ]}
              defaultSorted={[
                {
                  id: 'order_date',
                  desc: true,
                },
              ]}
              getTrProps={(state, rowInfo) => {
                if (rowInfo !== undefined) {
                  return {
                  onClick: (e) => {
                    this.setState({
                    selectedActWeight: '',
                    selectedTrackingNumber: '',
                    selectedParcelNumber: '',
                    selectedGroup: rowInfo.original,
                  });
                    },
                    style: {
                    background: rowInfo.original === this.state.selectedGroup ? '#00afec' : 'white',
                    color: rowInfo.original === this.state.selectedGroup ? 'white' : 'black',
                  },
                };
              }
              return {};
              }}
              style={{
                height: '400px',
              }}
            />
            <ReactTable
              style={{
                height: '400px',
              }}
              data={this.state.selectedGroup ? this.state.selectedGroup.line_items : []} // should default to []
              columns={groupedColumns}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual
              filterable
              multiSort={false}
              defaultPageSize={250}
              defaultSorted={[
                {
                  id: 'sku',
                  desc: false,
                },
              ]}
              className="-striped -highlight -responsive"
              getTrProps={(state, rowInfo) => {
                  if (rowInfo !== undefined) {
                    return {
                      onClick: () => {
                        const currentShipment = this.state.shipmentBatch.find(shipment =>
                          shipment.fulfillmentId === rowInfo.original.id);

                        this.setState({
                          selected: rowInfo.original,
                        });
                      },
                      style: {
                        background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                        color: rowInfo.original === this.state.selected ? 'white' : 'black',
                      },
                    };
                  }
                    return {};
                }} // informs React Table that you'll be handling sorting and pagination server-side
            />
            {this.state.selectedGroup !== undefined ? (
              <CardDeck>
                <Card>
                  <CardBody>
                    <div className="project-summary">
                      <div className="card__title">
                        <h5 className="bold-text">{`${this.state.selectedGroup.order_name}`}</h5>
                      </div>
                      <div className="project-summary__stats">
                        <div className="project-summary__stat">
                          <table className="project-summary__info">
                            <tbody>
                              <tr>
                                <th>Order date:</th>
                                <td>{new Date(this.state.selectedGroup.created_at).toLocaleDateString()}</td>
                              </tr>
                              <tr>
                                <th>Order Status:</th>
                                <td>{this.state.selectedGroup.status ? this.state.selectedGroup.status : 'Unfulfilled'}</td>
                              </tr>
                              <tr>
                                <th>Ship Notes:</th>
                                <td><b>{this.state.selectedGroup.note}</b></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="project-summary__stat">
                          <table className="project-summary__info">
                            <tbody>
                              <tr>
                                <th>Service:</th>
                                <td><b>DPD Next Day</b></td>
                              </tr>
                              <tr>
                                <th>Weight (est.):</th>
                                <td>{this.state.selectedGroup.est_total_weight}g</td>
                              </tr>
                              <tr>
                                <th>Weight (act.):</th>
                                <td>
                                  <input
                                    type="number"
                                    name="actual_weight"
                                    value={this.state.selectedActWeight}
                                    onChange={this.handleFieldChange}
                                    min={1}
                                    max={1999}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Parcel Count:</th>
                                <td>
                                  <input
                                    type="number"
                                    name="parcel_number"
                                    value={this.state.selectedParcelNumber}
                                    onChange={this.handleFieldChange}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th>Tracking Number:</th>
                                <td>
                                  <input
                                    type="text"
                                    name="tracking_number"
                                    value={this.state.selectedTrackingNumber}
                                    onChange={this.handleFieldChange}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th />
                                <td>
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={this.state.selectedGroup.mm_fulfilment_status === 'Shipped' || this.state.selectedGroup.mm_fulfilment_status === null || !this.state.selectedTrackingNumber || !this.state.selectedParcelNumber || !this.state.selectedActWeight}
                                    onClick={() => {
                                      this.submitShipment();
                                    }}
                                  >Submit
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="project-summary__stat">
                          <table className="project-summary__info">
                            <tbody>
                              {/* <tr>
                                <th>Shipping Address:</th>
                                <td>{this.getShippingAddress()}</td>
                              </tr> */}
                              <tr>
                                <th>Company:</th>
                                <td>{this.state.selectedGroup.shipping_address.company}</td>
                              </tr>
                              <tr>
                                <th>First Name:</th>
                                {this.state.isEditing ? (
                                  <td>
                                    <input
                                      type="text"
                                      name="shipping_address_firstname"
                                      value={this.state.selectedGroup.shipping_address.first_name}
                                      // onChange={this.handleWeightChange}
                                      // min={1}
                                      // max={1999}
                                    />
                                  </td>
                                ) : <td>{this.state.selectedGroup.shipping_address.first_name}</td> }
                              </tr>
                              <tr>
                                <th>Last Name:</th>
                                <td>{this.state.selectedGroup.shipping_address.last_name}</td>
                              </tr>
                              <tr>
                                <th>Address 1:</th>
                                <td>{this.state.selectedGroup.shipping_address.address1}</td>
                              </tr>
                              {this.state.selectedGroup.shipping_address.address2 ? (
                                <tr>
                                  <th>Address 2:</th>
                                  <td>{this.state.selectedGroup.shipping_address.address2}</td>
                                </tr>
                              ) : null }
                              {this.state.selectedGroup.shipping_address.address3 ? (
                                <tr>
                                  <th>Address 3:</th>
                                  <td>{this.state.selectedGroup.shipping_address.address3}</td>
                                </tr>
                              ) : null }
                              <tr>
                                <th>City:</th>
                                <td>{this.state.selectedGroup.shipping_address.city}</td>
                              </tr>
                              {// {this.state.selectedGroup.shipping_address. ? (
                              //   <tr>
                              //     <th>County:</th>
                              //     <td>{this.state.selectedGroup.shipping_address.}</td>
                              //   </tr>
                              // ) : null }
                            }
                              <tr>
                                <th>Postcode:</th>
                                <td>{this.state.selectedGroup.shipping_address.zip}</td>
                              </tr>
                              {this.state.selectedGroup.shipping_address.phone ? (
                                <tr>
                                  <th>Phone:</th>
                                  <td>{this.state.selectedGroup.shipping_address.phone}</td>
                                </tr>
                              ) : null }
                              {this.state.selectedGroup.shipping_address.contact_email ? (
                                <tr>
                                  <th>Email:</th>
                                  <td>{this.state.selectedGroup.shipping_address.contact_email}</td>
                                </tr>
                              ) : null }
                              {/* <tr>
                                <th>Country:</th>
                                <td>{this.getCountryLabel(this.state.selected.shipping_address_country_code)}</td>
                              </tr> */}
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => this.copyShippingAddressToClipboard()}
                              >Copy Address
                              </Button>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </CardBody>
                </Card>
              </CardDeck>) : null }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(OpenOrdersTable);
