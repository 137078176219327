/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-array-index-key */


import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup, ListGroup, ListGroupItem, Table, Input } from 'reactstrap';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import ActionButton from './ActionButton';


const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://marketplace-api.musicmarquee.co.uk';


class ToolsUpdateOrders extends Component {
  constructor() {
    super();
    this.state = {
      amazonResponse: { complete: false, response: undefined },
      ebayResponse: { complete: false, response: undefined },
      purchasesResponse: { complete: false, response: undefined },
      // abdUpdateResponse: { complete: false, response: undefined },
      // abdShipResponse: { complete: false, response: undefined },
      // readyShipments: [],
      // shipmentsBatch: [],
    };
  }

  async updateAmazonOrders() {
    const response = await axios.post(`${URL}/admin/amazon/update`, {}, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    const updatedOrders = response.data;
    this.setState({
      amazonResponse: { complete: true, response: `Updated ${updatedOrders.length} Amazon Orders.` },
    });
    return true;
  }

  async updateEbayOrders() {
    const response = await axios.post(`${URL}/admin/ebay/update`, {}, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    const updatedOrders = response.data;
    this.setState({
      ebayResponse: { complete: true, response: `Updated ${updatedOrders.length} eBay Orders.` },
    });
    return true;
  }

  async updateDiscogsPurchases() {
    const response = await axios.post(`${URL}/admin/discogs/purchases`, {}, { headers: { Authorization: localStorage.getItem('mm_admin') } });
    const updatedOrders = response.data;
    this.setState({
      purchasesResponse: { complete: true, response: `Updated ${updatedOrders.length} Discogs Purchases.` },
    });
    return true;
  }

  // async updateAbdOrders() {
  //   const response = await axios.post(`${URL}/admin/aboveboard/updateall`, {}, { headers: { Authorization: localStorage.getItem('mm_admin') } });
  //   const updatedOrders = response.data;
  //   this.setState({
  //     abdUpdateResponse: {
  //       complete: true, response: `Updating Pending Orders: Complete ---
  //       Updating Unshipped Orders: Complete ---
  //       Getting New Orders: Complete`,
  //     },
  //   });
  //   return true;
  // }
  //
  // async shipAbdOrders() {
  //   const response = await axios.post(`${URL}/admin/aboveboard/ship`, {}, { headers: { Authorization: localStorage.getItem('mm_admin') } });
  //   const result = response.data;
  //   this.setState({
  //     abdShipResponse: { complete: true, response: `Admin Account: ${result.admin ? 'Shipped' : 'Not Shipped'} --  Fulfilment Account: ${result.fulfilment ? 'Shipped' : 'Not Shipped'}` },
  //   });
  //   return true;
  // }

  render() {
    return (
      <Col style={{ height: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardBody>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Complete</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: '20%' }}>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Update Amazon Orders"
                      task={() => this.updateAmazonOrders()}
                    />
                  </td>
                  <td style={{ width: '10%' }}>{this.state.amazonResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td style={{ width: '70%' }}>{this.state.amazonResponse.response || ''}</td>
                </tr>
                <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Update eBay Orders"
                      task={() => this.updateEbayOrders()}
                    />
                  </td>
                  <td>{this.state.ebayResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.ebayResponse.response || ''}</td>
                </tr>
                <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Update Discogs Purchases"
                      task={() => this.updateDiscogsPurchases()}
                    />
                  </td>
                  <td>{this.state.purchasesResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.purchasesResponse.response || ''}</td>
                </tr>
                {/* <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Update AboveBoard Orders"
                      task={() => this.updateAbdOrders()}
                    />
                  </td>
                  <td>{this.state.abdUpdateResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.abdUpdateResponse.response || ''}</td>
                </tr>
                <tr>
                  <td>
                    <ActionButton
                      color="primary"
                      size="sm"
                      title="Ship AboveBoard Orders"
                      task={() => this.shipAbdOrders()}
                    />
                  </td>
                  <td>{this.state.abdShipResponse.complete ? 'Complete' : 'Not Complete'}</td>
                  <td>{this.state.abdShipResponse.response || ''}</td>
                </tr> */ }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ToolsUpdateOrders);
