/* eslint-disable import/prefer-default-export */

import { domesticServices, internationalServices } from './ServicesNew';
import { countryList, countriesWithSupplement } from './CountriesNew';

export const getDhlShippingZone = (countryLabel) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  return `D${country.dhlZone}`;
};

const getRMCarrierSupplement = (country, service) => {
  const countryWithSupplement = countriesWithSupplement.find(ctry => ctry.value === country.value);
  if (countryWithSupplement) {
    if (service.value === 'DE4' || service.value === 'DG4') {
      return countryWithSupplement.standardAmount;
    }
    return countryWithSupplement.trackedAmount;
  }
  return 0.00;
};

export const getServicePrice = (weight, serviceCode, countryLabel, size) => {
  let weightGroup;

  let service;
  const country = countryList.find(ctry => ctry.label === countryLabel);

  if (country.zone === 'DOMESTIC') {
    service = domesticServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
  } else {
    service = internationalServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
  }

  if (weight && country && service && size) {
    switch (true) {
      case weight <= 100 && weight >= 1:
        weightGroup = 100;
        if (size === 'SP') {
          weightGroup = 250;
        }
        break;
      case weight <= 250 && weight > 100:
        weightGroup = 250;
        break;
      case weight > 250 && weight <= 500:
        weightGroup = 500;
        break;
      case weight <= 750 && weight > 500:
        weightGroup = 750;
        break;
      case weight <= 1000 && weight > 750:
        weightGroup = 1000;
        break;
      case weight <= 1250 && weight > 1000:
        weightGroup = 1250;
        break;
      case weight <= 1500 && weight > 1250:
        weightGroup = 1500;
        break;
      case weight <= 2000 && weight > 1500:
        weightGroup = 2000;
        break;
      case weight > 2000 && weight <= 20000 && country.zone === 'DOMESTIC':
        weightGroup = 20000;
        break;
      default:
    }

    let price;

    if (weightGroup > 0) {
      if (country.label === 'United Kingdom') {
        if (size === 'SP') {
          if (weightGroup <= 20000 && weightGroup > 2000) {
            weightGroup = 20000;
          } else if (weightGroup <= 2000 && weightGroup > 1000) {
            weightGroup = 2000;
          } else {
            weightGroup = 1000;
          }
        } else if (service.value === 'SD1' && weightGroup < 1000) {
          weightGroup = 500;
        }
        price = service.prices[weightGroup];
      } else {
        if (size === 'SP') {
          if (weightGroup < 500) {
            weightGroup = 500;
          }
        }
        if (serviceCode === 'GPT' || serviceCode === 'GPP' || serviceCode === 'GMP') {
          price = service.prices[weightGroup][country.dhlZone];
          console.log(weightGroup);
          console.log(service);
          console.log(price);
        } else {
          price = service.prices[weightGroup][country.zone];
        }
      }
      return price;
    }
  }
  return null;
};

export const getMMServicePrice = (weight, serviceCode, countryLabel, size) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  let service = null;

  let cost = 0.0;

  if (country.zone === 'DOMESTIC') {
    let weightGroup;

    switch (true) {
      case weight <= 100 && weight >= 1:
        weightGroup = 100;
        break;
      case weight > 100 && weight <= 250:
        weightGroup = 250;
        break;
      case weight > 250 && weight <= 500:
        weightGroup = 500;
        break;
      case weight <= 750 && weight > 500:
        weightGroup = 750;
        break;
      case weight <= 1000 && weight > 750:
        weightGroup = 1000;
        break;
      case weight <= 1250 && weight > 1000:
        weightGroup = 1250;
        break;
      case weight <= 1500 && weight > 1250:
        weightGroup = 1500;
        break;
      case weight <= 2000 && weight > 1500:
        weightGroup = 2000;
        break;
      case weight > 2000 && weight <= 20000:
        weightGroup = 20000;
        break;
      default:
    }
    if (size === 'SP') {
      if (weightGroup < 1000) {
        weightGroup = 1000;
      }
    }
    if (serviceCode === 'SD1') {
      if (weightGroup < 500) {
        weightGroup = 500;
      } else if (weightGroup > 500 && weightGroup <= 1000) {
        weightGroup = 1000;
      } else if (weightGroup > 1000 && weightGroup <= 2000) {
        weightGroup = 2000;
      }
    }
    service = domesticServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
    cost = service.obaPricing[weightGroup];
  } else {
    service = internationalServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));

    if (serviceCode === 'GPT' || serviceCode === 'GPP' || serviceCode === 'GMP') {
      const pricePerItem = service.obaPricing[country.dhlZone].PPI;
      const pricePerKilo = service.obaPricing[country.dhlZone].PPK;
      cost += (weight / 1000.0) * pricePerKilo;
      cost += pricePerItem;
    } else {
      let servicePriceObj = service.obaPricing[country.value];
      if (!servicePriceObj) {
        if (country.zone === 'EU') {
          servicePriceObj = service.obaPricing.ROEU;
        } else {
          servicePriceObj = service.obaPricing.ROZ1;
        }
      }
      const pricePerItem = servicePriceObj.PPI;
      let pricePerKilo = servicePriceObj.PPK;
      pricePerKilo += getRMCarrierSupplement(country, service); // ROYAL MAIL TEMPORARY SURCHARGE
      cost += (weight / 1000.0) * pricePerKilo;
      cost += pricePerItem;
    }
  }
  return cost;
};
