/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */


import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://api.musicmarquee.co.uk';

// const isEquals = require('lodash.isequal');

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      case 'sales_channel':
        magicSpacing += 5;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class OrdersReportTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      orders: {},
      // data: [],
      selected: undefined,
      selectedMarketplace: 'ebay',
      // sellers: [],
    };
  }

  async componentWillMount() {
    const response = await axios.get(
      `${URL}/admin/reports/orders`,
      { headers: { Authorization: localStorage.getItem('mm_admin') } },
    );
    this.setState({
      orders: response.data,
    });
  }

  render() {
    const ordersColumns = [
      {
        Header: 'Marketplace',
        id: 'marketplace',
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All Markets</option>
              <option value="amazon">Amazon</option>
              <option value="ebay">eBay</option>
            </select>),
      }, {
        Header: 'Order Id',
        id: `item.order_item.${this.state.selectedMarketplace}_order_id`,
        accessor: `item.order_item.${this.state.selectedMarketplace}_order_id`,

      }, {
        Header: 'Type',
        id: 'sales_channel',
        width: getColumnWidth(this.state.data, 'sales_channel', 'Channel'),
        accessor: d => d.sales_channel,
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All Sales</option>
              <option value="3rdparty">3rd Party</option>
            </select>),
      }, {
        Header: 'Date',
        id: 'created_date',
        filterable: false,
        width: getColumnWidth(this.state.data, 'created_date', 'Date'),
        // Filter: ({ filter, onChange }) =>
        //             <IntervalDatePickerField
        //               onChange={event => onChange(event.target.value)}
        //                 style={{ width: "100%" }}
        //                 value={filter ? filter.value : "all"}
        //             />,
        Cell: row => (
          <div>
            <span>
              {`${new Date(row.original.created_date).toLocaleDateString()}
              ${new Date(row.original.created_date).toLocaleTimeString()}`}
            </span>
          </div>
        ),
      }, {
        Header: 'Sell Value',
        id: 'item.order_item.item_price',
        sortable: false,
        filterable: false,
        accessor: 'item.order_item.item_price',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Sell Shipping',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Marketplace Fee',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Buy Value',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Buy Shipping',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Buy Seller',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      }, {
        Header: 'Gross Profit',
        id: 'external_order_id',
        sortable: false,
        filterable: false,
        accessor: 'external_order_id',
        width: getColumnWidth(this.state.data, 'external_order_id', 'Order Id'),
      },
    ];

    return (
      <Col>
        <Card
          style={{
                height: '900px',
              }}
        >
          <CardBody>
            <div>
              <ReactTable
                style={{
      height: '800px', // This will force the table body to overflow and scroll, since there is not enough room
    }}
                data={this.state.orders[this.state.selectedMarketplace]} // should default to []
                columns={ordersColumns}
                pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
                loading={this.state.loading}
                manual
                filterable
                multiSort={false}
                defaultPageSize={50}
                defaultSorted={[
      {
        id: 'created_date',
        desc: true,
      },
    ]}
                className="-striped -highlight -responsive"
                getTrProps={(state, rowInfo) => {
      if (rowInfo !== undefined) {
        return {
          onClick: () => {
            // const currentShipment = this.state.shipmentBatch.find(shipment =>
            //   shipment.fulfillmentId === rowInfo.original.id);
          },
          style: {
            background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
            color: rowInfo.original === this.state.selected ? 'white' : 'black',
          },
        };
      }
        return {};
    }} // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state) => {
      // show the loading overlay
      this.setState({ loading: true });
      axios.post(`${URL}/admin/fulfillment/orders/open`, {
        page: state.page,
        pageSize: state.pageSize,
        sorted: state.sorted,
        filtered: state.filtered,
      }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
        // Update react-table
        this.setState({
          data: res.data.orders,
          pages: res.data.pages,
          loading: false,
        });
      });
    }}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(OrdersReportTable);
