/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import { Card, CardDeck, CardBody, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
import AddNewSellerEditor from './AddNewSellerEditor';

const isEquals = require('lodash.isequal');

const URL = 'https://api.musicmarquee.co.uk';

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'seller_id':
        magicSpacing += 10;
        break;
      case 'status':
        magicSpacing += 10;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  return null;
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })

class ToolsManageSellersPage extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      data: [],
      selected: undefined,
      selectedOriginal: undefined,
      sellers: [],
      isEditing: false,
      isAdding: false,
    };
    this.handleItemChange = this.handleItemChange.bind(this);
    this.finished = this.finished.bind(this);
  }

  async componentWillMount() {
    // const response = await axios.get(
    //   `${URL}/admin/fulfillment/sellers`,
    //   { headers: { Authorization: localStorage.getItem('mm_admin') } },
    // );
    // this.setState({
    //   sellers: response.data.sellers,
    // });
  }

  getSellerName(id) {
    const retSeller = this.state.sellers.find(seller => seller.id === id).name;
    if (retSeller) {
      return retSeller.name;
    }
    return null;
  }

  getFulfillmentLocationName(locationId) {
    switch (locationId) {
      case 1:
        return 'Orpington';
      case 2:
        return 'Gosport';
      default:
        return '';
    }
  }

  handleItemChange(e) {
    const selected = { ...this.state.selected };
    selected[e.target.name] = e.target.value;
    this.setState({ selected });
  }

  finished() {
    this.setState({ isAdding: false });
  }

  async saveSeller() {
    await axios.post(`${URL}/admin/users/update`, {
      item: this.state.selected,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
      // const newData = [...this.state.data];
      // res.data.orders.map((id) => {
      //   const index = newData.findIndex(obj => obj.id === id.id);
      //   if (index !== -1) {
      //     newData.splice(index, 1);
      //   }
      // });
        const dataCopy = this.state.data;
        dataCopy[dataCopy.indexOf(dataCopy.find(obj => obj.id === res.data.item.id))] = res.data.item;

        this.setState({
          selectedOriginal: res.data.item,
          selected: res.data.item,
          isEditing: false,
          data: dataCopy,
        });
      });
    return true;
  }

  render() {
    const itemsColumns = [
      // {
      //   Header: 'Seller',
      //   id: 'seller_id',
      //   accessor: d => this.state.sellers.find(seller => seller.id === d.seller_id).name,
      //   width: getColumnWidth(this.state.data, 'seller_id', 'Order Id'),
      //   Filter: ({ filter, onChange }) =>
      //     (
      //       <select
      //         onChange={event => onChange(event.target.value)}
      //         style={{ width: '100%' }}
      //         value={filter ? filter.value : 'all'}
      //       >
      //         <option value="">All Sellers</option>
      //         {this.state.sellers.map(seller => <option value={seller.id}>{seller.name}</option>)}
      //       </select>),
      // },
      {
        Header: 'Seller ID',
        id: 'seller_id',
        accessor: 'id',
        width: getColumnWidth(this.state.data, 'id', 'Seller Id'),
      }, {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        width: getColumnWidth(this.state.data, 'name', 'Name'),
      }, {
        Header: 'Email',
        id: 'email',
        accessor: 'email',
        width: getColumnWidth(this.state.data, 'email', 'Email'),
      }, {
        Header: 'Location',
        id: 'fulfillment_location_id',
        accessor: d => d.fulfillment_location_id,
        Cell: row => (
          <div>
            { this.getFulfillmentLocationName(row.original.fulfillment_location_id)}
          </div>
        ),
        Filter: ({ filter, onChange }) =>
          (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="">All</option>
              <option value={1}>Orpington</option>
              <option value={2}>Gosport</option>
            </select>),
      },
    ];

    return (
      <Col>
        <Card
          style={{
            height: '550px',
          }}
        >
          <CardBody>
            {
              // <Button
                // color={this.state.isAdding ? ('danger') : 'primary'}
                // size="sm"
                // onClick={() => this.setState(prevState => ({ isAdding: !prevState.isAdding }))}
              // >{this.state.isAdding ? ('Cancel') : 'Add New Seller'}
              // </Button>
            }
            {this.state.isAdding ? (
              <AddNewSellerEditor finished={this.finished} />)
                : (
                  <div>
                    <ReactTable
                      style={{
                        height: '400px',
                      }}
                      data={this.state.data} // should default to []
                      columns={itemsColumns}
                      pages={this.state.pages}
                      loading={this.state.loading}
                      manual
                      filterable
                      multiSort={false}
                      defaultPageSize={50}
                      defaultSorted={[
                    {
                      id: 'id',
                      desc: true,
                    },
                  ]}
                      className="-striped -highlight -responsive"
                      getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                        onClick: () => {
                          this.setState({
                            selected: rowInfo.original,
                            selectedOriginal: rowInfo.original,
                            isEditing: false,
                          });
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black',
                        },
                      };
                    }
                    return {};
                  }} // informs React Table that you'll be handling sorting and pagination server-side
                      onFetchData={(state) => {
                    // show the loading overlay
                    this.setState({ loading: true });
                    axios.post(`${URL}/admin/users/users`, {
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                    .then((res) => {
                      // Update react-table
                      this.setState({
                        data: res.data.items,
                        pages: res.data.pages,
                        loading: false,
                      });
                    });
                  }}
                    />
                  </div>
        )}
          </CardBody>
        </Card>
        {this.state.selected !== undefined && !this.state.isAdding ? (
          <CardDeck>
            <Card>
              <CardBody>
                <Button
                  color="primary"
                  size="sm"
                  disabled={this.state.isEditing}
                  onClick={() => this.setState(prevState => ({ isEditing: !prevState.isEditing }))}
                >Edit Seller
                </Button>
                {this.state.isEditing ? (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() =>
                        this.setState(prevState => ({
                          isEditing: !prevState.isEditing,
                          selected: prevState.selectedOriginal,
                        }))
                      }
                  >Cancel
                  </Button>
                  ) : null }
                <div className="project-summary">
                  <div className="card__title">
                    <h5 className="bold-text">
                      {this.state.selected.name}
                    </h5>
                  </div>
                  <div className="project-summary__stats">
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Name:</th>
                            <td>{this.state.selected.name}</td>
                          </tr>
                          <tr>
                            <th>Email:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="text"
                                  name="email"
                                  disabled
                                  value={this.state.selected.email}
                                  onChange={this.handleItemChange}
                                />
                              ) : <b>{this.state.selected.email}</b> }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="project-summary__stat">
                      <table className="project-summary__info">
                        <tbody>
                          <tr>
                            <th>Order Fee %:</th>
                            <td>
                              {this.state.isEditing ? (
                                <input
                                  type="number"
                                  name="fulfillment_fee_percent"
                                  disabled
                                  value={this.state.selected.fulfillment_fee_percent}
                                  onChange={this.handleItemChange}
                                />
                                ) : <b>{this.state.selected.fulfillment_fee_percent}</b> }
                            </td>
                          </tr>
                          <tr>
                            <th>Fulfilment Location:</th>
                            <td>
                              {this.state.isEditing ? (
                                <select
                                  onChange={event => this.handleItemChange(event)}
                                  value={this.state.selected.fulfillment_location_id}
                                  name="fulfillment_location_id"
                                  disabled={!this.state.isEditing}
                                >
                                  <option value={1}>Orpington</option>
                                  <option value={2}>Gosport</option>
                                </select>
                              ) :
                                <b>
                                  {this.getFulfillmentLocationName(this.state.selected.fulfillment_location_id)}
                                </b>
                                }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
                { isEquals(this.state.selectedOriginal, this.state.selected) ? (
                    null
                  ) :
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.saveSeller()}
                    >Save Changes
                    </Button> }
              </CardBody>
            </Card>
          </CardDeck>) : null }
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ToolsManageSellersPage);
