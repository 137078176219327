import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import OpenOrdersTable from './components/OpenOrdersTable';

const ShopifyOrders = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h5 className="page-title">Fulfilment / Shopify / Orders</h5>
      </Col>
    </Row>
    <Row>
      <OpenOrdersTable />
    </Row>
  </Container>
);

export default ShopifyOrders;
