import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import LoadingIcon from 'mdi-react/LoadingIcon';
import classNames from 'classnames';

export default class GenerateDocumentsButton extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    outline: PropTypes.bool,
    color: PropTypes.string,
    task: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: '',
    outline: false,
    color: 'secondary',
  };

  constructor() {
    super();
    this.state = {
      load: false,
    };
  }

  onLoad = () => {
    this.setState({
      load: true,
    });
    this.request();
  };

  async request() {
    // your async logic here
    const finished = await this.props.task();
    this.setState({ load: !finished });
  }

  render() {
    const expandClass = classNames({
      icon: true,
      expand: true,
      'expand--load': this.state.load,
    });

    return (
      <Button
        onClick={this.onLoad}
        className={expandClass}
        color={this.props.color}
        outline={this.props.outline}
      >
        <p><LoadingIcon /> {this.state.load ? 'Generating...' : this.props.title}</p>
      </Button>
    );
  }
}
