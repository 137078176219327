const validate = (values) => {
  const errors = [];
  if (values) {
    if (values.shipping_carrier === 'DHL') {
      if (values.shipping_address_firstname.length + values.shipping_address_lastname.length > 30) {
        errors.push('First and Last name must be 30 chars or less.');
      }
      if (values.shipping_address_1.length > 40) {
        errors.push('Max 40 Chars');
      }
      if (values.shipping_address_2) {
        if (values.shipping_address_2.length > 40) {
          errors.push('Address Line 2: Max 40 Chars');
        }
        if (values.shipping_address_3) {
          if ((values.shipping_address_2.length + values.shipping_address_3.length) > 40) {
            errors.push('Address Line 2 + 3: Max 40 Chars Combined');
          }
        }
      }
    } else if (values.shipping_carrier === 'Royal Mail') {
      console.log();
    }
    if (values.shipping_address_postcode) {
      if (values.shipping_address_country_code) {
        if (values.shipping_address_country_code === 'GBR') {
          if (!values.shipping_address_postcode.match(/^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/)) {
            errors.push('Not a valid postcode.');
          }
        }
      }
    }
    if (values.phone) {
      if (values.phone.match(/^[A-Za-z]+$/)) {
        errors.push('Not a valid phone number.');
      }
    }
    if (values.email) {
      if (!values.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        errors.push('Not a valid email address.');
      }
    }
  }
  return errors;
};

export default validate;
