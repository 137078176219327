/* eslint-disable import/prefer-default-export */

import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  },
  multipleSplitSection: {
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 4,
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    // color: 'red',
  },
  subHeader: {
    fontSize: 14,
    textAlign: 'center',
    // color: 'red',

  },
  subSubHeader: {
    fontSize: 12,
    textAlign: 'center',
    // color: 'red',
  },
  address: {
    fontSize: 10,
    textAlign: 'left',
    // color: 'red',
  },
  title: {
    fontSize: 10,
    textAlign: 'left',
    // margin: 'auto',
    marginTop: 5,
    // color: 'red',
  },
  text: {
    fontSize: 14,
    // color: 'red',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 5,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTitle: {
    width: '65%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColSku: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColQty: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColCheck: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    // color: 'red',
  },
  multipleHeaderCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    // color: 'red',
  },
});

export const invoiceStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 20,
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 0.75,
  },
  shippingAddressBox: {
    margin: 0,
    padding: 0,
    borderStyle: 'solid',
    borderWidth: 0.75,
  },
  multipleSplitSection: {
    padding: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black',
    borderBottomWidth: 4,
  },
  header: {
    fontSize: 18,
    textAlign: 'left',
    fontFamily: 'HelveticaNeue',
    fontWeight: 'bold',
    // color: 'red',
  },
  subHeader: {
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'HelveticaNeue',
    // color: 'red',
  },
  subSubHeader: {
    fontSize: 12,
    textAlign: 'right',
    // color: 'red',
  },
  address: {
    fontSize: 10,
    textAlign: 'left',
    // color: 'red',
  },
  title: {
    fontSize: 10,
    textAlign: 'left',
    // margin: 'auto',
    marginTop: 5,
    // color: 'red',
  },
  text: {
    fontSize: 14,
    // color: 'red',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTitle: {
    width: '60%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColPrice: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColQty: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTax: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColTotal: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 0.75,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    // color: 'red',
  },
  tableCellBold: {
    margin: 5,
    fontSize: 10,
    fontFamily: 'HelveticaNeueBold',
    // color: 'red',
  },
  multipleHeaderCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    // color: 'red',
  },
});
