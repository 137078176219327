/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-nested-ternary */


import React, { Component } from 'react';
import ContentSaveIcon from 'mdi-react/ContentSaveIcon';
import { Card, CardDeck, CardBody, Col, Button, ButtonToolbar, ButtonGroup, ListGroup, ListGroupItem, Table, Input } from 'reactstrap';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';

const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
const URL = 'https://marketplace-api.musicmarquee.co.uk';
const BACKEND_URL = 'https://api.musicmarquee.co.uk';


const tempFFSellers = [
  'dempster13',
  'GlennsMusic',
  'skytrial',
  'HOLYGRAILS',
  'Dobshizzle',
  'SteviesoulLimited',
  'willy-fogg',
  'brightonvinylfinds',
  'teatwomilky',
  'scott_infinity',
  'SlaveToTheVibe',
  'Vinyl_Blend',
  'knivo',
  'london-classical',
  'MusicMine',
  'DanceForFun',
  'blackcat_records',
  'New_Frontier',
  'mixman100',
  'Heathers-CDs',
  'DavidMAnderson',
  'Sublabel_Recs',
  'brixtonvibes',
  'earthquakerecords'];
class ShippingToolsAmazonPage extends Component {
  constructor() {
    super();
    this.state = {
      // readyShipments: [],
      readyShipmentItems: [],
      notReadyShipmentItems: [],
      // notReadyShipments: [],
      shipmentsBatch: [],
    };
    this.getShipments();
  }

  async getShipments() {
    const response = await axios.get(`${URL}/admin/amazon/ship`, { headers: { Authorization: localStorage.getItem('mm_admin') } });

    const shipments = this.splitShipments(response.data);

    console.log(shipments.readyShipments.length);
    console.log(shipments.notReadyShipments);
    this.setState({
      // readyShipments: shipments.readyShipments,
      // notReadyShipments: shipments.notReadyShipments,
      notReadyShipmentItems: shipments.notReadyShipmentItems,
      readyShipmentItems: shipments.readyShipmentItems,
    });
  }

  submitShipments(shipments) {
    console.log(shipments);
    axios.post(`${URL}/admin/amazon/ship`, {
      shipments,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        console.log('RES');
        console.log(res.data);
        // const currentData = [...this.state.data];
        // const itemToUpdate = currentData.find(order => order.ebay_order_id === res.data.ebay_order_id).items.find(item => item.order_item.item_id === res.data.item_id);
        // itemToUpdate.order_item = res.data;
        // this.setState({
        //   data: currentData,
        // });
      });
  }

  addToShipmentBatch(shipment) {
    this.setState({ shipmentsBatch: [...this.state.shipmentsBatch, shipment] });
  }

  splitShipments(shipments) {
    const readyShipments = [];
    const readyShipmentItems = [];
    const notReadyShipmentItems = [];
    const notReadyShipments = [];

    // shipments.map((shipment) => {
    //   const ready = false;
    //   shipment.order_items.map((order_item) => {
    //     if (order_item.purchase === null && order_item.mm_purchase === null) {
    //       notReadyShipments.push(shipment);
    //       notReadyShipmentItems.push({
    //         order: shipment.order,
    //         order_item,
    //       });
    //     } else if (order_item.purchase) {
    //       if (order_item.purchase.status === 'Shipped' || order_item.purchase.purchase_id === 'Self' || tempFFSellers.includes(order_item.purchase.seller_name)) {
    //         readyShipments.push(shipment);
    //         readyShipmentItems.push({
    //           order: shipment.order,
    //           order_item,
    //         });
    //       } else {
    //         notReadyShipments.push(shipment);
    //         notReadyShipmentItems.push({
    //           order: shipment.order,
    //           order_item,
    //         });
    //       }
    //     } else if (order_item.mm_purchase) {
    //       if (order_item.mm_purchase.status === 'Shipped' || tempFFSellers.includes(order_item.mm_purchase.seller_name)) {
    //         readyShipments.push(shipment);
    //         readyShipmentItems.push({
    //           order: shipment.order,
    //           order_item,
    //         });
    //       } else {
    //         notReadyShipments.push(shipment);
    //         notReadyShipmentItems.push({
    //           order: shipment.order,
    //           order_item,
    //         });
    //       }
    //     } else if (order_item.shipment != null) {
    //       readyShipments.push(shipment);
    //       readyShipmentItems.push({
    //         order: shipment.order,
    //         order_item,
    //       });
    //     } else {
    //       notReadyShipments.push(shipment);
    //       notReadyShipmentItems.push({
    //         order: shipment.order,
    //         order_item,
    //       });
    //     }
    //   });
    // });

    shipments.map((shipment) => {
      const ready = false;
      shipment.order_items.map((order_item) => {
        if (order_item.item.tracking_number || order_item.shipment !== null) {
          readyShipments.push(shipment);
          readyShipmentItems.push({
            order: shipment.order,
            order_item,
          });
        } else {
          notReadyShipments.push(shipment);
          notReadyShipmentItems.push({
            order: shipment.order,
            order_item,
          });
        }
      });
    });

    return {
      readyShipments, notReadyShipments, readyShipmentItems, notReadyShipmentItems,
    };
  }

  checkAll() {
    // const ref = `checkbox_${i}`;
    // this.refs[ref].checked = !this.refs[ref].checked;
    Object.keys(this.refs).forEach((ref) => {
      this.refs[ref].checked = !this.refs[ref].checked;
    });
    this.setState({
      shipmentsBatch: this.state.readyShipmentItems,
    });
    // console.log(this.refs);
  }

  updateTrackingNumber(index) {
    const items = [...this.state.notReadyShipmentItems];
    const item = { ...items[index] };
    item.order_item.item.tracking_number = this.refs[`tracking_${index}`].value;
    items[index] = item;
    console.log('ITEM');
    console.log(item);

    axios.post(`${BACKEND_URL}/admin/orders/amazon/update/tracking`, {
      item,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        item.order_item.item.tracking_number = res.data.tracking_number;
        items[index] = item;
        this.setState({ notReadyShipmentItems: items });
      });
  }

  render() {
    return (
      <Col style={{ height: '100%' }}>
        <Card style={{ width: '100%' }}>
          <CardBody>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.checkAll()}
            >Select All
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => console.log(this.state.shipmentsBatch)}
            >Check Batch
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => this.submitShipments(this.state.shipmentsBatch)}
            >Submit Batch
            </Button>
            <div>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Order Date</th>
                    <th>Order #</th>
                    <th>Status</th>
                    <th>Item</th>
                    <th>Seller</th>
                    <th>Purchase ID</th>
                    <th>Purchase Status</th>
                    <th>Tracking</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.readyShipmentItems.map((shipment, index) => (
                    <tr>
                      <td><input
                        ref={`checkbox_${index}`}
                        type="checkbox"
                        onChange={e => (e.target.checked ? this.setState({ shipmentsBatch: [...this.state.shipmentsBatch, this.state.readyShipmentItems[index]] }) : this.setState({
                          shipmentsBatch: this.state.shipmentsBatch.filter(item => item !== this.state.readyShipmentItems[index]),
                        }))}
                      />
                      </td>
                      <td>{shipment.order.order_date}</td>
                      <td>{shipment.order_item.item.amazon_order_id}</td>
                      <td>{shipment.order.status}</td>
                      <td>{shipment.order_item.item.title}</td>
                      <td>{shipment.order_item.purchase ? shipment.order_item.purchase.seller_name : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.seller_name : null }</td>
                      <td>{shipment.order_item.purchase ? shipment.order_item.purchase.purchase_id : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.purchase_id : null }</td>
                      <td>{shipment.order_item.purchase && shipment.order_item.purchase.purchase_id !== 'Self' ?
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://www.discogs.com/sell/order/${shipment.order_item.purchase.discogs_purchase_id}`}
                        >{shipment.order_item.purchase.status}
                        </a>
                      : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.status
                      : null }
                      </td>
                      <td>{shipment.order_item.shipment ? shipment.order_item.shipment.tracking_no || shipment.order_item.shipment.carrier_ref : shipment.order_item.item.tracking_number }</td>
                    </tr>
              ))}
                </tbody>
              </Table>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Order Date</th>
                    <th>Order #</th>
                    <th>Status</th>
                    <th>Item</th>
                    <th>Seller</th>
                    <th>Purchase ID</th>
                    <th>Purchase Status</th>
                    <th>Tracking</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.notReadyShipmentItems.map((shipment, index) => (
                    <tr>
                      <td>{shipment.order.order_date}</td>
                      <td>{shipment.order_item.item.amazon_order_id}</td>
                      <td>{shipment.order.status}</td>
                      <td>{shipment.order_item.item.title}</td>
                      <td>{shipment.order_item.purchase ? shipment.order_item.purchase.seller_name : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.seller_name : null }</td>
                      <td>{shipment.order_item.purchase ? shipment.order_item.purchase.purchase_id : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.purchase_id : null }</td>
                      <td>{shipment.order_item.purchase ?
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://www.discogs.com/sell/order/${shipment.order_item.purchase.purchase_id}`}
                        >{shipment.order_item.purchase.status}
                        </a>
                      : shipment.order_item.mm_purchase ? shipment.order_item.mm_purchase.status
                      : null }
                      </td>
                      {!shipment.order_item.shipment && !shipment.order_item.item.tracking_number && !shipment.order_item.mm_purchase && shipment.order_item.purchase && shipment.order_item.purchase.purchase_id !== 'Self' ? (
                        <td>
                          <div className="form__form-group-field">
                            <input
                              ref={`tracking_${index}`}
                              type="text"
                              name="password"
                              placeholder="Enter Tracking..."
                              // value={shipment.order_item.item.tracking_number}
                              onChange={e => ''}
                            />
                            <button
                              className="form__form-group-button active"
                              onClick={e => this.updateTrackingNumber(index)}
                            >
                              <ContentSaveIcon />
                            </button>
                          </div>
                        </td>
                          ) : (
                            shipment.order_item.shipment ? (
                              <td>{shipment.order_item.shipment ? shipment.order_item.shipment.tracking_no || shipment.order_item.shipment.carrier_ref : null }</td>
                          ) : shipment.order_item.item.tracking_number ? (
                            <td>{shipment.order_item.item.tracking_number}</td>
                        ) : <td />
                        )}
                    </tr>
              ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(ShippingToolsAmazonPage);
