/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */


import React, { Component } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { connect } from 'react-redux';
// import ProductGallery from './ProductGallery';


const URL = 'https://api.musicmarquee.co.uk';

const getColumnWidth = (rows, accessor, headerText) => {
  if (rows !== undefined) {
    const maxWidth = 400;
    let magicSpacing = 10;
    switch (accessor) {
      case 'total':
        magicSpacing += 3;
        break;
      case 'date':
        magicSpacing += 7;
        break;
      case 'status':
        magicSpacing -= 2.5;
        break;
      default:
    }
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
};

// const currencyFormat = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

class PurchasesTable extends Component {
  constructor() {
    super();
    this.state = {
      pages: 2,
      loading: false,
      data: [],
      selected: {},
      shouldRelist: false,
      purchases: JSON.parse(localStorage.getItem('purchases')) || {},
    };
  }

  getImageUrls() {
    const urls = [];

    if (this.state.selected.image_count) {
      for (let i = 1; i <= this.state.selected.image_count; i++) {
        if (i < 10) {
          urls[i - 1] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img0${i}.jpg` };
        } else {
          urls[i - 1] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img${i}.jpg` };
        }
      }
    } else {
      urls[0] = { src: `https://s3-eu-west-1.amazonaws.com/releaseimages/${this.state.selected.release_id.toString().split('').join('/')}/img01.jpg` };
    }
    return urls;
  }

  getDiscogsImageURL(releaseId) {
    const split = releaseId.toString().split('');
    const join = split.join('/');
    const url = `https://s3-eu-west-1.amazonaws.com/releaseimages/${join}/img01.jpg`;
    return url;
  }

  cancelPurchase() {
    const purchase = { ...this.state.selected };
    // purchase.status = 'Cancelled';
    purchase.relist = this.state.shouldRelist;
    console.log(purchase);
    axios.post(`${URL}/admin/purchases/cancel`, {
      purchase,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        if (res.data.purchase.id !== null) {
          const newData = [...this.state.data];
          const index = newData.findIndex(obj => obj.id === res.data.purchase.id);
          if (index !== -1) {
            newData[index].status = res.data.purchase.status;
            // newData.splice(index, 1, res.data.purchase);
          }

          this.setState({
            data: newData,
          });
        }
      });
  }

  completePurchase(seller) {
    console.log('COMPLETING PURCHASE');
    console.log(seller);
    const purchase = this.state.purchases[seller];
    console.log(purchase);
    axios.post(`${URL}/admin/purchases/create`, {
      purchase,
    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
      .then((res) => {
      // Update react-table
        console.log('RESPONSE:');
        console.log(res);
        if (res.data.purchase.purchase_id !== null) {
          const currentState = this.state.purchases;
          currentState[seller].isComplete = true;
          currentState[seller].purchaseId = res.data.purchase.purchase_id;
          this.setState({ purchases: currentState });
          localStorage.setItem('purchases', JSON.stringify(currentState));
          // currentState[seller].items.map(item => this.addItemToCart(item));
        }
      });
  }

  updatePurchaseDetails(event, seller) {
    const purchases = this.state.purchases;
    purchases[seller][event.target.name] = event.target.value;
    this.setState({ purchases });
  }

  addItemToCart(item) {
    const purchases = this.state.purchases;
    if (purchases[item.seller_username] === undefined || purchases[item.seller_username].isComplete === true) {
      purchases[item.seller_username] = {
        items: [item], seller_id: item.seller_id, instructions: '', shipping_address: '', isComplete: false, purchaseId: null,
      };
      this.setState({
        purchases,
      }, () => {
        localStorage.setItem('purchases', JSON.stringify(purchases));
      });
    } else {
      if (!purchases[item.seller_username].items.some(cartItem => cartItem.listing_id === item.listing_id)) {
        purchases[item.seller_username].items.push(item);
      } else {
        purchases[item.seller_username].items = purchases[item.seller_username].items.filter(cartItem => cartItem.listing_id !== item.listing_id);
        if (purchases[item.seller_username].items.length < 1) {
          delete purchases[item.seller_username];
        }
      }
      this.setState({
        purchases,
      }, () => {
        localStorage.setItem('purchases', JSON.stringify(purchases));
      });
    }
  }

  groupBy(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
    return data.reduce((storage, item) => {
    // get the first instance of the key by which we're grouping
      const group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, {}); // {} is the initial value of the storage
  }


  render() {
    const purchasesColumns = [
      {
        Header: 'MM Id',
        id: 'id',
        accessor: 'id',
        width: getColumnWidth(this.props.orders, 'id', 'MM Id'),
      },
      {
        Header: 'Seller',
        id: 'name',
        accessor: 'name',
        width: getColumnWidth(this.props.orders, 'name', 'Seller'),
      },
      {
        Header: 'Order Id',
        id: 'order_id',
        Cell: row => (
          <div>
            <span><b>MM-{row.original.seller_id}-{row.original.order_id}</b></span>
          </div>
        ),
        width: getColumnWidth(this.props.orders, 'order_id', 'Order Id'),
      }, {
        Header: 'Summary',
        id: 'summary',
        sortable: false,
        // accessor: d => {
        //   if (d.items.length > 1) {
        //     return d.items.length+' Items';
        //   } else {
        //     return d.items.length+' Item';
        //   }
        // }
        Cell: row => (
          <div>
            <span><b>{(row.original.items.length > 1) ? `${row.original.items.length} Items:` : `${row.original.items.length} Item:`}</b></span>
            {row.original.items.map(i =>
              <tr>{i ? i.description : '' }</tr>)}
          </div>
        ),
      }, {
        Header: 'Total',
        id: 'items_total',
        filterable: false,
        width: getColumnWidth(this.props.orders, 'total', 'Total'),
        Cell: row => (
          <div>
            <span>{`Items: £${row.original.items_total}`}</span>
            <br />
            <span><b>{`Shipping: £ ${row.original.shipping_total ? row.original.shipping_total : 'NOT SET'}`}</b></span>
          </div>
        ),
      }, {
        Header: 'Status',
        id: 'status',
        width: getColumnWidth(this.props.orders, 'status', 'Status'),
        // accessor: d => {
        //   return this.getStatusBadge(d.status)
        // },
        Filter: ({ filter, onChange }) =>
          (<select
            onChange={event => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="">All</option>
            <option value="new order">New Order</option>
            <option value="buyer contacted">Buyer Contacted</option>
            <option value="invoice sent">Invoice Sent</option>
            <option value="payment pending">Payment Pending</option>
            <option value="payment received">Payment Received</option>
            <option value="shipped">Shipped</option>
            <option value="cancelled (per buyer">Cancelled (PBQ)</option>
            <option value="cancelled (item unavailable)">Cancelled (IU)</option>
            <option value="cancelled (non-paying buyer)">Cancelled (NPB)</option>
          </select>),
        Cell: row => (
          <div>
            <tr>{row.original.status}</tr>
          </div>
        ),
      }, {
        Header: 'Date',
        id: 'order_date',
        filterable: false,
        width: getColumnWidth(this.props.orders, 'date', 'Date'),
        // Filter: ({ filter, onChange }) =>
        //             <IntervalDatePickerField
        //               onChange={event => onChange(event.target.value)}
        //                 style={{ width: "100%" }}
        //                 value={filter ? filter.value : "all"}
        //             />,
        Cell: row => (
          <div>
            <span>{`Ordered: ${new Date(row.original.order_date).toLocaleDateString()}`}</span>
          </div>
        ),
      },
      // ,{
      //   Header: 'Fulfillment Status',
      //   id: 'fulfillment_status',
      //   accessor: 'fulfillment_status',
      //   show: this.props.user.fulfillment
      // }
    ];

    return (
      <div>
        <Col>
          <Card
            style={{
            height: '500px', // This will force the table body to overflow and scroll, since there is not enough room
            }}
          >
            <CardBody>
              <ReactTable
                style={{
                  height: '100%', // This will force the table body to overflow and scroll, since there is not enough room
                  }}
                data={this.state.data} // should default to []
                columns={purchasesColumns}
                pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
                loading={this.state.loading}
                manual
                filterable
                multiSort={false}
                defaultPageSize={50}
                defaultSorted={[
                    {
                      id: 'order_id',
                      desc: true,
                    },
                  ]}
                className="-striped -highlight -responsive"
                getTrProps={(state, rowInfo) => {
                    if (rowInfo !== undefined) {
                      return {
                        onClick: () => {
                          this.setState({
                            selected: rowInfo.original,
                            shouldRelist: false,
                          });
                        },
                        style: {
                          background: rowInfo.original === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.original === this.state.selected ? 'white' : 'black',
                        },
                      };
                    }
                      return {};
                  }} // informs React Table that you'll be handling sorting and pagination server-side
                onFetchData={(state) => {
                    // show the loading overlay
                    this.setState({ loading: true });
                    axios.post(`${URL}/admin/purchases`, {
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered,
                    }, { headers: { Authorization: localStorage.getItem('mm_admin') } })
                    .then((res) => {
                      // Update react-table
                      this.setState({
                        data: res.data.orders,
                        pages: res.data.pages,
                        loading: false,
                      });
                    });
                  }}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <center><h4 className="bold-text">{this.state.selected.description}</h4></center>
              <br />
              <div>
                <center><b>{this.state.selected.comments}</b></center>
              </div>
              <br />
              <div className="project-summary">
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th>Purchase ID:</th>
                      <td>{<b>MM-{this.state.selected.seller_id}-{this.state.selected.order_id}</b>}</td>
                    </tr>
                    <tr>
                      <th>Purchase Price:</th>
                      <td>{this.state.selected.items_total}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{this.state.selected.status}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Media:</th>
                      <td>{this.state.selected.media_cond}</td>
                    </tr>
                    <tr>
                      <th>Sleeve:</th>
                      <td>{this.state.selected.sleeve_cond}</td>
                    </tr>
                    <tr>
                      <th>Location:</th>
                      <td>{this.state.selected.location}</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th>Private Comments:</th>
                      <td>{this.state.selected.external_id}</td>
                    </tr>
                    <tr>
                      <th>Release ID:</th>
                      <td>{this.state.selected.release_id}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <Button
                  color="primary"
                  size="sm"
                  disabled={this.state.selected.status === 'Cancelled'}
                  onClick={() => this.cancelPurchase()}
                >Cancel Purchase
                </Button>
                {this.state.selected.status !== 'Cancelled' ? (
                  <tr>
                    <th>Relist Items?</th>
                    <input
                      type="checkbox"
                      checked={this.state.shouldRelist}
                      onChange={e => this.setState({ shouldRelist: e.target.checked })}
                    />
                  </tr>
                  ) : null }
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

// export default connect()(SidebarContent);
export default connect(mapStateToProps)(PurchasesTable);
